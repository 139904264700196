import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { toast } from 'react-toastify';
import { Country, State, City, ICountry, ICity, IState} from 'country-state-city';
import moment from 'moment';
import React from "react";
import { Buffer } from 'buffer';

interface FormikValues{
    firstName: string;
    lastName: string;
    phone: string;
    region: CityInfo;
    town: CityInfo;
    country: CountryInfo;
    companyName: string;
    companyNumber: string;
    companyAddress: string;
    vatNumber: string;
    tradingAddress: boolean;
    profileImageSrc: string | null | ArrayBuffer;
    file: File | null;
    company_trading_name : string;
}
interface TimeZone {
    zoneName: string;
    gmtOffset: number;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;
  }
  
export interface CountryInfo {
    isoCode: string;
    name: string;
    phonecode: string;
    flag: string;
    currency: string;
    latitude: string;
    longitude: string;
    timezones: TimeZone[];
  }

  export interface CityInfo {
    name: string;
    isoCode: string;
    countryCode: string;
    latitude: string;
    longitude: string;
  }
export interface DateObject {
    from: Date | null,
    to: Date | null,
    fromError: string | null,
    toError: string | null
}
export interface FormattedDateObject {
    unavailable_from: string | null,
    unavailable_to: string | null
}
export interface OpenDatePicker {
    from: boolean;
    to: boolean;
}
export interface Place {
    value: string;
    label: string;
}
interface LoginResponse {
    meta?: MetaResponse;
    errors?: (string)[];
    data: {
        attributes: Attributes
    };
}
interface Attributes {
    email: string;
    first_name: string;
    last_name: string;
    full_phone_number: string;
    country_code: string;
    phone_number: string;
    activated: boolean;
}

interface MetaResponse {
    token: string;
    refreshToken: string;
    id: string;
  }
const initialDatesObject = {
    selectedDates: [{ from: null, to: null, fromError: null, toError: null }],
    formattedSelectedDates: [],
    openDatePickers : [{ from: false, to: false }]
}
interface EmplyeeSignUpData {
    title : string;
    firstName : string;
    lastName: string;
    email: string;
    password: string;
    confirmPassword: string;
}
interface SubscriptionPlanFeature1 {
    id: number;
    title: string;
    subscription_plan_feature_category_id: number;
    created_at: string;
    updated_at: string;
  };
interface SubscriptionPlan {
    id: string;
    type: "subscription_plan";
    attributes: {
        plan_name: string;
        amount: number | null;
        currency: string;
        duration: string;
        is_archive: boolean;
        created_at: string;
        description: string;
        features: SubscriptionPlanFeature1[];
    };
  };
  interface FeatureCategory {
    id: string;
    type: string;
    attributes: {
      name: string;
      created_at: string;
      updated_at: string;
    };
  };
  interface SubscriptionPlanFeature {
    id: string;
    type: string;
    attributes: {
      title: string;
      created_at: string;
      updated_at: string;
      feature_category: {
        id: number;
        name: string;
        created_at: string;
        updated_at: string;
      };
    };
  };
  
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start

    signUpFormData: {
        password: string;
        email: string;
        confirmPassword: string;
    };
    subscriptionPlans: SubscriptionPlan[];
    subscriptionFeatureCategories: FeatureCategory[];
    subscriptionFeatures: SubscriptionPlanFeature[];
    planDisabled:boolean;
    openToDatePicker: boolean;
    openFromDatePicker: boolean;
    selectedFromDate: Date | null;
    selectedToDate: Date | null;
    emailError: string;
    setNullState: boolean;
    passFieldError: string;
    profileImageSrc: string | ArrayBuffer | null;
    confirmPassError: string;
    showPassword: boolean;
    phone: string;
    companyName: string;
    companyAddress: string;
    companyNumber: string;
    vatNumber: string;
    firstName: string;
    variable: boolean;
    disableSignInSubmit: boolean;
    lastName: string;
    selectedDates: (DateObject)[];
    formattedSelectedDates: (FormattedDateObject)[];
    openDatePickers: (OpenDatePicker)[];
    countryDropdownOpen: boolean;
    stateDropdownOpen: boolean;
    cityDropdownOpen: boolean;
    countryCode: string;
    stateCode: string;
    visibility: Place | null;
    termsPopup: boolean;
    privacyPopup: boolean;
    tradingAddress: boolean;
    datePickerToggle: boolean;
    availability: string;
    showConfirmPassword: boolean;
    countries: (Place)[];
    states: (Place)[];
    cities: (Place)[];
    selectedRegion: CityInfo;
    selectedCity: CityInfo;
    selectedCountry: CountryInfo;
    company_trading_name : string;
    EmployeSignUpFormData: EmplyeeSignUpData;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class AccountRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailSignInCallId: string = "";
    confirmEmailApiCallId: string = "";
    createProfileApiCallId: string = "";
    getStatesCallId: string = "";
    getCitiesCallId: string = "";
    fetchSubscriptionPlansApiCallId : string | null = "";
    fetchSubscriptionFeatureCategoriesCallId: string | null = "";
    fetchSubscriptionFeaturesCallId: string | null = "";
    selectedPricingPlanCallId:string | null = "";
    sendConfirmationEmailId: string = "";
    validationApiCallId: string = "";
    labelTitle: string = "";
    signInApiCallId: string = "";
    fileInputRef: React.RefObject<HTMLInputElement> = React.createRef();
    isCreative = sessionStorage.getItem("selectedRole") === "creative" || true;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.handleTermsNavigate = this.handleTermsNavigate.bind(this);
        this.handlePrivacyPolicyClick = this.handlePrivacyPolicyClick.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            subscriptionPlans:[],
            subscriptionFeatureCategories:[],
            subscriptionFeatures:[],
            planDisabled:false,
            signUpFormData: {
                password: "",
                email: "",
                confirmPassword: ""
            },
            selectedFromDate: null,
            visibility: null,
            selectedToDate: null,
            selectedDates: initialDatesObject.selectedDates,
            formattedSelectedDates: initialDatesObject.formattedSelectedDates,
            openDatePickers: initialDatesObject.openDatePickers,
            variable: false,
            termsPopup: false,
            privacyPopup: false,
            countryDropdownOpen: false,
            stateDropdownOpen: false,
            cityDropdownOpen: false,
            openFromDatePicker: false,
            openToDatePicker: false,
            companyName: '',
            companyAddress: '',
            companyNumber: '',
            vatNumber: '',
            firstName: '',
            disableSignInSubmit: false,
            lastName: '',
            countryCode: '',
            stateCode: '',
            profileImageSrc: null,
            setNullState: false,
            emailError: "",
            confirmPassError: "",
            passFieldError: "",
            tradingAddress: false,
            datePickerToggle: false,
            availability: 'closed',
            showPassword: false,
            phone: "",
            showConfirmPassword: false,
            countries: [{ value: '', label: '' }],
            states: [{ value: '', label: '' }],
            cities: [{ value: '', label: '' }],
            selectedRegion: {} as CityInfo,
            selectedCity:{} as CityInfo,
            selectedCountry: {} as CountryInfo,
            company_trading_name : "",
            EmployeSignUpFormData : {
                title : "",
                email : "",
                firstName : "",
                lastName : "",
                password : "",
                confirmPassword : ""
            }
        };
        this.labelTitle = configJSON.labelTitle;
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        this.setCountries();
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        this.fetchSubscriptionPlans();
        this.fetchSubscriptionFeatures();
        this.fetchSubscriptionFeatureCategories();
        const urlParams = new URLSearchParams(window.location.search); 
        const token = urlParams.get('token');
        if (token) {
          localStorage.setItem('token', token);
        }
        this.handleRestoreState();
        // Customizable Area End
    }

    // Customizable Area Start
    paymentBack(){
        this.props.navigation.navigate('UserPlan')
    }
    paymentConfirm(){
        this.props.navigation.navigate('AddPaymentDetail')
    }
    addPaymentDetailBack(){
        this.props.navigation.navigate('PaymentMethod')
    }
    addPaymentDetailConfirm(){
        this.props.navigation.navigate('ClientSignUpConfirm')
    }
    planNext(){
        this.props.navigation.navigate('PaymentMethod')
    }
    handleProceedSignUpClick() {
        if(this.isCreative){
            this.props.navigation.navigate('AccountRegistration')
        }
        else{
            this.props.navigation.navigate('AddUserProfile')
        }
    }
    handleTermsNavigate() {
        this.setState({ termsPopup: !this.state.termsPopup })
    }
    handleHomeBtnClick() {
        window.localStorage.clear();
        sessionStorage.clear();
        this.props.navigation.navigate('LandingPageWeb')
    }
    setCountries() {
        const countries = Country.getAllCountries().map(state => ({ value: state.isoCode, label: state.name }));
        this.setState({ countries: countries })
    }
    handlePrivacyPolicyClick() {
        this.setState({ privacyPopup: !this.state.privacyPopup })
    }
    handleLoginClick() {
        this.props.navigation.navigate("AccountLogin")
    }
    handleDateSelect = (selectedDate: Date | [Date | null, Date | null] | null, type: string, gridIndex: number) => {
        if (selectedDate instanceof Date || selectedDate === null) {
            this.handleDateChange(selectedDate, type, gridIndex);
            this.handleDatePickerClose(type, gridIndex);
        } else {
            console.error('Unexpected type for selectedDate');
        }
    }

    handleRegionError=(validateField : any) => {
        validateField('region');
    }
    handleCountryError=(validateField : any) => {
        validateField('country'); 
    }
    handleTownError=(validateField : any) => {
        validateField('town'); 
    }


    handleDateChange = (selectedDate: Date | null, type: string, gridIndex: number) => {
        this.setState(prevState => {
            const newSelectedDates = [...prevState.selectedDates];
            if (type === 'from') {
                newSelectedDates[gridIndex] = { ...newSelectedDates[gridIndex], from: selectedDate };
            } else {
                newSelectedDates[gridIndex] = { ...newSelectedDates[gridIndex], to: selectedDate };
            }
            return {
                selectedDates: newSelectedDates,
                formattedSelectedDates: newSelectedDates.map(item => ({
                    unavailable_from: item.from ? moment(item.from).format('YYYY-MM-DD') : null,
                    unavailable_to: item.to ? moment(item.to).format('YYYY-MM-DD') : null,
                })),
            };
        });
    };

    




    handleDatePickerOpen = (type: string, gridIndex: number) => {
        const newOpenDatePickers = [...this.state.openDatePickers];
        if (type == 'from') {
            newOpenDatePickers[gridIndex].from = true;
        }
        else {
            newOpenDatePickers[gridIndex].to = true;
        }
        this.setState({ openDatePickers: newOpenDatePickers });
    };

    handleDatePickerClose = (type: string, gridIndex: number) => {
        const newOpenDatePickers = [...this.state.openDatePickers];
        if (type == 'from') {
            newOpenDatePickers[gridIndex].from = false;
        }
        else {
            newOpenDatePickers[gridIndex].to = false;
        }
        this.setState({ openDatePickers: newOpenDatePickers });
    };
    addNewDatePicker = () => {
        this.setState(prevState => ({
            selectedDates: [...prevState.selectedDates, initialDatesObject.selectedDates[0]],
            openDatePickers: [...prevState.openDatePickers, { from: false, to: false }],
        }));
    };
    removeDatePicker = (indexToRemove: number) => {
        this.setState(prevState => ({
            selectedDates: prevState.selectedDates.filter((_, index) => index !== indexToRemove),
            openDatePickers: prevState.openDatePickers.filter((_, index) => index !== indexToRemove),
        }));
    };

    handlePassShow = () => {
        this.setState({
            showPassword: !this.state.showPassword
        });
    };
    handleConfirmPassShow = () => {
        this.setState({
            showConfirmPassword: !this.state.showConfirmPassword
        });
    };

    saveLoggedInUserData(responseJson: LoginResponse) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            let token = responseJson.meta.token;
            window.localStorage.setItem("token", token);
            sessionStorage.setItem("email", responseJson.data.attributes.email);
            this.isCreative ? this.props.navigation.navigate('AccountVerification') : this.props.navigation.navigate('SignUpThanks') ;
        }
        if (responseJson?.errors && responseJson?.errors[0] === 'Email already exists') {
            this.props.navigation.navigate('AccountAlreadyExists')
            return;
        }
        if (responseJson?.errors) {
            toast.error(responseJson?.errors[0])
        }

    }

    handleChangeUpload = (event: { target: { name: string, value: string } }) => {
        const { name, value } = event.target;
        this.setState({
            signUpFormData: {
                ...this.state.signUpFormData,
                [String(name)]: value
            }
        });
    }

    handleNavigate = (values: FormikValues) => {
            if (values.tradingAddress || !this.isCreative) {
                sessionStorage.setItem("tradingAddress", values.tradingAddress?.toString())
                sessionStorage.setItem('companyName', values.companyName);
                sessionStorage.setItem('companyNumber', values.companyNumber);
                sessionStorage.setItem('companyAddress', values.companyAddress);
                sessionStorage.setItem('vatNumber', values.vatNumber);
            }
            const updateImgFile = values.file;
            const profileImageSrc = values.profileImageSrc || "";
            if(updateImgFile){
                sessionStorage.setItem('profileImageSrc', profileImageSrc as string);
               this.handleFileToBase64(updateImgFile).then((base64File)=>sessionStorage.setItem('photo', base64File as string));     
             }
            sessionStorage.setItem('fName', values.firstName);
            sessionStorage.setItem('lName', values.lastName);
            sessionStorage.setItem('phone', values.phone);
            sessionStorage.setItem('country', JSON.stringify(values.country));
            sessionStorage.setItem('state', JSON.stringify(values.region));
            sessionStorage.setItem('city', JSON.stringify(values.town));
            sessionStorage.setItem("company_trading_name", JSON.stringify(values.company_trading_name))
            if(!this.isCreative){
                this.createProfile();
            }else{
                this.props.navigation.navigate('Availability');
            }
    }
    getStateArray = (iosCode: string) => {
        let newArray = State.getStatesOfCountry(iosCode).map((countryVal: IState) => ({ value: countryVal, label: countryVal.name }))
        return newArray
    }
    
    getCityArray = (iosCodeCountry: string, iosCodeCounty: string) => {
        let newArray = City.getCitiesOfState(iosCodeCountry, iosCodeCounty).map((countryVal: ICity) => ({ value: countryVal, label: countryVal.name }))
        return newArray
    }

    getCountry = () => {
        let CounntryArray: ICountry[] = Country.getAllCountries()
        let newArray = CounntryArray.map((countryVal: ICountry) => ({ value: countryVal, label: countryVal.name }))
        return newArray
    }

    handleFormatSelectedDates = (selectedDates: (DateObject)[]) => {
        const newSelectedDates = selectedDates.map(item => ({
            ...item,
            from: item.from?  new Date(item.from) : null,
            to: item.to ? new Date(item.to) : null,
        }));
        return newSelectedDates
    };

    handleRestoreState = () => {
        const country = sessionStorage.getItem("country");
        const state = sessionStorage.getItem("state"); 
        const city = sessionStorage.getItem("city");
        const countryData = country ? JSON.parse(country) as CountryInfo : {} as CountryInfo;
        const stateData = state ? JSON.parse(state) as CityInfo: {} as CityInfo;
        const cityData = city ? JSON.parse(city) as CityInfo: {} as CityInfo;
        const datePickerToggle = sessionStorage.getItem("datePickerToggle") ;
        const datePickerToggleData = datePickerToggle ? JSON.parse(datePickerToggle) : false ;
        const selectedDates = sessionStorage.getItem("selectedDates");
        const selectedDatesData = selectedDates ? JSON.parse(selectedDates) as (DateObject)[]: [{ from: null, to: null, fromError: null, toError: null }];
        const visibility = sessionStorage.getItem("visibility");
        const visibilityData = visibility ? JSON.parse(visibility) : null;
        const openDatePickers = sessionStorage.getItem("openDatePickers");
        const openDatePickersData = openDatePickers ? JSON.parse(openDatePickers) : initialDatesObject.openDatePickers;
        this.setState({
            tradingAddress: Boolean(sessionStorage.getItem("tradingAddress") || ""),
            companyName: sessionStorage.getItem("companyName") || "",
            companyNumber: sessionStorage.getItem("companyNumber") || "",
            companyAddress: sessionStorage.getItem("companyAddress") || "",
            vatNumber: sessionStorage.getItem("vatNumber") || "",
            firstName: sessionStorage.getItem("fName") || "",
            lastName: sessionStorage.getItem("lName") || "",
            phone: sessionStorage.getItem("phone") || "",
            selectedCity: cityData,
            selectedRegion: stateData,
            selectedCountry: countryData,
            profileImageSrc: sessionStorage.getItem("profileImageSrc"),
            datePickerToggle: datePickerToggleData,
            selectedDates: this.handleFormatSelectedDates(selectedDatesData) as (DateObject)[],
            visibility: visibilityData,
            openDatePickers: openDatePickersData
        });
    };

    handleAutoComplateChange = (fieldName: string, newValue: string[], setFieldValue: (fieldName: string, newValue: string[]) => void) => {
        setFieldValue(fieldName, newValue);
        this.setState(prevState => ({
            ...prevState,
            [fieldName]: newValue
        }))
      };

    handleVisiblity = (visible: Place) => {
        this.setState({ visibility: visible });
    }

    handleFileToBase64 = (file: File) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = error => reject(error);
        });
    }

     getFileFromSessionStorage=()=> {
        const base64File = sessionStorage.getItem('photo');
        if (!base64File) {
          return "";
        } 
        const mimeTypeMatch = base64File.match(/^data:(.*?);base64,/);
        const mimeString = mimeTypeMatch ? mimeTypeMatch[1] : '';
        const base64Content = base64File.replace(/^data:.*?;base64,/, ''); 
        const byteCharacters = Buffer.from(base64Content, 'base64');

        return new Blob([byteCharacters], { type: mimeString });
      }

    handleOppertunities = (datePickerToggle: boolean) => {
        let selectedDates = [...this.state.selectedDates];
        let visibility = this.state.visibility;
        let availability = "";
        let openDatePickers = [...this.state.openDatePickers]
        if(!datePickerToggle){
            selectedDates = initialDatesObject.selectedDates;
            visibility = null;
            availability = "closed";
            openDatePickers = initialDatesObject.openDatePickers;
        }else{
            availability = "opened";
        }
        this.setState({
            selectedDates, visibility, datePickerToggle, availability: availability, openDatePickers: openDatePickers
        });
    }

    handleBackNavigation = () =>{
        sessionStorage.setItem("datePickerToggle", this.state.datePickerToggle.toString());
        sessionStorage.setItem("selectedDates", JSON.stringify(this.state.selectedDates));
        sessionStorage.setItem("visibility", JSON.stringify(this.state.visibility));
        sessionStorage.setItem("openDatePickers", JSON.stringify(this.state.openDatePickers));
       this.props.navigation.goBack();
    }
    fetchSubscriptionPlans = () => {
        const headers = {
            token: window.localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.fetchSubscriptionPlansApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_subscriptions/subscription_plans'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    fetchSubscriptionFeatures = () => {
        const headers = {
            token: window.localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.fetchSubscriptionFeaturesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_subscriptions/subscription_plan_features'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            'GET'
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    fetchSubscriptionFeatureCategories = () => {
        const headers = {
            token: window.localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.fetchSubscriptionFeatureCategoriesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            'bx_block_subscriptions/subscription_plan_feature_categories'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    selectedPricingPlan=(id: any)=>{
        const body ={
            "account_id": id,
          }
        const headers = {
            token: window.localStorage.getItem("token"),
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.selectedPricingPlanCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            '/bx_block_subscriptions/subscriptions'
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(body)
          );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "Post"
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start


        runEngine.debugLog("Message Received", message);
        // Condition for fetching group list.
        if (this.apiEmailSignInCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            this.saveLoggedInUserData(responseJson);
        }
        //createProfileApiCallId
        if (this.createProfileApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if(responseJson.error){
                toast.error(responseJson.error);
            }else{
                this.handleProfileCreated();
                if(!this.isCreative){
                    this.props.navigation.navigate('UserPlan');
                }else{
                    this.props.navigation.navigate('SignUpComplete');
                }
            }
        }
        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
        if(!responseJson.errors){
            switch(apiRequestCallId){
                case this.fetchSubscriptionPlansApiCallId: {
                    this.setState({subscriptionPlans: responseJson.data});
                    break;
                }

                case this.fetchSubscriptionFeatureCategoriesCallId: {
                    this.setState({ subscriptionFeatureCategories: responseJson.data});
                    break;
                }

                case this.fetchSubscriptionFeaturesCallId: {
                    this.setState({ subscriptionFeatures: responseJson.data });
                    break;
                }
                case this.selectedPricingPlanCallId: {
                    this.setState({ planDisabled: true });
                    break;
                }
                default: {
                    break;
                }
            }
  }
        // Customizable Area End
    }

    handleProfileCreated = () => {
        sessionStorage.removeItem("country");
        sessionStorage.removeItem("state")
        sessionStorage.removeItem("city")
        sessionStorage.removeItem("datePickerToggle")
        sessionStorage.removeItem("selectedDates")
        sessionStorage.removeItem("visibility")
        sessionStorage.removeItem("openDatePickers")
        sessionStorage.removeItem("tradingAddress")
        sessionStorage.removeItem("companyName")
        sessionStorage.removeItem("companyNumber")
        sessionStorage.removeItem("companyAddress")
        sessionStorage.removeItem("vatNumber")
        sessionStorage.removeItem("fName")
        sessionStorage.removeItem("lName")
        sessionStorage.removeItem("phone")
        sessionStorage.removeItem("profileImageSrc")
    }
    createProfile(): boolean {
        const token = window.localStorage.getItem('token');
        const header = {
            token: window.localStorage.getItem('token')
        };
        const formData = new FormData();
        const photo=this.getFileFromSessionStorage();
        if (photo) {
            formData.append("photo", photo);
        }
        const country = sessionStorage.getItem("country");
        const state = sessionStorage.getItem("state"); 
        const city = sessionStorage.getItem("city");
        const countryData = country ? JSON.parse(country) as CountryInfo : {} as CountryInfo;
        const stateData = state ? JSON.parse(state) as CityInfo: {} as CityInfo;
        const cityData = city ? JSON.parse(city) as CityInfo: {} as CityInfo;
        formData.append("first_name", sessionStorage.getItem('fName') || '');
        formData.append("last_name", sessionStorage.getItem('lName') || '');
        formData.append("phone_number", sessionStorage.getItem('phone') || '');
        formData.append("country", countryData?.name || '');
        formData.append("county", stateData?.name || '');
        formData.append("city", cityData?.name || '');
        formData.append("availability", this.state.availability);
        formData.append("visibility_option", this.state.visibility?.value as string);
        formData.append("unavailable_dates", JSON.stringify(this.state.formattedSelectedDates));
        formData.append("company_name", sessionStorage.getItem('companyName') || '');
        formData.append("company_number", sessionStorage.getItem('companyNumber') || '');
        formData.append("registered_address", sessionStorage.getItem('companyAddress') || '');
        formData.append("vat_number", sessionStorage.getItem('vatNumber') || '');
        formData.append("company_trading_name", sessionStorage.getItem('company_trading_name') || '');
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.createProfileApiCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_profile/profiles?token=${token}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.signupAPiMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }
    handleSignInSubmit = (values: {
        email: string;
        password: string;
        confirmPassword: string;
    }) => {
        const header = {
            "Content-Type": configJSON.signUpApiContentType,
        };

        const attrs = {
            email: values.email,
            password: values.password,
            password_confirmation: values.confirmPassword,
            role_id : this.isCreative?2:1
        };

        const data = {
            type: "email_account",
            attributes: attrs,
        };

        const httpBody = {
            data: data,
        };

        const reqMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEmailSignInCallId = reqMsg.messageId;
        reqMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.signUpEndPoint
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.signupAPiMethod
        );
        reqMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        runEngine.sendMessage(reqMsg.id, reqMsg);

        return true;
    }
}
