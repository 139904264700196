import React from "react";

// Customizable Area Start
import {
  Box,
  Typography
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButton, StyledTitle, AccountStatement } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import CloseIcon from '@material-ui/icons/Close';
// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class SignUpThanks extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}><div
        style={{ overflow: 'hidden' }}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
          {
            this.isCreative ?
              <>
                <CloseIcon className="redirectIcon" onClick={() => this.handleHomeBtnClick()} />
                <Box width={{ xs: "300px", sm: "327px", md: "360px" }}>
                  <AccountStatement>
                    <StyledTitle className="marginBottom28" style={{ fontSize: "24px", color: "#000000", lineHeight: "32px" }}>
                      Thanks For Your Interest!
                    </StyledTitle>
                  </AccountStatement>
                  <AccountStatement>
                    <StyledTitle className="lightGrayMedium marginBottom28" style={{ fontFamily: "Silika-Medium" }}>
                      We are working on an invitation based system.Your signup request will be processed and we will come back to you as soon as possible.
                    </StyledTitle>
                  </AccountStatement>
                  <AccountStatement>
                    <StyledTitle className="lightGrayMedium" style={{ marginBottom: "114px" }}>
                      Please proceed to signup if you are okay. If you have an invitation link, please signup using it.
                    </StyledTitle>
                  </AccountStatement>
                  <StyledButton
                    onClick={() => { this.handleProceedSignUpClick() }}
                    variant="outlined"
                    style={{ ...webStyle.logInButton, textTransform: "capitalize", marginTop: 'auto' }}
                    type="submit"
                    data-test-id="buttonClick"
                  >
                    <Typography className="textBtnCreate"> Proceed to Sign Up</Typography>
                  </StyledButton>
                </Box>
              </>
              :
              <>
              <Box width={{ xs: "362px", sm: "327px", md: "360px" }}>
                  <AccountStatement>
                    <StyledTitle className="marginBottom28" style={{ textAlign:"center",fontFamily:"Silika-Medium",fontWeight:500,fontSize: "24px", color: "#000000", lineHeight: "32px" }}>
                    Thank you for signing up.
                    </StyledTitle>
                  </AccountStatement>
                  <AccountStatement>
                    <StyledTitle className="lightGrayMedium" style={{textAlign:"center", marginBottom: "114px" }}>
                    Your company details are now being verified. This process can take up to 48 hrs, after which you will be able to make payments and contract design professionals through the platform.
                    </StyledTitle>
                  </AccountStatement>
                  <StyledButton
                    onClick={() => { this.handleProceedSignUpClick() }}
                    variant="outlined"
                    style={{ ...webStyle.logInButton, textTransform: "capitalize", marginTop: 'auto' }}
                    type="submit"
                    data-test-id="buttonClick"
                  >
                    Set up my profile
                  </StyledButton>
                </Box>
              </>
          }
        </LoginSignUp>
      </div> </ThemeProvider>
      // Customizable Area End
    );
  }
}


