import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import _ from "lodash";
import { setStorageData } from "../../../../packages/framework/src/Utilities";
import { isSlowInternet } from "../../../../packages/components/src/measureInternet.web";
interface ImageAttributes {
  id: number;
  name: string;
  image: string;
}
interface IListGallery {
  data: MediaImage[];
  errors?: object;
}

interface MediaImage {
  id: number;
  filename: string;
  url: string;
  type: string;
  is_visible?:boolean
  is_favourite?:boolean
  is_like?:boolean
}

interface MediaPostAttributes {
  id: number;
  confidential: boolean;
  account_id: number;
  images: MediaImage[];
  collaborators?: number[];
  profile_id ?: number;
  project_title?: string;
}

interface MediaPost {
  id: string;
  type: string;
  attributes: MediaPostAttributes;
}

interface Pagination{
  total_pages: number;
  current_page: number;
  per_page: number;
  total_count: number;
  is_first_page: boolean;
  is_last_page: boolean; 
}
interface ProfileAttributes {
  id: number;
  country: string;
  county: string;
  city: string;
  postal_code: string;
  account_id: number;
  first_name: string;
  last_name: string;
  bio: string;
  skill: string[];
  phone_number: string;
  profession: string[];
  headline: string;
  company_name: string;
  company_number: string;
  registered_address: string;
  vat_number: string;
  availability: string | null;
  thumbs_up_count: number;
  visibility_option: string;
  dates: string[];
  seniority_level: string;
  work_location: string;
  work: string[];
  hourly_rate: string;
  collaborators: string[];
  tags: string[];
  description: string;
  photo: string;
  media: string | null;
  unavailable_dates: string;
  is_like: boolean;
}
interface Profile {
  getProfile: {
    attributes: ProfileAttributes
  };
  id: string;
  type: string;
  attributes: ProfileAttributes;
}
interface OptionType {
  account_id: number;
  firstName: string;
  lastName: string;
  photo: string;
};
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  lastPage: boolean;
  images: MediaPost[];
  searchImage: MediaPost[];
  searchMassege:string;
  isLoadingMore: boolean;
  isLogedIn : boolean;
  searchValue:string;
  needLoadMoreBtn: boolean;
  pagination: Pagination;
  perPage: number;
  page: number;
  profileId: number | null;
  openModel: boolean,
  shareLink: string;
  postImageID: number | null;
  isCopied: boolean | null,
  msgSend: boolean,
  isSendLinkToChatModalOpen: boolean,
  getAllData: Profile[],
  shareData: OptionType[],
  getStaticLikeID:string | number,
  getStaticfavID:string | number,
  sendChatMessage: string,
  deleteModal: boolean;
  postID: number
  // Customizable Area End
}
interface SS {
  navigation: any;
  id: any;
  // Customizable Area Start
  // Customizable Area End
}
export default class LandingPage6Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getMediaGalleryApiID: string = "";
  getMediaGallerySearchApiID: string = "";
  getAllDataProfileApiCallId: string = "";
  postMessageSendApiCallId: string = "";
  getLikeUnlikeApiCallId:string="";
  getAddToFavoriteApiCallId:string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area End
    ];
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    this.state = {
      // Customizable Area Start
      lastPage: false,
      deleteModal: false,
      postID: 0,
      openModel: false,
      isCopied:null,
      shareLink: "",
      postImageID:0,
      getAllData: [],
      shareData: [],
      getStaticLikeID:0,
      getStaticfavID:0,
      isSendLinkToChatModalOpen: false,
      sendChatMessage: "",
      msgSend: false,
      images: [],
      searchImage: [],
      searchMassege:"",
      isLoadingMore: false,
      isLogedIn : false,
      searchValue:"",
      needLoadMoreBtn: false,
      perPage: 20,
      page: 1,
      profileId: null,
      pagination:{
        total_pages: 0,
        current_page: 0,
        per_page: 0,
        total_count: 0,
        is_first_page: false,
        is_last_page: false,
      }
      // Customizable Area End
    };
    // Customizable Area Start
    this.receive = this.receive.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors) {
        if (apiRequestCallId === this.getMediaGalleryApiID) {
          this.handleListGalleryResponse(responseJson);
        }
        if(apiRequestCallId==this.getMediaGallerySearchApiID){
          this.handleListGallerySearchResponse(responseJson);
        }
        if (apiRequestCallId === this.getAllDataProfileApiCallId) {
          this.setState({
            ...this.state,
            getAllData: responseJson.data,
          });
        }
        if (apiRequestCallId === this.getLikeUnlikeApiCallId) {
          if (responseJson.data) {
            const dataToRender_ = this.state.searchImage.length > 0 ? this.state.searchImage : this.state.images;
            const updatedData = dataToRender_.map((elem) =>{
              return (
                elem.id === this.state.getStaticLikeID.toString() ? { ...elem, ...responseJson.data } : elem
              )
            }
            );
            this.setState({
              images: this.state.searchImage.length > 0 ? this.state.images : updatedData,
              searchImage: this.state.searchImage.length > 0 ? updatedData : this.state.searchImage,
            });

          }
        }
        if(apiRequestCallId == this.getAddToFavoriteApiCallId){
          if (responseJson.data) {
            const dataToRender_ = this.state.searchImage.length > 0 ? this.state.searchImage : this.state.images;
            const updatedData = dataToRender_.map((elem) =>{
              return (
                elem.id === this.state.getStaticfavID.toString() ? { ...elem, ...responseJson.data } : elem
              )
            }
            );
            this.setState({
              images: this.state.searchImage.length > 0 ? this.state.images : updatedData,
              searchImage: this.state.searchImage.length > 0 ? updatedData : this.state.searchImage,
            });

          }
        }
        if(apiRequestCallId === this.postMessageSendApiCallId){
          this.setState({ isSendLinkToChatModalOpen: false, shareData: [], sendChatMessage: '' ,msgSend: true });
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Star
  async componentDidMount() {
    this.getMediaGallery(this.state.page, this.state.perPage);
    const profileInfoId = this.props.navigation.getParam('id') || sessionStorage.getItem("profileId");
    const token = window.localStorage.getItem("token") || "";
    window.addEventListener("scroll", this.handleScroll);
    if(window.localStorage.getItem("token")){
      this.setState({isLogedIn : true})
    }
    window.scroll({
      top: 0,
      left: 0
  })
  if (!!!token) {
    profileInfoId && await setStorageData("profileInfoId", profileInfoId);
  }
  this.setState({profileId: profileInfoId});
  }
  componentWillUnmount(): Promise<void> {
    window.removeEventListener("scroll", this.handleScroll);
    return Promise.resolve();
  }
  handleSearch = () => {
    return(
      this.state.searchImage.length <= 0 && this.state.searchValue
      ? "(0)"
      : (this.state.searchImage.length > 0 && this.state.searchValue) 
      ? `(${this.state.searchImage.length})`
      : ("") 
    )
  }

  handleLoadMore = () => {
    this.props.navigation.navigate("AccountLogin")
  };
  handleImageNavigation = async (id : string, confidential:boolean, profileId?: string) =>{
    if(this.state.isLogedIn && !confidential){
      this.props.navigation.navigate("Profiles", { id: profileId, postId: id });
    }else if(!confidential){
      await setStorageData("postId", id)
      this.props.navigation.navigate("AccountLogin");
    }
  }

  handleLoadMoreClick = () => {
    if(!this.state.pagination.is_last_page){
      this.setState({isLoadingMore: true})
      this.getMediaGallery(this.state.pagination.current_page + 1, this.state.perPage);
    }
  };

  handleScroll = async () => {
    if(this.state.searchValue.length === 0 && this.state.isLogedIn){
    const scrollTopValue = window.pageYOffset || document.documentElement.scrollTop;
    const windowHeightValue = window.innerHeight;
    const scrollHeightValue = document.documentElement.scrollHeight;
    const touchedBottom = windowHeightValue + scrollTopValue >= scrollHeightValue;
    if (touchedBottom) {
      if(await isSlowInternet()){
        this.setState({needLoadMoreBtn:true});
      }
      else{
        this.handleLoadMoreClick();
      }
    }
    }
  };

  getTokenURL = () => {
    const token = localStorage.getItem("token");
    let url = !!token ? configJSON.getMediaGalleryLoginApiEntPoint : configJSON.getMediaGalleryApiEndPoint;
    return {url, token};
  }

  getMediaGallery = async (page:number, perPage:number) => {
    const object = this.getTokenURL();
    const header = {
      token: object.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       `${object.url}?page=${page}&per_page=${perPage}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    this.getMediaGalleryApiID = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleListGalleryResponse = async (responseJson: any) => {
    if (responseJson && !responseJson.errors) {
      this.setState((prevState) => ({
        images: [...prevState.images, ...responseJson.posts.data],
        isLoadingMore : false,
        pagination:{
          total_pages: responseJson.meta.pagination.total_pages,
          current_page: responseJson.meta.pagination.current_page,
          per_page: responseJson.meta.pagination.per_page,
          total_count: responseJson.meta.pagination.total_count,
          is_first_page: responseJson.meta.pagination.is_first_page,
          is_last_page: responseJson.meta.pagination.is_last_page
          }
      }));
    }
  };

  handleListGallerySearchResponse = async (responseJson: any) => {
    if(responseJson.message!==undefined ){
      this.setState({searchMassege:responseJson.message,searchImage:[]})
    }
    else{
      this.setState({searchMassege:""})
      if (responseJson && !responseJson.errors) {
        this.setState((prevState) => ({
          searchImage: responseJson.posts.data,
          isLoadingMore : false
        }));
      }
    }
    
  };


  handleSearchDebounce = _.debounce(() => {
    const object = this.getTokenURL();
    const {searchValue} = this.state;
    const header = {
      token: object.token
    };
    if(searchValue===""){
      this.getMediaGallery(this.state.pagination.current_page,this.state.perPage);
      this.setState({searchImage:[] , searchMassege:""})
    }
    else{
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
         `${object.url}?query=${searchValue}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      this.getMediaGallerySearchApiID = requestMessage.messageId;
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }, 1800)

  handleClose = () => {
    this.setState({
      openModel: false,
      isCopied: null,
      msgSend: false,
      shareLink: ""
    })
  }
  handleGetAllUserProfile = async () => {
    const ob = this.getTokenURL();
    const headers = {
      token: ob.token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAllDataProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_profile/profiles`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  handleChat = () => {
    this.setState({ isSendLinkToChatModalOpen: true, msgSend: false, isCopied: null })
    this.handleGetAllUserProfile();
  }
  handleCloseChatModel = () => {
    this.setState({ isSendLinkToChatModalOpen: false, shareData: [], sendChatMessage: '' });
  }
  handlesendChatMessage = (event: { target: { value: string } }) => {
    this.setState({ sendChatMessage: event.target.value });
  }
  handleshareDataChatChange = (_event: React.ChangeEvent<{}>, value: OptionType[]) => {
    this.setState({ shareData: value });
  };
  handleNavigateBack = () => {
    this.setState({
      isSendLinkToChatModalOpen: false,
      openModel: true
    })
  }
  handleSend = async () => {
    const ob = this.getTokenURL();
    const ids = this.state.shareData.map(user => user.account_id);
    const {postImageID} = this.state;

    const headers = {
      "Content-Type": "application/json",
       token: ob.token
    };
    let body =  {
        user_id: ids,
        message: this.state.sendChatMessage,
        post_id: `${postImageID}`
       }
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/posts/share_email`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    );
    this.postMessageSendApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  likePost = (event: React.MouseEvent<SVGSVGElement> , type: string, id: number) => {
    this.setState({getStaticLikeID : id})
    const ob = this.getTokenURL();
    event.stopPropagation();
    const headers = {
       token: ob.token,
      "Content-Type": "application/json" 
    };

    const bodydata={
      data:{
          type: type,
          attributes: {
              post_id:id
          }
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getLikeUnlikeApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_posts/posts/like_gallery_post"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodydata)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleAddToFavorite = (event: React.MouseEvent<SVGSVGElement> , type: string, id: number) => {
    this.setState({getStaticfavID : id})
    event.stopPropagation();
    const ob = this.getTokenURL();
    const headers = {
       token: ob.token,
      "Content-Type": "application/json" 
    };
    const bodydata={
      data:{
          type: type,
          attributes: {
            favouriteable_id:id,
            favouriteable_type:"BxBlockPosts::Post"
          }
      }
  }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getAddToFavoriteApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_posts/posts/favourite_gallery_post"
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(bodydata)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      headers
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'POST'
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  openPopUp = (event: React.MouseEvent<SVGSVGElement|HTMLImageElement>,profile_id :number|null, id: number|null, type: string) => {
    event.stopPropagation();
    let shareLink = window.location.href.split("/",3).join("/");
    if(type === "post"){
      shareLink = shareLink + "/Profiles/"+ `${profile_id}/` +`${id}`
    }else{
      shareLink = shareLink + "/Profiles/"+ `${profile_id}`
    }
    this.setState({
      openModel: true,
      shareLink,
      postImageID: id
    })
  }
  searchHandle = (text: string) => {
    this.setState({ searchValue: text }, this.handleSearchDebounce);
    this.handleSearch()
  };
  // Customizable Area End
}
// Customizable Area Start
// Customizable Area End
