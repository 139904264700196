// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
import PlanCardAndTable from "../../../components/src/PlanCardAndTable"
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserPlan extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{ overflow: 'hidden' , width:"100%" }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box style={{ marginTop: "108px", overflow: "hidden" }}>
              <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '35px' }}>
                <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left", }}>
                  Select other Plan
                </Typography>
              </div>
              <PlanCardAndTable subscriptionPlans={this.state.subscriptionPlans} subscriptionFeatures={this.state.subscriptionFeatures} subscriptionFeatureCategories={this.state.subscriptionFeatureCategories} onSelectPlan={this.selectedPricingPlan}/>
              <StyledNextBtn>
                <div>
                <Button className="backBtn"><Typography className="textBtnCreate">Back</Typography></Button>
                <Button className="nextBtn" data-test-id="planNextID" disabled={this.state.planDisabled} onClick={()=>{this.planNext()}}><Typography className="textBtnCreate">Next</Typography></Button>
                </div>
              </StyledNextBtn>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
    );
  }
}

const StyledNextBtn = styled('div')({
  display: "flex",
  justifyContent: "end",
  width: "100%",
  marginTop:"49px",
  "& .backBtn": {
    width: "129px",
    height: "48px",
    padding: "23.04px",
    gap: " 11.52px",
    borderRadius: "2.88px",
    background: "#FFFFFF",
    border: "1.44px solid #010101",
    marginRight: "20px",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "18px",
    textTransform: "capitalize",
    "&:hover": {
                boxShadow: "inset 400px 0 0 0 !important",
                backgroundColor: "#000",
                "& .textBtnCreate": {
                  color: "#fff !important",
                  fontFamily: "silika-Medium",
                  fontSize: "16px",
                },
              },
              "& .textBtnCreate": {
                boxShadow: "inset 0 0 0 0 !important",
                transition: "color 0.3s ease-in-out",  
                fontFamily: "silika-Medium",
                fontSize: "16px",
                color: "#0D0D0D",
              },
  },
  "& .nextBtn": {
    width: "129px",
    height: "48px",
    padding: "23.04px",
    gap: " 11.52px",
    borderRadius: "2.88px",
    background: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontWeight: 500,
    lineHeight: "18px",
    textTransform: "capitalize",
    "&:hover":{
      boxShadow:"inset 400px 0 0 0 !important",
      "& .textBtnCreate":{
        color: "#0D0D0D !important", 
        fontFamily: "silika-Medium",
        fontSize: "16px"
      }  
    },
    "& .textBtnCreate":{
      boxShadow:"inset 0 0 0 0 !important", 
      transition: "color 0.3s ease-in-out",  
      fontFamily: "silika-Medium",
      fontSize: "16px"
    },
  }
})
// Customizable Area End




