import React from "react";
import {styled, Dialog, DialogTitle, DialogContent, IconButton, Button, Theme, Typography} from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props{
    open: boolean;
    title: string;
    onClose: () => void;
}

interface ActionProps{
    onCancelClick: () => void;
    onSuccessClick: () => void;
    cancelText: string;
    successText: string;
    isValid: boolean;
}

class CommonFormDialog extends React.Component<Props>{
    constructor(props:Props){
        super(props);
    }
    render() {
        return(
            <StyledDialog open={this.props.open} onClose={(event: React.MouseEvent<HTMLButtonElement>) => this.props.onClose()} disableEscapeKeyDown={false}>
                    <StyledDialogTitle>
                        <span>{this.props.title}</span>
                        <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>
                    </StyledDialogTitle>
                    <StyledDialogContent>
                       {this.props.children}
                    </StyledDialogContent>
            </StyledDialog>
        )
    }
}

export const StyledActions = (props: ActionProps) => {
    return  <StyledDialogActions>
    <StyledButton onClick={props.onCancelClick} color="primary" className="cancelBtn">
        <Typography className="">{props.cancelText}</Typography>
    </StyledButton>
    <StyledButton disabled={!props.isValid} onClick={(event: React.MouseEvent<HTMLButtonElement>) => props.onSuccessClick()} color="primary" variant="contained" className="successBtn">
       <Typography className="">{props.successText}</Typography>
    </StyledButton>
</StyledDialogActions>
}

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: '100px',
}));

const StyledDialog = styled(Dialog)(({ theme }: {theme: Theme}) => ({
    '& *': {
      fontSize: "16px"
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      borderRadius: 4,
      minWidth: "736px",
      boxShadow: 'none',
      paddingBottom: "9.5px",
      [theme.breakpoints.down('sm')]: {
        minWidth: "auto",
      },
    },
}));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#0D0C22',
    color: '#fff',
    padding: '18px 37px 18px 40px',
    fontWeight: 'bold',
    "& h2":{
      width: "100%",
      display: "inline-flex",
      alignItems: "center"
    },
    "& button":{
      marginRight: "0px",
      marginLeft: "auto",
      padding: "0px",
      borderRadius: "2px"
    },
    "& *": {
      fontFamily: "Silika-Medium",
    },
    "& path": {
      fill: "#fff"
    }
}));

const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: '24px 40px 0px 40px',
    "& p": {
      fontWeight: 300,
      fontFamily: "Silika-Light",
      textAlign: "center"
    }
}));

const StyledDialogActions = styled("div")(({ theme }) => ({
    padding: '16px 0px 12px 0px',
    justifyContent: "flex-end",
    display: 'flex',
    width: "100%",
    '& button': {
      fontFamily: "Silika-Medium",
      textTransform: "none",
      boxShadow: "none",
      height: "48px",
      borderRadius: "2px",
      width: "104px"
    },
    "& .cancelBtn": {
      border: "1px solid #010101",
      color: "#010101",
      textTransform:'none',
      transition: "box-shadow 0.4s ease-in-out, color 0.3s ease-in-out",
      "&:hover": {
          boxShadow: "inset 400px 0 0 0 #000 !important", 
          color: "#fff",
          "& .textBtnCreate": {
          color: "#fff !important", 
          fontFamily: "silika-Medium",
          },
      },
      "& .textBtnCreate": {
          boxShadow: "inset 0 0 0 0 #000 !important",
          transition: "color 0.3s ease-in-out, box-shadow 0.4s ease-in-out",
          fontFamily: "silika-Medium",
      },
    },
    "& .successBtn": {
      background: "#0D0C22",
      marginLeft: "16px",
      border: "1px solid #0D0C22",
      textTransform: "none",
      borderRadius: "2px",
      color: "#fff", 
      boxShadow: "inset 0 0 0 0 #fff !important",
      transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out", 
      "&:hover": {
        backgroundColor: "#fff", 
        color: "#0D0D0D", 
        boxShadow: "inset 400px 0 0 0 #fff !important", 
        "& .textBtnCreate": {
          color: "#0D0D0D !important", 
        }
      },
      "& .textBtnCreate": {
        boxShadow: "inset 0 0 0 0 #fff !important",
        transition: "color 0.3s ease-in-out",
        color: "#fff", 
      },
    },
    "& .Mui-disabled":{
      background: "#9B9B9D",
      border:"1px solid #9B9B9D",
      color: "#fff"
    }
}));

export default CommonFormDialog;