import React from "react";
import { Box, Button, Grid, styled, Typography } from "@material-ui/core";
import HtmlTypography from "../../../../components/src/HtmlTypography";

interface ImageDivProps {
  title: string;
  description: string;
  image: any;
  direction?: string;
  fullView?: boolean;
  handleNavigation: (screenName: string) => void;
  idName: string;
  className?: string;
}

export const StyledContainer = styled(Box)({
  "& *": {
    fontFamily: "Silika-Medium",
    marginLeft: "auto",
    marginRight: "auto",
  },
  "& .landingPage5": {
    "& .designResearchContainer": {
      maxWidth: "1440px",
      "@media(max-width:1024px)": {
        flexFlow: "column !important",
        alignItems: "center",
        "& .leftImgDivContainer": {
          margin: "0px !important",
        },
        "& .rightImgDivContainer": {
          margin: "0px !important",
        },
        "& .contentDivSideDesc": {
          margin: "0px !important",
        },
      },
      "@media(max-width:599px)": {
        "& .contentDivSideDesc": {
          margin: "0 25px !important",
        },
      },
    },
    "& .imageDivs:nth-child(1)" :{
      backgroundColor: "#f8f8f8 !important",
      "& .designResearchContainer": {
        "& .contentDivSideDesc": {
          margin: "129px 133px 129px 0",
        },
        "& .leftImgDivContainer": {
          margin: "158px 147px 256px 133px",
          aspectRatio: "445/313",
          maxHeight: "313px"
        },
      }
    },
    "& .imageDivs:nth-child(2)":{
      "& .designResearchContainer": {
      "& .rightImgDivContainer": {
        margin: "211px 103px 211px 81px",
        aspectRatio: "445/313",
         maxHeight: "313px"
      },
      "& .contentDivSideDesc": {
        margin: "166px 0 165px 94px",
      },
    }
    },
    "& .imageDivs:nth-child(3)":{
      backgroundColor: "#f8f8f8 !important",
      "& .designResearchContainer": {
      "& .contentDivSideDesc": {
        margin: "122px 96px 122px 0",
      },
      "& .leftImgDivContainer": {
        margin: "190px 150px 190px 108px",
        aspectRatio: "493/373",
         maxHeight: "373px"
      },
    }
    },
    "& .imageDivs:nth-child(4)":{
      "& .designResearchContainer": {
      "& .rightImgDivContainer": {
        margin: "213px 93px 212px 85px",
        aspectRatio: "445/313",
         maxHeight: "313px"
      },
      "& .contentDivSideDesc": {
        margin: "166px 0 165px 94px",
      },
    }
    },
    "& .imageDivs:nth-child(5)":{
      "& .designResearchContainer": {
      "& .leftImgDivContainer": {
        margin: "197px 89px 197px 97px",
        aspectRatio: "586/409",
         maxHeight: "409px"
      },
      "& .contentDivSideDesc": {
        margin: "122px 96px 122px 0",
      },
    },
    backgroundColor: "#f8f8f8 !important",
    },
    "& .imageDivs:nth-child(6)":{
      "& .designResearchContainer": {
      "& .rightImgDivContainer": {
        aspectRatio: "576/333",
         maxHeight: "333px",
        margin: "158px 147px 258px 133px",
      },
      "& .contentDivSideDesc": {
        margin: "129px 133px 129px 0",
      },
    }
    },
    "& .imageDivs:nth-child(7)":{
      backgroundColor: "#f8f8f8 !important",
      "& .designResearchContainer": {
      "& .contentDivSideDesc": {
        margin: "203px 0 203px 104px",
      },
      "& .leftImgDivContainer": {
        aspectRatio: "445/313",
         maxHeight: "313px",
        margin: "211px 103px 211px 81px",
      },
    }
    },
    "& .imageDivs:nth-child(8)":{
      "& .designResearchContainer": {
      "& .rightImgDivContainer": {
        aspectRatio: "445/313",
        margin: "152px 149px 152px 107px",
         maxHeight: "313px"
      },
      "& .contentDivSideDesc": {
        margin: "57px 111px 57px 0",
      },
    }
    },
    "& .imageDivs:nth-child(9)":{
      backgroundColor: "#f8f8f8 !important",
      "& .designResearchContainer": {
      "& .rightImgDivContainer": {
        aspectRatio: "583/497",
         maxHeight: "497px",
        margin: "167px 97px 167px 85px",
      },
      "& .contentDivSideDesc": {
        margin: "110px 0 111px 64px",
      },
    }
    },
    "& .imageDivs:nth-child(10)":{
      "& .designResearchContainer": {
      "& .rightImgDivContainer": {
        margin: "197px 89px 197px 97px",
        aspectRatio: "445/313",
         maxHeight: "313px"
      },
      "& .contentDivSideDesc": {
        margin: "112px 96px 112px 0",
      },
    }
    },
    "& .imageDivs:nth-child(11)":{
      backgroundColor: "#f8f8f8 !important",
      "& .designResearchContainer": {
      "& .contentDivSideDesc": {
        margin: "166px 0 166px 96px",
      },
      "& .rightImgDivContainer": {
        margin: "213px 149px 213px 85px",
        aspectRatio: "445/313",
         maxHeight: "313px"
      },
    },
    }
    },
  "& .findTalentTitleContainer": {
    justifyContent: "center",
  },
  "& .findDesignTalentTitle": {
    paddingBottom: "48px",
    paddingTop: "116px"
  },
  "& .findTalentTitle": {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "64px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:720px)": {
      fontSize: "36px",
    },
  },
  "& .findPara": {
    fontWeight: "300 !important",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#0D0C22",
    marginTop: "49px",
    fontFamily: "Silika-Light",
    lineHeight: "25px",
    "@media(max-width:1280px)": {
      marginTop: "30px",
      fontSize: "14px",
    },
    "@media(max-width:959px)": {
      marginTop: "15px",
      fontSize: "12px",
    },
  },
  "& .blockTitle": {
    fontSize: "48px !important",
    justifyContent: "flex-start",
    marginLeft: "0px !important",
    "@media(max-width:1100px)": {
      fontSize: "36px !important",
    },
  },
  "& .paraOfDesign": {
    justifyContent: "flex-start !important",
    alignItems: "flex-start !important",
    lineHeight: "25px",
    "@media(max-width:1100px)": {
      fontSize: "14px !important",
      lineHeight: "20px",
      marginTop: "20px !important",
    },
  },
  "& .findTalentBTn": {
    marginLeft: "0px",
  },
  "& .getStartedBtn": {
    fontWeight:"500",
        fontSize:"16px",
        fontFamily:"Silika-Medium",
        width:"125px",
        height:"48px",
        marginTop:"32px",
        background:"#0D0C22",
        textTransform:"none",
        color:"white",
        borderRadius:"2px",
        boxShadow:"inset 0 0 0 0 #fff !important",
        border:"0.5px solid #0D0D0D",
        "&:hover":{
            boxShadow:"inset 400px 0 0 0 #fff !important",
            "& .textBtnCreate":{
              color: "#0D0D0D !important" , 
            }  
          },
          "& .textBtnCreate":{
            boxShadow:"inset 0 0 0 0 #fff !important", 
            transition: "color 0.3s ease-in-out",  
          },
  },
  "& .startBtnProject": {
    backgroundColor: "#0D0C22",
  },
  "& .designResearchContainer": {
    width: "100vw",
    display: "flex",
    flexFlow: "row",
    "@media(max-width:1024px)": {
      paddingTop: "40px",
      paddingBottom: "40px",
    },
    "@media(max-width:100px)": {
      paddingTop: "30px",
      paddingBottom: "30px",
      paddingRight: "40px",
      paddingLeft: "40px",
    },
  },
  "& .leftImage": {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  "& .fullImage": {
    width: "612px",
    height: "552px",
    marginTop: "29px",
    objectFit: "cover",
    "@media(max-width:1500px)": {
      width: "80%",
      marginTop: "5px",
    },
    "@media(max-width:959px)": {
      width: "100%",
    },
  },
  "& .rightImage": {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  "& .contentDivSideDesc": {
    display: "flex",
    flexFlow: "column",
    justifyContent: "center",
    "@media(max-width:1024px)": {
      maxWidth: "490px",
    },
  },
  "& .paragraphTitle": {
    textAlign: "center",
    maxWidth: "1112px",
    "@media(max-width:499px)": {
      width: "calc(100vw - 50px)"
    }
  },
  "& .questionDiv": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: "140px",
    paddingBottom: "140px",
    "@media(max-width:886px)": {
      paddingBottom: "50px",
      paddingTop: "50px",
    },
  },
  "& .questionBox": {
    width: "886px",
    "@media(max-width:886px)": {
      width: "90%",
    },
  },
  "& .questionBoxTitle": {
    paddingLeft: "90px",
    paddingRight: "90px",
    "@media(max-width:886px)": {
      paddingLeft: "50px",
      paddingRight: "50px",
    },
  },
  "& .questionTitle": {
    fontFamily: "Silika-Medium",
    textAlign: "center",
    marginBottom: "30px",
    fontWeight: 500,
    fontSize: "48px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:886px)": {
      fontSize: "30px",
      marginBottom: "20px",
    },
  },
  "& .questionDescription": {
    fontFamily: "Silika-Light",
    textAlign: "center",
    marginBottom: "30px",
    fontWeight: 300,
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media(max-width:886px)": {
      fontSize: "14px",
      marginBottom: "20px",
    },
  },
});

const ImageDiv: React.FC<ImageDivProps> = ({
  title,
  description,
  image,
  direction = "left",
  fullView = false,
  handleNavigation,
  idName,
  className,
}) => {
  return (
    <Box className="imageDivs">
      <Grid
        container
        className={`findTalentTitleContainer designResearchContainer ${
          direction === "right" ? "reverseDirection" : ""
        }`}
        style={{
          flexDirection: direction === "right" ? "row-reverse" : "row",
          backgroundColor: direction === "right" ? "white" : "none",
        }}
        id={idName}
      >
        <Grid
          item
          xl={6}
          lg={6}
          md={6}
          sm={12}
          className={`${
            direction === "right"
              ? "rightImgDivContainer"
              : "leftImgDivContainer"
          } ${className}`}
        >
          <img
            src={image}
            alt="image"
            className={fullView ? "fullImage" : `${direction}Image`}
          />
        </Grid>
        <Grid item xl={6} lg={6} md={6} sm={12} className="contentDivSideDesc">
          <HtmlTypography
            className="findTalentTitle blockTitle"
            htmlContent={title}
          />
          <HtmlTypography
            className="findPara paraOfDesign"
            htmlContent={description}
          />
          <Button
            data-test-id="findTalentBtn"
            className="findTalentBTn getStartedBtn startBtnProject"
            onClick={() => handleNavigation("AccountLogin")}
          >
            <Typography className="textBtnCreate">Find Talent</Typography>
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ImageDiv;
