import React from "react";
import {
  Grid,
  Box,
  Typography,
  Button,
  styled,
  ButtonGroup,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@material-ui/core";
import HeaderNavbar from "../../../../components/src/Navbar";
import JobListingController from "../JobListingController";
import JobCard from "../../../../components/src/JobCard.web";
import { AdvanceSearch } from "./AdvanceSearch";
import { closeIcon, companyLogo } from "../assets";
import { DialogOpportunity } from "./DialogOpportunity";
import { DialogJobPostPopUp } from "./DialogJobPostPopUp";
import dayjs from "dayjs";
import utcTime from "dayjs/plugin/utc";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { ApplySuccessPopUp } from "./ApplySuccessPopUp";
import {
  ShareLinkDialog,
  ShareMenuDialog,
} from "../../../../components/src/ShareDialogs.web";
import { getSurname } from "../../../../components/src/HelperUtils";
dayjs.extend(utcTime);
dayjs.extend(isSameOrAfter);
const StyledTabWrapper = styled(Box)({
  marginTop: "35px",
  display: "flex",
  flexDirection: "column",
  padding: "80px",
  "@media (max-width:960px)": {
    padding: "20px",
  },
  "& .tabTitle": {
    fontFamily: "Silika-Medium !important",
    "@media (max-width:600px)": {
      fontSize: "14px !important",
    },
  },
  "& .tabBtn": {
    borderRadius: "0px",
  },
  "& .cardContainer": {
    display: "flex",
    columnGap: "2%",
    "@media (max-width:960px)": {
      marginBottom: "20px",
    },
  },
  "& .OpportunitiesText": {
    "@media (max-width:600px)": {
      fontSize: "24px !important",
    },
  },
  "& .opportunityBtn": {
    "@media (max-width:600px)": {
      width: "135px !important",
      fontSize: "14px !important",
    },
  },
  "& .jobCardContainer": {
    width: "100%",
    marginBottom: "40px",
  },
  "& .clickBtn": {
    display: "none",
  },
  "& .displayBtn": {
    display: "none",
  },
});

const StyledDialogApplyJob = styled(Dialog)({
  "& .MuiDialog-paperFullWidth": {
    maxWidth: "600px",
    height: "234px",
  },
  "& .MuiDialogTitle-root": {
    padding: "15px 40px",
  },
  "& .MuiTypography-h6": {
    fontSize: "16px",
    fontFamily: "Silika-Medium",
  },
  "& .subTitle": {
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontWeight: 300,
  },
  "& .cancelBtn": {
    textTransform: "none",
    border: "1px solid black",
    borderRadius: "2px",
    fontSize: "16px",
    fontFamily: "Silika-Medium",
    width: "104px",
    height: "48px",
  },
  "& .yesBtn": {
    width: "112px",
    backgroundColor: "black",
    color: "white",
    marginRight: "32px",
  },
  "& .closeBtnIconStyle": {
    width: "15px",
    height: "15px",
    marginRight: "32px",
    cursor: "pointer",
  },
});

class ClientJobListing extends JobListingController {
  handleClose = () => {
    this.setState({ showMessage: false });
  };

  buttonGroupOfTab = () => {
    return (
      <ButtonGroup style={webStyle.opportunityDiv}>
        <Button
          data-test-id="InfoDiv"
          type="button"
          className="tabBtn"
          onClick={() => {
            this.handleTabChange(1);
          }}
          style={
            this.state.activeTab === 1 ? webStyle.infoDiv : webStyle.workDiv
          }
        >
          <Typography
            style={this.state.activeTab === 1 ? webStyle.info : webStyle.info1}
            className="tabTitle"
          >
            Search
          </Typography>
        </Button>
        <Button
          data-test-id="workDiv"
          type="button"
          className="tabBtn"
          onClick={() => {
            this.handleTabChange(3);
          }}
          style={
            this.state.activeTab === 3 ? webStyle.infoDiv : webStyle.workDiv
          }
        >
          <Typography
            style={this.state.activeTab === 3 ? webStyle.info : webStyle.info1}
            className="tabTitle"
          >
            My Opportunities
          </Typography>
        </Button>
      </ButtonGroup>
    );
  };

  DialogApplyJob = () => (
    <StyledDialogApplyJob
      fullWidth={true}
      maxWidth="md"
      open={this.state.applyPopup}
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={webStyle.experienceInnerDiv1}>
        <DialogTitle style={webStyle.dialougeHeading}>Apply Job</DialogTitle>
        <img
          src={closeIcon}
          className="closeBtnIconStyle"
          data-test-id="closeBtnIcon"
          id="close-img"
          onClick={() => this.handlePopupApply()}
        ></img>
      </div>
      <DialogContent style={webStyle.dialogContent}>
        <Typography className="subTitle">
          Are you sure you want to apply for this role?
        </Typography>
      </DialogContent>
      <DialogActions style={{ marginBottom: "16px" }}>
        <Button
          className="cancelBtn"
          data-test-id="cancelBtn"
          onClick={() => this.handlePopupApply()}
        >
          Cancel
        </Button>
        <Button
          className="yesBtn cancelBtn"
          data-test-id="sendDataId"
          onClick={() => this.closeOpenConfirmationSuccessJob()}
        >
          yes, Apply
        </Button>
      </DialogActions>
    </StyledDialogApplyJob>
  );

  handleDayDifferenceThat = (date: string) => {
    return this.handleDayDifference(dayjs(date).format("YYYY-MM-DD")) === 0
      ? "Today"
      : this.handleDayDifference(dayjs(date).format("YYYY-MM-DD")) +
          " days ago";
  };

  cover = () => {
    return (
      <Box
        className="displayBtn"
        data-test-id="coverBtn"
        onClick={this.handleGetAllUserProfile}
      ></Box>
    );
  };

  render() {
    const jobList = this.state.jobListingData;
    return (
      <Grid>
        <HeaderNavbar navigation={this.props.navigation} />
        <DialogOpportunity
          open={this.state.isNewOpportunity}
          handlePopUpOpportunity={this.handleNewOpportunity}
          handleChooseOptionForOpportunity={
            this.handleChooseOptionForOpportunity
          }
          handleOpenPopUpRelatedPopup={this.handleOpenPopUpRelatedPopup}
          typeOpportunity={this.state.typeOpportunity}
          data-test-id="dialogueOpportunity"
        />
        <DialogJobPostPopUp
          open={this.state.isPostJobOpen}
          handleCreateJob={this.handleJobCreate}
          handleJobPostPopUp={this.handleJobPostPopUp}
          data-test-id="DialogJobPostPopUp"
          seniorityLevels={this.state.seniorityLevels.map(
            (seniorityLevels) => ({
              key: seniorityLevels,
              value: seniorityLevels,
            })
          )}
          workTypes={this.state.workTypes}
          skills={this.state.skills}
          professions={this.state.workTypes.map((profession) => ({
            key: profession,
            value: profession,
          }))}
        />

        {this.DialogApplyJob()}
        <StyledTabWrapper
          style={{
            maxWidth: "1280px",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <Box style={webStyle.opportunityBox}>
            <Typography
              style={webStyle.OpportunitiesText}
              className="OpportunitiesText"
            >
              Opportunities
            </Typography>
            <Button
              style={webStyle.opportunityBtn}
              className="opportunityBtn"
              onClick={this.handleNewOpportunity}
            >
              New Opportunity
            </Button>
          </Box>
          <Box
            className="displayBtn"
            data-test-id="displayBtn1"
            onClick={() => this.handleChatSend}
          >
            create
          </Box>
          {this.cover()}
          <Box
            className="displayBtn"
            data-test-id="displayBtn2"
            onClick={() => this.handleChatSend}
          >
            create job
          </Box>
          {this.buttonGroupOfTab()}
          {this.state.activeTab === 1 && (
            <Grid container style={webStyle.jobCardContainer}>
              <AdvanceSearch
                selectedSeniorityLevels={this.state.selectedSeniorityLevels}
                onMaxRateChange={this.handleMaxRateChange}
                seniorityLevels={this.state.seniorityLevels}
                selectedSkills={this.state.selectedSkills}
                workTypes={this.state.workTypes}
                selectedCountries={this.state.selectedCountries}
                selectedWorkTypes={this.state.selectedWorkTypes}
                selectedProfessions={this.state.selectedProfessions}
                onClickClearAll={this.handleClickClearAll}
                maxRate={this.state.maxRate}
                onClickClearFilter={this.handleClickClearFilter}
                skills={this.state.skills}
                onChangeFilter={this.handleChangeFilter}
                onChangeSearchText={this.handleChangeSearchText}
                professions={this.state.professions}
                onMinRateChange={this.handleMinRateChange}
                minRate={this.state.minRate}
                onClearRateRange={this.handleClearRateRange}
              />
              {jobList?.map((data, index) => {
                return (
                  <div key={index} className="jobCardContainer">
                    <JobCard
                    toggleJobDetail={this.toggleJobDetail}
                      category={data.attributes.category}
                      title={data.attributes.job_title}
                      updatedTime={this.handleDayDifferenceThat(
                        data.attributes.created_at
                      )}
                      level={data?.attributes?.level
                        .toString()
                        .split(",")
                        .map((item) => item)
                        .join(" | ")}
                      location={`${data.attributes.county}, ${data.attributes.country}`}
                      type={data?.attributes?.job_type
                        ?.toString()
                        .split(",")
                        .map((item) => item)
                        .join(" | ")}
                      profession={data.attributes.profession
                        .toString()
                        .split(",")
                        .map(
                          (item) =>
                            item.trim().charAt(0).toUpperCase() +
                            item.trim().slice(1)
                        )
                        .join(" | ")}
                      dates={`${this.handleDateFormat(
                        data.attributes.start_date
                      )} - ${this.handleDateFormat(data.attributes.end_date)}`}
                      rate={`£${data?.attributes?.account?.data?.attributes?.account_profile?.data?.attributes?.hourly_rate_from}-${data?.attributes?.account?.data?.attributes?.account_profile?.data?.attributes?.hourly_rate_to}`}
                      budget={"£" + `${data.attributes.budget}`}
                      description={data.attributes.job_description}
                      stage="open"
                      btnText="Assign"
                      data-test-id="jobcard"
                      imageUrl={data.attributes.banner_image || companyLogo}
                      handlePopUpShare={this.openPopUp}
                      handleApplyPopUp={this.handlePopupApply}
                      job_id={+data.id}
                    />
                  </div>
                );
              })}
              <Box
                className="displayBtn"
                data-test-id="displayBtn2"
                onClick={() => this.handleChatSend}
              >
                create job
              </Box>
            </Grid>
          )}
          {this.state.activeTab === 2 && (
            <Grid container style={webStyle.jobCardContainer}>
              {Array.from(Array(2)).map((_, index) => (
                <Box style={{ marginBottom: "40px" }}>
                  <JobCard
                    toggleJobDetail={this.toggleJobDetail}
                    title="Automotive Designer"
                    category="Supercars"
                    updatedTime="5 days ago"
                    location="Coventry, UK"
                    profession={[
                      "Interior Design | UI/UX Design | Visualization",
                    ]}
                    dates="13 Mar 24 - 25 Apr 24"
                    rate="£45.00/hr"
                    level={["Senior"]}
                    type={["Full Time", "Remote"]}
                    budget="£2,000"
                    description="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet urna eget lacus sollicitudin, non accumsan lorem posuere. In hac habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas tincidunt ligula. Integer sodales ac purus sit amet imperdiet."
                    imageUrl={companyLogo}
                    stage="invitation"
                    btnText="Accept"
                    handleApplyPopUp={this.handlePopupApply}
                    handlePopUpShare={this.openPopUp}
                  />
                </Box>
              ))}
              {this.cover()}
            </Grid>
          )}
          {this.state.activeTab === 3 && (
            <Grid container style={webStyle.jobCardContainer}>
              {Array.from(Array(4)).map((_, index) => (
                <Box style={{ marginBottom: "40px", minWidth: "100%" }}>
                  <JobCard
                    toggleJobDetail={this.toggleJobDetail}
                    title="Automotive Designer"
                    category="Supercars"
                    updatedTime="5 days ago"
                    location="Coventry, UK"
                    profession={[
                      "Interior Design | UI/UX Design | Visualization",
                    ]}
                    dates="13 Mar 24 - 25 Apr 24"
                    rate="£45.00/hr"
                    level={["Senior"]}
                    type={["Full Time", "Remote"]}
                    imageUrl={companyLogo}
                    stage="application"
                    btnText="Assign"
                    budget="£2,000"
                    description=""
                    handleApplyPopUp={this.handlePopupApply}
                  />
                </Box>
              ))}
            </Grid>
          )}
        </StyledTabWrapper>
        <ApplySuccessPopUp
          open={this.state.applySuccessPopUp}
          handleApplySuccessPopUp={this.handleApplySuccessPopUp}
          data-test-id="applySuccessPopUp"
        />
        <ShareLinkDialog
          isChatSend={this.state.isChatSend}
          shareLink={this.state.shareLink}
          data-test-id="shareLinkDialog"
          onClickChatButton={this.handleClickChatButton}
          onClose={this.handleCloseButton}
          isOpen={this.state.isShareModalOpen}
        />
        <ShareMenuDialog
          isOpen={this.state.isChatModalOpen}
          onClose={this.handleCloseButton}
          onClickBackButton={this.handleBackButton}
          profiles={this.state.profileData.map(
            (option: {
              attributes: {
                account_id: number;
                first_name: string;
                last_name: string;
                photo: string;
              };
            }) => ({
              account_id: option.attributes.account_id,
              firstName: option.attributes.first_name,
              lastName: getSurname(
                option.attributes.last_name,
                option.attributes.account_id.toString()
              ),
              photo: option.attributes.photo,
            })
          )}
          shareData={this.state.shareData}
          chatMessage={this.state.chatMessage}
          onChangeChatMessage={this.handleChangeChatMessage}
          onShareDataChange={this.handleShareData}
          onClickSendChat={this.handleSendChatButton}
          data-test-id="ShareMenuDialogID"
        />
      </Grid>
    );
  }
}

export default ClientJobListing;

const webStyle = {
  outerMostContainerSize: {
    height: "600px",
    marginBottom: "100px",
    marginTop: "100px",
    paddingTop: 50,
    width: "100%",
    position: "relative" as "relative",
  },
  opportunityDiv: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  infoDiv: {
    width: "50%",
    border: "none",
    alignItems: "center",
    borderBottom: "2px solid #000",
  },
  workDiv: {
    alignSelf: "center",
    border: "none",
    width: "50%",
    borderBottom: "2px solid #DFDFDF",
  },
  info: {
    fontWeight: 500,
    fontFamily: "Silka",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    fontSize: "24px",
    textAlign: "center" as "center",
    textTransform: "none" as "none",
  },
  info1: {
    fontSize: "24px",
    lineHeight: "40px",
    fontFamily: "Silka",
    letterSpacing: "-0.15px",
    fontWeight: 500,
    textAlign: "center" as "center",
    color: "#9B9B9D",
    textTransform: "none" as "none",
  },
  jobCardContainer: {
    padding: "48px 0 48px 0",
  },
  OpportunitiesText: {
    fontFamily: "Silika-Medium",
    lineHeight: "40px",
    fontWeight: 500,
    fontSize: "32px",
    color: "#000000",
    letterSpacing: "-0.15px",
  },
  opportunityBtn: {
    width: "157px",
    height: "44px",
    backgroundColor: "#0D0C22",
    fontWeight: 500,
    color: "#FFFFFF",
    fontFamily: "Silika-Medium",
    lineHeight: "19.2px",
    letterSpacing: "-0.15px",
    fontSize: "16px",
    textTransform: "none" as "none",
  },
  opportunityBox: {
    display: "flex",
    marginBottom: "50px",
    justifyContent: "space-between",
  },
  popupOverlay: {
    position: "fixed",
    top: "0",
    left: "0",
    right: "0",
    bottom: "0",
    background: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: "1000",
  },
  popup: {
    background: "white",
    padding: "20px",
    borderRadius: "10px",
    boxshadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
  },
  buttonpop: {
    margintop: "10px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    backgroundColor: "#243642",
    color: "white",
    cursor: "pointer",
    margin: "auto",
    display: "flex",
  },
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1195px",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "19.3px",
    color: "white",
    alignItems: "center",
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px !important",
    fontWeight: 500,
    lineHeight: "19px",
  },
  dialogContent: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
