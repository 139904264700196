import React, { Component } from "react";
import { Avatar , Box, Button, Grid, Typography, styled, WithWidth, IconButton, isWidthDown, withWidth, Drawer, List, ListItem, ListItemText, makeStyles, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions } from "@material-ui/core";
import { Message } from "framework/src/Message";
import MessageEnum, { getName } from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@mui/icons-material/Close';
const imgLogo = require("./image_logo.svg");
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
const { baseURL } = require("framework/src/config");
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

interface Props extends WithWidth {
  navigation?: {};
}

interface S {
  isDrawerOpen: boolean,
  profileAvatar: string;
  firstName: string | undefined;
  lastName: string | undefined;
  profileModal: boolean;
  isDropdownOpen: boolean;
  isOpenArrow: boolean;
  isLogoutDialogeOpen: boolean;
}
function EventTooltip(props: any) {
  const classes = useEventTooltipStyle();

  return <Tooltip classes={classes} placement="right" {...props} />;
}
const useEventTooltipStyle = makeStyles((theme) => ({
  arrow: {
    color: "f5f5f5",
  },

  tooltip: {
    color: "#000000",
    padding: "14px 14px 14px 10px",
    fontFamily: "Silika-Medium",
    fontSize: "12px",
    borderRadius: "2px",
    backgroundColor: "#f5f5f5",
    borderLeft: "4px solid #000",
    margin: "4px 0px 0px 4px",
  },
}));
const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
  backgroundColor: "#0D0C22",
  color: "#fff",
  padding: "18px 37px 18px 40px",
  fontWeight: "bold",
  "& *": {
    fontFamily: "Silika-Medium",
  },
}));
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
  padding: "44px 39.5px 24px 39.5px",
  "& p": {
    fontWeight: 400,
    fontFamily: "Silika-Light",
    textAlign: "center",
  },
  "@media(max-width:959px)": {
    paddingTop: "0px !important",
    paddingRight: "20px !important",
    paddingBottom: "0px !important",
    paddingLeft: "20px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
}));
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  padding: "16px 40px 24px 40px",
  "@media(max-width:959px)": {
    paddingTop: "0px !important",
    paddingRight: "20px !important",
    paddingBottom: "20px !important",
    paddingLeft: "20px !important",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  display: "flex",
  "& button": {
    fontFamily: "Silika-Medium",
    textTransform: "none",
    borderRadius: "0",
    boxShadow: "none",
    height: "48px",
  },
  "& .cancelBtn": {
    border: "1px solid #010101",
    color: "#010101",
    boxShadow:"inset 0 0 0 0 #000 !important",
    "&:hover":{
        boxShadow:"inset 400px 0 0 0 #000 !important",
        "& .textBtnCreate":{
          color: "#fff !important",
          fontFamily: "silika-Medium"
        }  
      },
      "& .textBtnCreate":{
        boxShadow:"inset 0 0 0 0 #000 !important", 
        transition: "color 0.3s ease-in-out",
        fontFamily: "silika-Medium"
      },
    
  },
  "& .logoutBtn": {
    background: "#0D0C22",
    marginLeft: "16px",
    boxShadow:"inset 0 0 0 0 #fff !important",
    border:"0.5px solid #0D0D0D",
    fontFamily: "silika-Medium",
    "&:hover":{
        boxShadow:"inset 400px 0 0 0 #fff !important",
        "& .textBtnCreate":{
          color: "#0D0D0D !important", 
          fontFamily: "silika-Medium"
        }  
      },
      "& .textBtnCreate":{
        boxShadow:"inset 0 0 0 0 #fff !important", 
        transition: "color 0.3s ease-in-out",  
        fontFamily: "silika-Medium"

      },
  },
}));
const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: "100px",
  borderRadius: "2px !important",
  "& .logOutBtn": {
    padding: "14.5px 16px 14.5px 16px !important",
    fontFamily: "silika-Medium"
  },
}));
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& *": {
    fontSize: "16px",
  },
  "& .MuiPaper-root": {
    backgroundColor: "#fff",
    borderRadius: 4,
    width: "600px",
    boxShadow: "none",
    height: "243px",
  },
}));
const StyledHyperlink = styled("span")(() => ({
  cursor: "pointer",
  height: "100%",
  width: "100%",
  display: "flex",
  flexFlow: "column",
  "&:hover": {
    "& span": {
      fontWeight: "700"
    }
    },
  "& span": {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    textAlign: "left" as "left",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#0D0C22",
    padding: "9px 20px 9px 6px",
    width: "calc(100% - 20px)",
  },
}));
const StyledContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  "& .logoForge": {
    Width: "19.41px",
    Height: "25.67px",
    color: "black",
  },
  "& .headLeftDiv": {
    display: "flex",
    gap: "5px",
    alignItems: "center",
    height: "30px",
    marginTop: "20px",
    marginBottom: "20px",
    width: "auto",
    flexShrink: 1
  },
  "& .linkText": {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "15px",
    lineHeight: "18.1px",
  },
  "& .headRightDiv": {
    display: "flex",
    alignItems: "center",
    height: "30px",
    marginTop: "20px",
    marginBottom: "20px",
    justifyContent: "flex-end"
  },
  "& .headContainer": {
    display: "flex",
    justifyContent: "space-between",
    marginRight: "34px",
    marginLeft: "34px",
  },
  "& .loginBtn": {
    border: "1px solid grey",
    textTransform: "none",
    width: "55px",
    fontSize: "14px",
    fontWeight: 500,
    color: "black",
    height: "30px",
    borderRadius: "2px",
    fontFamily: "Silika-Medium",
    letterSpacing: "0",
    boxShadow:"inset 0 0 0 0 #000 !important",
    background:"white",
    "&:hover":{
        boxShadow:"inset 400px 0 0 0 #000 !important",
        "& .textBtnCreate":{
          color: "#fff !important", 
          fontSize: "14px",
          
        }  
      },
      "& .textBtnCreate":{
        boxShadow:"inset 0 0 0 0 #000 !important", 
        transition: "color 0.3s ease-in-out",  
        fontSize: "14px"
      },
  },
  "& .signUpBtn": {
    textTransform: "none",
    backgroundColor: "#0D0C22",
    color: "white",
    width: "78px",
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: "20px",
    height: "30px",
    borderRadius: "2px",
    fontFamily: "Silika-Medium",
    letterSpacing: "0",
    boxShadow:"inset 0 0 0 0 #fff !important",
    border:"0.5px solid #0D0D0D",
    background:"#0D0C22",
    "&:hover":{
        boxShadow:"inset 400px 0 0 0 #fff !important",
        "& .textBtnCreate":{
          color: "#0D0D0D !important" , 
          fontSize: "14px"
        }  
      },
      "& .textBtnCreate":{
        boxShadow:"inset 0 0 0 0 #fff !important", 
        transition: "color 0.3s ease-in-out",  
        fontSize: "14px"
      },
  }
});

class LandingHeader extends Component<Props, S> {
  constructor(props: any) {
    super(props);

    this.state = {
      isLogoutDialogeOpen: false,
      isOpenArrow: false,
      isDropdownOpen: false,
      profileModal: false,
      isDrawerOpen: false,
      profileAvatar: sessionStorage.getItem("profileAvatar") || "gfhf",
      firstName: sessionStorage.getItem("firstName")?.toString()[0],
      lastName: sessionStorage.getItem("lastName")?.toString()[0]
    };
  }
  getToken = () => {
    const token = localStorage.getItem("token") || "";
    return token;
  };

  fetchProfile = async () => {
    const token = this.getToken();
    if (token.length !== 0) {
      const apiUrl = `${baseURL}/account_block/accounts/show_profile`;
      try {
        let response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        });
        let result = await response.json();
        sessionStorage.setItem("profileId", result.data.attributes.profile);
        this.fetchProfileData();
      } catch (error) {
        console.error("()=>here1", error);
      }
    }
  };
  async componentDidMount() {
    this.fetchProfile();
    window.addEventListener("profileUpdated", this.handleProfileUpdate)
  }

  componentWillUnmount(){
    window.removeEventListener("profileUpdated", this.handleProfileUpdate)
  }
  handleLogout = () => {
    window.localStorage.clear();
    sessionStorage.clear();
    this.handleNavigation("LandingPage");
  };
  handleProfileUpdate = () => {
    this.fetchProfileData();
  }
  fetchProfileData = async () => {
    const profileId = sessionStorage.getItem("profileId");
    const token = this.getToken();
    const apiUrl = `${baseURL}/bx_block_profile/profiles/${profileId}`;
    if (token.length !== 0) {
      try {
        let response = await fetch(apiUrl, {
          method: "GET",
          headers: {
            token: token,
            "Content-Type": "application/json",
          },
        });
        let result = await response.json();
        this.setState({
          profileAvatar: result.data.attributes.photo,
          firstName: result.data.attributes.first_name,
          lastName: result.data.attributes.last_name
        })
      } catch (error) {
        console.error("()=>here1", error);
      }
    }
  };
  handleNavigation = (screenName: string) => {

    if (screenName.length > 0) {
      console.log("screen name", screenName);
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
      message.addData(getName(MessageEnum.NavigationTargetMessage), screenName);
      runEngine.sendMessage(message.id, message);
      if(screenName!=="AccountLogin"){
        localStorage.setItem("selectedScreen", screenName);
      }
    }
  }
  handleHeaderProfileOpen = () => {
    this.setState({
      profileModal: !this.state.profileModal,
    });
  };
  handleClickAwayProfile = () => {
    this.setState({
      profileModal: false,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };
  handleHeaderProfileClose = () => {
    this.setState({
      profileModal: false,
    });
  };
  handleIsLogoutDialogeOpen = () => {
    this.setState({
      isLogoutDialogeOpen: !this.state.isLogoutDialogeOpen,
    });
  };
  renderLogoutDialogue = () => {
      return (
        <StyledDialog
          open={this.state.isLogoutDialogeOpen}
          onClose={this.handleIsLogoutDialogeOpen}
        >
          <StyledDialogTitle>Log out</StyledDialogTitle>
          <StyledDialogContent>
            <Typography>Are you sure you want to log out?</Typography>
          </StyledDialogContent>
          <StyledDialogActions>
            <StyledButton
              onClick={this.handleIsLogoutDialogeOpen}
              color="primary"
              className="cancelBtn"
            >
                <Typography component="span" className="textBtnCreate">Cancel</Typography>           
            </StyledButton>
            <StyledButton
              className="logOutBtn"
              onClick={this.handleLogout}
              color="primary"
              variant="contained"
            >
                <Typography component="span" className="textBtnCreate"> Yes, log out</Typography>           
            </StyledButton>
          </StyledDialogActions>
        </StyledDialog>
      );
    };
    dropdownLinks = (navText: string, screen: string, className?: string) => {
      return (
        <StyledHyperlink>
          <Typography
            component="span"       
            onClick={() => this.handleNavigation(screen)}
            className={className}
          >
            {navText}
          </Typography>
            <Typography style={webStyle.discoverOptionBorder} />
        </StyledHyperlink>
      );
    };
  handleClickAwayDiscover = () => {
    this.setState({
      isDropdownOpen: false,
      isOpenArrow: !this.state.isOpenArrow,
    });
  };
  handleisDrawerOpen = () => {
    this.setState({
      isDrawerOpen: !this.state.isDrawerOpen
    });
  };

  checkRouteIsActive = (routeNameLink:string) => {
    const routeName = new URL(document.URL).href.split('/').pop();
    return routeNameLink===routeName
  }

  render() {
    const { width } = this.props;
    const isMobile = isWidthDown("sm", width);
    const isGalleryRoute = location.pathname === "/Gallery";
    const token = window.localStorage.getItem("token") || "";
    return (
      <StyledContainer>
        <Grid container className="headContainer">
          <Grid item xl={4} lg={6} md={6} sm={8} className="headLeftDiv">
            <Box
              style={webStyle.logoContainer}
            >
              {
                isMobile ? <IconButton onClick={this.handleisDrawerOpen} style={webStyle.menuButton}><MenuIcon /></IconButton> : <></>
              }
              <img
                src={imgLogo}
                alt="logo"
                style={webStyle.logoImage}
                onClick={()=>this.handleNavigation("LandingPageWeb")}
              />
              {!isMobile ? <div style={webStyle.leftMenu}>
                <Typography onClick={() => this.handleNavigation("LandingPage2Web")}  style={{
                    ...webStyle.discoverHyperLink,
                    fontWeight : "normal",
                    minWidth: "82px",
                  }}>
                  For Clients
                </Typography>
                <Typography onClick={() => this.handleNavigation("LandingPageWeb6")}
                  style={{
                    ...webStyle.discoverHyperLink,
                    fontWeight : "normal"
                  }}>
                  Gallery
                </Typography>
                <Typography onClick={() => this.handleNavigation("LandingPage3Web")}  style={{
                    ...webStyle.discoverHyperLink,
                    fontWeight : "normal"
                  }}>
                  Pricing
                </Typography>
              </div> : <></>}
            </Box>
            <Drawer open={this.state.isDrawerOpen} onClose={this.handleisDrawerOpen}>
              <List>
                <Box
                  style={{...webStyle.logoContainer, marginLeft : "16px"}}
                >
                  <IconButton onClick={this.handleisDrawerOpen} style={webStyle.menuButton}><CloseIcon /></IconButton>
                  <img
                    alt="logo"
                    src={imgLogo}
                    style={webStyle.logoImage}
                  />
                </Box>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("")} style={webStyle.discoverHyperLink}>
                     For Clients
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("LandingPageWeb6")} style={{...webStyle.discoverHyperLink, fontWeight : isGalleryRoute? "bold" : "normal"}}>
                      Gallery
                    </div>
                  </ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemText>
                    <div onClick={() => this.handleNavigation("LandingPage3Web")} style={webStyle.discoverHyperLink}>
                      Pricing
                    </div>
                  </ListItemText>
                </ListItem>
              </List>
            </Drawer>
          </Grid>

          <Grid item xl={2} lg={2} md={2} sm={4} className="headRightDiv">
            {
              token==""
              ? <>
            <Button
              className="loginBtn"
              onClick={() => this.handleNavigation("AccountLogin")}
            >
             <Typography component="span" className="textBtnCreate">Log In</Typography>
            </Button>
            <Button
              className="signUpBtn"
              onClick={() => this.handleNavigation("SignUpThanks")}
            >
             <Typography component="span" className="textBtnCreate">Sign up</Typography>
            </Button>
              </>
              : <>
              <Box style={webStyle.rightAlignOption}>
              <EventTooltip title="Coming soon" placement="bottom">              
                        <NotificationsOutlinedIcon style={webStyle.notificationOption} />
                 </EventTooltip>
                 <EventTooltip title="Coming soon" placement="bottom">              
                         <ChatBubbleOutlineIcon style={webStyle.chatOption} />
                 </EventTooltip>
                 <Avatar
                onClick={() => this.handleHeaderProfileOpen()}
                src={this.state.profileAvatar}
                alt="profile"
                style={webStyle.headerImage}
                data-test-id="headerImg"
              >
                {this.state.firstName?.toString()[0]}
                {this.state.lastName?.toString()[0]}
              </Avatar>
              {this.state.profileModal && (
                <ClickAwayListener onClickAway={this.handleClickAwayProfile}>
                  <Box
                    onClick={this.handleHeaderProfileClose}
                    style={webStyle.profileHyperLinkDiv}
                  >
                    {this.dropdownLinks("My Profile", "ProfileInfo")}
                    {this.dropdownLinks("Settings", "SettingsProfile")}
                    {this.dropdownLinks("Favourites", "Favourites")}
                    <StyledHyperlink>
                      <Typography
                        component="span"
                        onClick={this.handleIsLogoutDialogeOpen}
                      >
                        Log out
                      </Typography>
                    </StyledHyperlink>
                  </Box>
                </ClickAwayListener>
              )}
            </Box>
            {this.renderLogoutDialogue()}
              </> 
            }
          </Grid>

        </Grid>
      </StyledContainer>
    );
  }
}


const webStyle = {
  menuButton: {
    padding: "4px",
    marginRight: "17px"
  },
  discoverOptionBorder: {
    borderBottom: "0.6px solid #9B9B9D",
    width: "175px",
    opacity: "16%",
    margin: "auto",
    marginLeft: "6px"
  },
  notificationOption: {
    fontSize: "30px",
    stroke: "#ffffff",
    strokeWidth: 0.6,
    cursor: "default",
  },
  headerImage: {
    height: "32px",
    width: "32px",
    flexShrink: 0,
    borderRadius: "50%",
    objectFit: "cover" as "cover",
    cursor: "pointer",
  },
  chatOption: {
    height: "24px",
    width: "24px",
    cursor: "default",
  },
  profileHyperLinkDiv: {
    position: "absolute" as "absolute",
    top: "50px",
    right: "5px",
    zIndex: 99999,
    width: "192px",
    background: "#FFF",
    borderRadius: "8px",
    padding: "4px",
    border: "1px solid #9B9B9D",
    gap: "2px",
    display: "grid" as "grid",
    flexDirection: "column" as "column",
    justifyContent: "start" as "start",
    alignItems: "center" as "center",
    justifyItems: "start" as "start",
  },
  logoContainer: {
    width: "67px",
    display: "flex",
    alignItems: "center",
  },
  logoImage: {
    width: "19.412px",
    height: "25.674px",
    flexGrow: 0,
    alignSelf: "center",
    cursor: "pointer",
    color: "black",
    marginRight: "25px",
  },
  discoverHyperLink: {
    whiteSpace: "nowrap" as "nowrap",
    fontFamily: "Silika-Medium",
    fontSize: "15px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    color: "black",
    padding: "9px 20px 9px 16px",
    gap: "8px",
    cursor: 'pointer',
    letterSpacing: '0'
  },
  leftMenu: {
    display: "flex",
  },
  rightAlignOption: {
    display: "flex" as "flex",
    height: "100%",
    justifyContent: "space-evenly",
    alignItems: "center",
    gap: "24px",
    position: "relative" as "relative",
  },
};

export default withWidth()(LandingHeader)