import React from "react";

// Customizable Area Start
import { Box, Typography, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Formik } from "formik";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import * as Yup from "yup";
import LoginSignUp, { webStyle,StyledButton, AccountStatement, StyledTextField } from "./LoginSignUp.web";
import AccountLoginController, { Props } from "./AccountLoginController";

const validationSchema = Yup.object().shape({
  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      "Password doesn't match the criteria above"
    ),
  confirmPassword: Yup.string()
    .required("Confirm password is required")
    .oneOf([Yup.ref("password"), null], "Password not matching"),
});



// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserForgotPassword extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  searchParams = new URLSearchParams(window.location.search);
  email = this.searchParams.get("email") || "";
  // Customizable Area End
  render() {
   
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
          <div style={{overflow:'hidden'}}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
        <Box width={{xs : "300px", sm : "327px",  md: "360px"}}>
          <Formik
            initialValues={{
              password: this.state.newPassword,
              confirmPassword: this.state.confirmNewPwd,
            }}
           onSubmit={()=>{}}
            validateOnChange={false}
            validateOnBlur={true}
            data-testid="formikForm"
            validationSchema={validationSchema}
          >
            {({  handleSubmit }) => (
              <form autoComplete="off" onSubmit={handleSubmit}>
                <div style={{...webStyle.formTitleContainer,marginBottom:'25px',justifyContent: 'left'}}>
                  <Typography style={{...webStyle.formTitleText,textAlign: "left" as "left"}}>
                    New Password
                  </Typography>
                </div>
                <Box style={{...webStyle.passwordInputPosition}}>
                  <AccountStatement>
                    <Typography className="blackMedium marginBottom12">
                      Enter a new password for {this.email}
                    </Typography>
                  </AccountStatement>
                  <AccountStatement>
                    <Typography className="lightGray marginBottom8">
                      The password should have at least one lower case and one
                      upper case characters
                    </Typography>
                  </AccountStatement>
                  <StyledTextField
                    fullWidth
                    error={!!this.state.forgotPwdError}
                    helperText={this.state.forgotPwdError}
                    className="marginBottom16"
                    variant="outlined"
                    autoComplete='new-password'
                    type={this.state.showForgotPwd ? "text" : "password"}
                    value={this.state.newPassword}
                    onBlur={()=>{this.validatePasswrd()}}
                    placeholder="Password"
                    data-test-id={"password"}
                    name="password"
                    onChange={(event) => {
                      this.handlePasswordChange(event.target.value)
                    }}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: !this.state.showForgotPwd ? (
                        <VisibilityOffIcon
                          style={webStyle.passwordVisibilityOffIcon}
                          onClick={this.handlePwdShow}
                        />
                      ) : (
                        <VisibilityIcon
                          style={webStyle.passwordVisibilityOnIcon}
                          onClick={this.handlePwdShow}
                        />
                      ),
                    }}
                  />
                </Box>
                <Box style={{...webStyle.passwordInputPosition, marginTop : 0}}>
                  <StyledTextField
                    fullWidth
                    error={!!this.state.confirmPwdError}
                    helperText={this.state.confirmPwdError}
                    className="marginBottom48"
                    variant="outlined"
                    onBlur={()=>{this.valdateConfirmPwd()}}
                    type={this.state.showConfirmPassword ? "text" : "password"}
                    value={this.state.confirmNewPwd}
                    placeholder="Confirm New Password"
                    data-test-id={"confirmPassword"}
                    name="confirmPassword"
                    onChange={(event) => {
                     this.handleConfirmPasswordChange(event.target.value)
                    }}
                    FormHelperTextProps={{
                      style: {
                        marginLeft: 0,
                      },
                    }}
                    InputProps={{
                      endAdornment: !this.state.showConfirmPassword ? (
                        <VisibilityOffIcon
                          style={webStyle.passwordVisibilityOffIcon}
                          onClick={this.handleForgotPwdShow}
                        />
                      ) : (
                        <VisibilityIcon
                          style={webStyle.passwordVisibilityOnIcon}
                          onClick={this.handleForgotPwdShow}
                        />
                      ),
                    }}
                  />
                </Box>
                <Box />
                  <StyledButton
                    variant="outlined"
                    data-test-id='submitBtn'
                    type="submit"
                    disabled={!this.state.newPassword || !this.state.confirmNewPwd}
                    onClick={() => {
                     this.handleClick();
                    }}
                  >
                    <Typography className="textBtnCreate">Set New Password </Typography>
                  </StyledButton>
              </form>
            )}
          </Formik>
          </Box>
        </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}
