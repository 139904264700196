import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButton } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";



// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class VerificationSuccess extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{ overflow: 'hidden' }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box width={{xs : "300px", sm : "327px",  md: "360px"}}>
              <Box height={{xs : "auto", md:"370px"}} style = {{display : "flex", flexDirection : "column"}}>
              <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '35px' }}>
                <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left", }}>
                  Welcome
                </Typography>
              </div>
              <div style={webStyle.isAccountDiv}>
                <Typography style={{ ...webStyle.isAccountStatement, marginBottom: 30, fontSize: "15px" }}>
                  Congrats! You are verified. Please, continue and provide your details.
                </Typography>
              </div>
              <StyledButton
                  onClick={() => { this.props.navigation.navigate('AddUserProfile') }}
                  variant="outlined"
                  data-test-id="clickButton"
                  style={{ ...webStyle.logInButton, textTransform: 'capitalize', marginTop : "auto" }}
                  type="submit"
                >
                  <Typography className="textBtnCreate">Proceed</Typography>
                </StyledButton>
                </Box>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


