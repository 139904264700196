import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { StyledTitle, webStyle , StyledButton, AccountStatement} from "../../email-account-login/src/LoginSignUp.web";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import "../../customisableuserprofiles/src/ProfileInfo.css"



// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});



export default class AccountVerification extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
           <div style={{
            overflow:'hidden'}}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
          <Box width={{xs : "300px", sm : "327px",  md: "360px"}}>
              <StyledTitle>
                Verify Email
              </StyledTitle>
            <AccountStatement>
              <Typography className="blackMedium lineHeight24 marginBottom16">
                Please check the inbox of <span style={{wordBreak: "break-all"}}>{sessionStorage.getItem('email')}</span> and validate the signup. Do not forget to check your spam folder.
              </Typography>
            </AccountStatement>
            <AccountStatement>
              <Typography className="lightGrayMedium resendVerificationLinkWarn">
                If you have not received it please click on the Resend Verification Link.
              </Typography>
            </AccountStatement>
              <StyledButton
                variant="outlined"
                data-test-id='sendMail'
                onClick={() => { this.sendEmailConfirmation() }}
                type="submit"
                className={this.state.timer>0?"colorButton":"sendBtn"}
                disabled={this.state.timer>0}
              >
                <Typography className="textBtnCreate">Resend Verification Link </Typography>
              </StyledButton>
              <Typography style={{ ...webStyle.formTitleText,marginTop:8,fontSize:'17px', textAlign: "right" as "right"}}>
            {this.state.timer>=10?`00:${this.state.timer}`:`00:0${this.state.timer}`}
              </Typography>
          </Box>
        </LoginSignUp></div>   
      </ThemeProvider>
        
      // Customizable Area End
    );
  }
}


