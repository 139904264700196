import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { AccountStatement, StyledButton, StyledTextField, StyledTitle } from "../../email-account-login/src/LoginSignUp.web";
import AccountLoginController, { Props } from "./AccountLoginController";
import "../../customisableuserprofiles/src/ProfileInfo.css"




// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPasswordEmail extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    const { forgotPasswordEmail } = this.state;
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{
          overflow: 'hidden'
        }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>

            <Box width={{ xs: "300px", sm: "327px", md: "360px" }} >
                <StyledTitle className="marginBottom16">
                  Forgot Password
                </StyledTitle>
              <AccountStatement>
                <Typography className="blackMedium marginBottom42">
                  Please enter the email address you'd like your password reset information sent to
                </Typography>
              </AccountStatement>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <AccountStatement>
                <Typography className="blackMedium marginBottom4">
                  Enter email address
                </Typography>
                </AccountStatement>
                <StyledTextField
                  onBlur={() => { this.validateEmail() }}
                  fullWidth
                  value={forgotPasswordEmail}
                  onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                      this.handleBtnClick();
                    }
                  }}
                  error={!!this.state.pswrdEmailError}
                  helperText={this.state.pswrdEmailError}
                  variant="outlined"
                  InputProps={{
                    classes:{
                      error: "formError"
                    }
                  }}
                  placeholder="email@email.com"
                  data-test-id={"pwdEmail"}
                  name="password"
                  onChange={(event) => {
                    this.handleEmailChange(event)
                  }}
                  FormHelperTextProps={{
                    style: {
                      marginLeft: 0,
                    },
                    classes: {
                      error: "formHelperErrorText"
                    }
                  }}
                />
              </div>
                <StyledButton
                  variant="outlined"
                  data-test-id={"submitBtn"}
                  onClick={() => { this.handleBtnClick() }}
                  className="requestResetLink"
                  disabled={!forgotPasswordEmail}
                >
                   <Typography className="textBtnCreate">Request reset link</Typography>
                </StyledButton>

            </Box>

          </LoginSignUp></div>
      </ThemeProvider>

      // Customizable Area End
    );
  }
}


