// Customizable Area Start
import React from "react";

import {
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class UserPlan extends AccountRegistrationController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{ overflow: 'hidden' }}>
          <LoginSignUp navigation={this.props.navigation} id={""}>
            <Box style={{ marginTop: "108px", overflow: "hidden",textAlign:"center" }}>
              <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '35px' }}>
                <Typography style={{ ...webStyle.formTitleText,fontWeight:600}}>
                All sorted.
                </Typography>
                <Typography style={{ ...webStyle.isAccountStatement, marginTop: 42}}>Thank you, your payment details have<br/> been recorded.</Typography>

              </div>
              <ActionBtn>
                <Button className="redirectBTN">Go Home</Button>
              </ActionBtn>
            </Box>
          </LoginSignUp>
        </div>
      </ThemeProvider>
    );
  }
}

const ActionBtn = styled('div')({
  display: "flex",
  justifyContent: "Center",
  width: "100%",
  marginTop:"198px",
  "& .redirectBTN": {
    width: "360px",
    height: "56px",
    padding: "23.04px",
    gap: " 11.52px",
    borderRadius: "2.88px",
    background: "#0D0C22",
    color: "#FFFFFF",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 300,
    lineHeight: "19.2px",
    textTransform: "capitalize",
  }
})
// Customizable Area End




