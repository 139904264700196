import React from 'react'
import {
    Theme, Button, styled,
    Typography
} from "@material-ui/core"
import parse from "html-react-parser";

interface Props {
    termsAndConditions: string;
    isAgreeButtonEnabled: boolean;
    onAgreeButtonClick: () => void;
}

class TermsAndConditions extends React.Component<Props>{

    constructor(props: Props){
        super(props);
    }

    render(): React.ReactNode {
        return (
            <StyledTermAndConditions>
                <div className="termsAndConditions" style={{marginTop: "7%",}} data-test-id="termsAndConditions">
                    {parse(this.props.termsAndConditions)}
                </div>
                {!this.props.isAgreeButtonEnabled ? <StyledButtonContainer> 
                    <Button data-test-id="termsAndConditionsAgree" type="button" onClick={(e: React.MouseEvent<HTMLButtonElement>) => this.props.onAgreeButtonClick()}><Typography className=''>Agree</Typography></Button>
                </StyledButtonContainer>: <></>}
            </StyledTermAndConditions>
        )
    }
}

const StyledTermAndConditions = styled("div")(({theme}: {theme: Theme}) => ({
    width: "calc(100% - 350px)",
    marginTop: "5%",
    [theme.breakpoints.down('sm')] :{ 
        width: "calc(100vw - 80px)",
        marginTop: "24px",
        "& .termsAndConditions":{
            marginLeft: "56px",
        }
    },
    "& p":{
        fontFamily: "Silika-Light",
        fontSize: "16px",
        lineHeight: "24px"
    }
}));

const StyledButtonContainer = styled("div")(({theme}: {theme: Theme}) => ({
    display: "flex",
    justifyContent: "end",
    [theme.breakpoints.down('sm')] :{ 
        width: "100vw"
    },
   "& button": {
  backgroundColor: "#0D0C22 !important", 
  borderRadius: "2px !important",
  color: "white !important", 
  right: "0px",
  textTransform: "none",
  width: "87px",
  height: "48px",
  fontSize: "16px",
  fontFamily: "Silika-Medium",
  marginTop: "21.5px",
  marginBottom: "32.5px",
  [theme.breakpoints.down('sm')]: { 
    width: "calc(100% - 50px)",
    margin: "24px 25px auto 25px"
  },
  border: "1px solid #0D0C22",
  boxShadow: "inset 0 0 0 0 #fff !important",
  transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "#fff", 
    color: "#0D0D0D !important", 
    boxShadow: "inset 400px 0 0 0 #fff !important",
  },
  "& .textBtnCreate": {
    boxShadow: "inset 0 0 0 0 #fff !important",
    transition: "color 0.3s ease-in-out",
    color: "#fff",
    "&:hover": {
      color: "#0D0D0D !important", 
    }
  },
}

}));

export default TermsAndConditions
