import React from "react";

// Customizable Area Start
import HeaderNavbar from "../../../components/src/Navbar";
import FavouritesTabs from "./components/FavouriteTabs";
import { ShareLinkDialog, ShareMenuDialog } from "../../../components/src/ShareDialogs.web";
import { getSurname } from "../../../components/src/HelperUtils";
// Customizable Area End

import FavouritesControllerWeb, {
  Props,
  configJSON,
} from "./FavouritesController.web";

export default class Favourites extends FavouritesControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return <>
            <HeaderNavbar navigation={this.props.navigation}/>
            <FavouritesTabs  
              likePost={this.likePost}
              handleAddToFavorite={this.handleAddToFavorite}
              openPopUp={this.openPopUp}
              onClickNavigateToPostDetails={this.navigateToPostDetailsPage}
              profiles={this.state.profiles} 
              favouritesJobs={this.state.favouritesJobs} 
              favouritesPeople={this.state.favouritesPeople} 
              favouritesProjects={this.state.favouritesProjects} 
              data-test-id="favEvents" 
              favourites={this.state.favourites} 
              activeTab={this.state.activeTab} 
              onTabChange={this.handleChangeTab}
              onShareDataChange={this.handleShareData}
              onChangeChatMessage={this.handleChangeChatMessage}
              chatMessage={this.state.chatMessage}
              shareData={this.state.shareData}
              onClickShareButton={this.handleClickShareButton}
              isShareModalOpen={this.state.isShareModalOpen}
              onClickBackButton={this.handleBackButton}
              onClickChatButton={this.handleClickChatButton}
              isChatModalOpen={this.state.isChatModalOpen}
              isChatSend={this.state.isChatSend}
              onClickSendChat={this.handleSendChatButton}
              onClose={this.handleCloseButton}
              onClickDeleteFavourite={this.handleDeleteFavourite}
              searchKey={this.state.searchKey}
              onChangeSearchKey={this.handleChangeSearchKey}
              onNavigateToDetailsPage={this.navigateToPage}
              shareLink={this.state.shareLink}
              isLoading={this.state.isLoading}
              count={this.state.count}
              onLoadMoreClick={this.handleLoadMoreClick}
              needLoadMoreBtn={this.state.needLoadMoreBtn}
              onNavigateToCreativeProfile={this.navigateToCreativeProfile}
            />
      <ShareLinkDialog
        isOpen={this.state.openModel}
        onClose={this.handleClose}
        onClickChatButton={this.handleChat}
        shareLink={this.state.shareLink}
        isChatSend={false}
        data-test-id="ShareLinkDialogID"
      />
       <ShareMenuDialog
                    onClose={this.handleCloseChatModel}
                    onChangeChatMessage={this.handlesendChatMessage}
                    isOpen={this.state.isSendLinkToChatModalOpen}
                    shareData={this.state.shareData}
                    chatMessage={this.state.sendChatMessage}
                    data-test-id="ShareMenuDialogID"
                    onShareDataChange={this.handleshareDataChatChange}
                    onClickBackButton={this.handleNavigateBack}
                    onClickSendChat={this.handleSend}
                    profiles={this.state.getAllData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
                      account_id:option.attributes.account_id,
                      firstName: option.attributes.first_name,
                      lastName:  getSurname(option.attributes.last_name, option.attributes.account_id.toString()),
                      photo: option.attributes.photo,
                    }))}
                  />
        </>
    // Customizable Area End
  }
}

// Customizable Area Start
// Customizable Area End