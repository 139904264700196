import React from 'react'
import { Typography, Button, Table, TableCell, TableRow, TableBody, TableHead, TableContainer } from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';

import { createTheme, styled } from "@material-ui/core/styles";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});
export default function PlanCardAndTable(props: any) {

  const StyledCard = styled("div")({
    border: "0.78px solid #DFDFDF",
    padding: "24px",
    width: "212px",
    background: "#fff",
    marginBottom: "12px",
    display: "flex",
    flexFlow: "column",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      marginRight: "0px",
      width: "100%",
      marginBottom: "24px",
      boxShadow: "0px 2px 8px 0px #00000014",
      transition: "boxShadow 0.6s ease",
      "& .actionBtn": {
        background: "#0D0C22 !important",
        color: "#fff",
        border: "1px solid #0D0C22",
        transition: "background-color 0.6s ease, color 0.7s ease",
        height: "44px",
        width: "100%",
        }
      },
    "@media(max-width:1643px)": {
      padding:"7px"
    },
      "& .title": {
        fontSize: "18.72px",
        fontWeight: 400,
        lineHeight: "24.96px",
        letterSpacing: " -0.09359999746084213px",
        marginBottom: "8px"
      },
      "& .description": {
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "18.72px",
        fontFamily: "Silika-Light",
        color: "#010101",
      },
    "& .price": {
      marginTop: "15.13px",
      fontSize: "37px",
      lineHeight: "43.68px",
      display: "block",
      fontWeight:500,
      fontFamily:"Silika-Medium"
    },
    "& .perMonth": {
      fontSize: "12px",
      lineHeight: "18.72px",
      display: "block",
      fontFamily: "Silika-Light",
      fontWeight: 400,
      color: "#9B9B9B"
    },
    "& .groupFeatures": {
      margin: "38px 0"
    },
    "& .groupFeature": {
      display: "inline-flex",
      width: "100%",
      alignItems: "center",
      height: "19px",
      marginBottom: "8px",
      "& svg": {
        color: "#00FFA7",
        height: "16px"
      }
    },
    "& .groupFeatureName": {
      fontSize: "12px",
      lineHeight: "18.72px",
      marginLeft: "8px",
      fontFamily: "Silika-Light",
      fontWeight: 300,
      color: "#010101"

    },
    "& .actionBtn": {
        width: "184.08px",
        height: "43.68px",
        gap: '6.24px',
        borderRadius: '2px',
        lineHeight: '18.72px',
        border: "0.39px solid #DFDFDF",
        textTransform: "none",
        fontSize: "12px",
        marginBottom: "18px",
        fontWeight: 500,
        fontFamily: "Silika-Medium",
        [theme.breakpoints.down('sm')]: {
          marginRight: "0px",
          width: "100%",
          },
      },
    "& .cancelBtn": {
      background: "none",
      border: "none",
      fontSize: "10px",
      lineHeight: "18.72px",
      padding: "0px",
      fontFamily: "Silika-Light",
      fontWeight: 300,
      color: "#010101"
    }
  });

  const StyledCardList = styled("div")({
    flexWrap: "wrap",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center",
      width: "calc(100vw - 50px)",
      margin: "0 auto"
    }
  });
  const StyledTableContainer = styled(TableContainer)({
    width:"auto",
    boxShadow: 'none',
    border: `1px solid #DFDFDF`,
    marginTop: "38px",
    borderBottom: "0px",
    [theme.breakpoints.down('sm')]: {
      margin: "0 auto",
      width: "calc(100vw - 50px)"
    }
  });
  const StyledTableHead = styled(TableHead)({
    backgroundColor: '#f8f8f8',
    borderBottom: "0px",
    '& .MuiTableCell-root': {
      fontWeight: 'bold',
      fontSize: '14px',
      padding: '12px',
      borderBottom: '2px solid #ddd',
      color: '#333',
    },
    "& .tableCellEmpty": {
      width: "296px",
      [theme.breakpoints.down('sm')]: {
        width: "auto"
      }
    },
    "& .tablecell": {
      padding: "16px 28px 12px 28px",
      width: "98px",
      borderRight: "0.5px solid #DFDFDF",
      borderLeft: "0.5px solid #DFDFDF",
      height: "100%",
      "&:last-child": {
        borderRight: "0px",
      },
      [theme.breakpoints.down('sm')]: {
        padding: "10px 5px",
        height: "45px",
        width: "12%"
      }
    },
    "& .headTitle": {
      display: "flex",
      flexFlow: "column",
      height: "100%",
      alignItems: "center",
      gap: "8px",
      [theme.breakpoints.down('sm')]: {
      display: "block",
       textAlign:"center",
        flexDirection: "column",
        textOrientation: "mixed",
        width: "max-content !important",
        alignItems: "baseline",
        "@supports (-webkit-appearance: none)": {
          alignItems: "flex-start"
        },
        gap: "4px",
      },
      "& p": {
        borderRadius: '3.12px',
        fontWeight: 500,
        background: "#0D0C22",
        color: "#fff",
        fontSize: "12",
        lineHeight: "18.72px",
        fontFamily: "Silika-Medium",
        width: "98px",
        height: "28px",
        marginTop: "8px",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
          margin: "0",
          padding: "0 0.5px",
          height: "30px",
          textAlign: "center",
          borderRadius: "59px"
        }
      },
      "& span": {
        letterSpacing: "-0.09359999746084213px",
        fontWeight: 500,
        marginTop: "0px",
        marginBottom: "auto",
        textAlign: "center",
        fontSize: "18.72px",
        lineHeight: "24.96px",
        [theme.breakpoints.down('sm')]: {
          margin: "0",
          lineHeight: "14px",
          marginRight: "4px",
          fontSize: "14px",
        }
      }
    }
  });
  const StyledTableBody = styled(TableBody)({
    '& .MuiTableCell-root': {
      padding: '14.5px 12px',
      borderBottom: `0.5px solid #DFDFDF`,
      borderRight: "0.5px solid #DFDFDF",
      fontSize: '14px',
      color: '#010101',
      fontFamily: "Silika-Light",
      lineHeight: "18.72px",
      [theme.breakpoints.down('sm')]: {
        padding: "8px 12px"
      }
    },
    '& .MuiTableRow-root:nth-of-type(odd)': {
      backgroundColor: '#f9f9f9',
    },
    "& .tickMark": {
      width: "100%",
      display: "flex",
      justifyContent: "center",
      "& svg": {
        color: "#00FFA7"
      }
    },
    "& .titleBar": {
      "& td": {
        background: "#DFDFDF",
        fontFamily: "Silika-Medium",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: '22px',
      }
    }
  });
  return (
    <div>
      <div>
        <StyledCardList className="subscriptionCardsList">
          {
            props.subscriptionPlans.length > 0 &&
            props.subscriptionPlans.map((subscription: any) => {
              const title = subscription.attributes.plan_name;
              const description = subscription.attributes.description;
              const currency = subscription.attributes.currency;
              const duration = subscription.attributes.duration;
              const amount = subscription.attributes.amount;
              const features = subscription.attributes.features.slice(0, 3).map((f: { title: any; }) => f.title);
              const actionBtntring = `Get ${title}`;
              return (
                <StyledCard data-test-id="subscriptionCard">
                  <div className="top">
                    <Typography className="title">{title}</Typography>
                    <Typography className="description">{description}</Typography>
                  </div>
                  <div className="bottom">
                    <span className="price">
                      {currency} {amount}
                    </span>
                    <span className="perMonth">
                      {duration}
                    </span>
                    <div className="groupFeatures">
                      {features.length > 0 ? features.map((feature: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined) => <div className="groupFeature">
                        <CheckIcon />
                        <span className="groupFeatureName">{feature}</span>
                      </div>) : <></>}
                    </div>
                    <div style={{display:"flex",justifyContent:"center"}}>
                    <Button className="actionBtn" data-test-id="selectPlanID" onClick={()=>props.onSelectPlan(subscription.id)}>
                      {actionBtntring}
                    </Button>
                    </div>
                    <Typography className="cancelBtn" component="button">Cancel anytime. No strings attached.</Typography>
                  </div>
                </StyledCard>
              )
            })
          }
        </StyledCardList>

        <StyledTableContainer data-test-id="subscriptionCardsContainer">
          <Table aria-label="simple table">
            <StyledTableHead>
              <TableRow>
                <TableCell className="tableCellEmpty"></TableCell>
                {props.subscriptionPlans.map((subscription: { attributes: { plan_name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; }) =>
                  <TableCell className="tablecell">
                    <div className="headTitle">
                      <span>{subscription.attributes.plan_name}</span>
                      <Typography>Get it Now</Typography>
                    </div>
                  </TableCell>)}
              </TableRow>
            </StyledTableHead>
            <StyledTableBody>
              {props.subscriptionFeatureCategories?.map((sCategory: { id: any; attributes: { name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; }) => {
                let categoryId = sCategory.id;
                let categoryFeatures = props.subscriptionFeatures.length > 0 ? props.subscriptionFeatures.filter((sFeature: { attributes: { feature_category: { id: { toString: () => string; }; }; }; }) => sFeature.attributes.feature_category.id.toString() == categoryId) : [];
                if (categoryFeatures.length > 0) {
                  return (
                    <>
                      <TableRow key={-1} className="titleBar">
                        <TableCell colSpan={5}>{sCategory.attributes.name}</TableCell>
                      </TableRow>
                      {
                        categoryFeatures.map((feature: { id: React.Key | undefined; attributes: { title: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }; }) => (
                          <TableRow key={feature.id}>
                            <TableCell>{feature.attributes.title}</TableCell>
                            {props.subscriptionPlans && props.subscriptionPlans.length > 0 ? props.subscriptionPlans.map((sPlan: { attributes: { features: any[] }; }) => {
                              const sFeatureIds = sPlan.attributes.features.filter((f: { subscription_plan_feature_category_id: { toString: () => string; }; }) => f.subscription_plan_feature_category_id.toString() === categoryId).map((f) => f.id.toString());
                              return <TableCell>
                                <div className="tickMark">
                                  {sFeatureIds.includes(feature.id) ? <CheckIcon /> : <></>}
                                </div>
                              </TableCell>
                            }) : <></>}
                          </TableRow>))
                      }
                    </>
                  )
                }
                return <></>
              })}
            </StyledTableBody>
          </Table>
        </StyledTableContainer>
      </div>
    </div>
  )
}
