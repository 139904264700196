import React, { ReactNode } from "react";
import { IconButton, Theme, styled, Dialog, DialogTitle, DialogActions, DialogContent, Button, Typography } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    open: boolean,
    description?: string | ReactNode;
    onCancelClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    onSuccessClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void;
    cancelText?: string;
    successText?: string;
    onClose?: () => void;
  }

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& *': {
      fontSize: "16px"
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      borderRadius: 4,
      width: '600px',
      boxShadow: 'none'
    },
  }));

  const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    display: "none",
    [theme.breakpoints.down("sm")]:{
      display:"block",
      backgroundColor: '#fff',
      color: '#fff',
      padding: '18px 37px 18px 40px',
      "& h2":{
        display:"flex"
      },
      "& button":{
        marginRight: "0px",
        marginLeft: "auto",
        padding: "0px",
        borderRadius: "2px"
      },
      "& svg":{
        height: "24px",
        width: "24px"
      },
      "& path": {
        fill: "#0D0C22"
      }
    }
  }));
  
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: '46.5px 39.5px 36px 39.5px !important',
    "& span": {
      fontFamily: "Silika-Medium",
      textAlign: "center",
      fontSize: "20px",
      lineHeight: "24.13px",
      width: "100%",
      display: "block",
    },
    [theme.breakpoints.down("sm")]:{
      padding: "12px 25.5px !important",
      "& span":{
        fontSize: "16px",
        lineHeight: "19.3px"
      }
    }
}));
  
const StyledDialogActions = styled(DialogActions)(({ theme }: {theme: Theme}) => ({
    padding: '24px 40px',
    justifyContent: "center",
    display: 'flex',
    marginBottom: "22.5px",
    [theme.breakpoints.down('sm')] :{ 
      flexFlow: "column",
      padding: "16px 25.5px",
      marginBottom: "0px"
    },
    '& button': {
      fontFamily: "Silika-Medium",
      textTransform: "none",
      boxShadow: "none",
      height: "48px",
      borderRadius: "2px",
      width: "100%"
    },
    "& .cancelBtn": {
      border: "1px solid #010101",
      color: "#010101",
      [theme.breakpoints.down('sm')] :{
        marginBottom: "16px"
      },
      textTransform: "none",
      transition: "box-shadow 0.4s ease-in-out, color 0.3s ease-in-out",
      backgroundColor: "white",
      "&:hover": {
          boxShadow: "inset 400px 0 0 0 #000 !important", 
          color: "#fff",
          "& .textBtnCreate": {
          color: "#fff !important", 
          fontFamily: "silika-Medium",
          },
      },
      "& .textBtnCreate": {
          boxShadow: "inset 0 0 0 0 #000 !important",
          transition: "color 0.3s ease-in-out, box-shadow 0.4s ease-in-out",
          fontFamily: "silika-Medium",
      },
    },
    "& .successBtn": {
  marginLeft: "16px",
  [theme.breakpoints.down('sm')]: { 
    margin: 0
  },
  backgroundColor: "black",
  color: "white",
  boxShadow: "inset 0 0 0 0 #fff !important", 
  fontFamily: "silika-Medium",
  border: "0.5px solid #0D0D0D",
  transition: "box-shadow 0.4s ease-in-out, color 0.3s ease-in-out", 
  "&:hover": {
    boxShadow: "inset 400px 0 0 0 #fff !important", 
    backgroundColor: "black", 
    color: "#0D0D0D", 
    "& .textBtnCreate": {
      color: "#0D0D0D !important", 
      fontFamily: "silika-Medium",
      fontSize: "16px"
    }
  },
  "& .textBtnCreate": {
    boxShadow: "inset 0 0 0 0 #fff !important", 
    transition: "color 0.3s ease-in-out",
    fontFamily: "silika-Medium",
    fontSize: "16px",
    color: "#fff", 
  },
}
}));
  
const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: '100px',
}));


class CommonDialog2 extends React.Component<Props>{
    constructor(props: Props){
        super(props);
    }

    render(){
        return(
            <StyledDialog open={this.props.open} onClose={this.props.onClose} disableEscapeKeyDown={false}>
                <StyledDialogTitle>
                  <IconButton onClick={this.props.onClose}><CloseIcon /></IconButton>
                </StyledDialogTitle>
                <StyledDialogContent>
                  {this.props.description}
                </StyledDialogContent>
                <StyledDialogActions>
                  <StyledButton onClick={this.props.onCancelClick} color="primary" className="cancelBtn">
                      <Typography className="">{this.props.cancelText}</Typography>
                  </StyledButton >
                  <StyledButton type="submit" onClick={this.props.onSuccessClick} color="primary" variant="contained" className="successBtn">
                      <Typography className="">{this.props.successText}</Typography>
                  </StyledButton>
                </StyledDialogActions>
            </StyledDialog>
        )
    }
}

export default CommonDialog2;
