import React from "react";
import { styled, Theme, Typography } from "@material-ui/core";
import NoFavourites from "./NoFavourites";
import { FavouriteProject, FavouritesProjectsProps } from "../types";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { shareImage } from "../../../customisableuserprofiles/src/assets";

const StyledProjectsContainer = styled("div")(({theme}: {theme : Theme}) => ({
    marginTop: "95px",
    display: "inline-flex",
    flexWrap: "wrap",
    gridTemplateColumns: "repeat(auto-fit, minmax(303px, 1fr))",
    gap: "24px",
    width: "100%",
    [theme.breakpoints.down("sm")]:{
        marginTop: "24px !important",
    },
    "& .project":{
        position: "relative",
        display: "flex",
        width: "302px",
        marginRight: "12px",
        marginBottom: "12px",
        height: "233px",
        "@media (max-width: 1487px)": {
            width: "295px",
            height: "228px"
        },
        "@media (max-width: 1459px)": {
            width: "286px",
            height: "221px"
        },
        "@media (max-width: 1423px)": {
            width: "280px",
            height: "216px",
        },
        "@media (max-width: 1399px)": {
            width: "270px",
            height: "206px",
        },
        "@media (max-width: 1359px)": {
            width: "260px",
            height: "201px"
        },
        "@media (max-width: 1319px)": {
            width: "250px",
            height: "193px"
        },
        "@media (max-width: 1279px)": {
            width: "235px",
            height: "182px"
        },
        "@media (max-width: 1219px)": {
            width: "226px",
            height: "175px"
        },
        "@media (max-width: 1183px)": {
            width: "300px",
            height: "232px"
        },
        "@media (max-width: 1143px)": {
            width: "285px",
            height: "220px"
        },
        "@media (max-width: 1098px)": {
            width: "270px",
            height: "209px"
        },
        "@media (max-width: 1053px)": {
            width: "260px",
            height: "201px"
        },
        "@media (max-width: 1023px)": {
            width: "405px",
            height: "312px"
        },
        "@media (max-width: 1018px)": {
            width: "calc(50% - 24px)",
            height: "calc(40vh - 70px)"
        },
        
        "@media (max-width: 790px)": {
            width: "100%",
            height: "auto",
            display: "flex",
            flexFlow: "column",
            "& .projectName":{
                fontSize: "16px",
                marginTop: "8px",
                marginBottom: "4px"
            },
            "& .projectOwner":{
                fontSize: "14px",
                fontWeight: 400
            }
        },
        "& img":{
            width: "100%",
            height: "auto",
            borderRadius: "4px",
            "@media (max-width: 790px)": {
                height: "252px"
            }
        },
        "& .likeShareSaveButtonsDiv":{
    display: "inline-flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "12px",
    opacity: 0,
    "& .actionButton":{
      height: "24px",
      width: "24px",
      cursor: "pointer",
      "&:first-child":{
        marginRight: "12px"
      }
    },
    "& .actionButtonImg":{
      height: "22px",
      width: "22px",
      marginRight: "12px",
      cursor: "pointer"
    }
  },
    },
    "& .hoverOverlay":{
        position: "absolute",
        top:0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "flex",
        flexFlow: "column",
        justifyContent: "center",
        alignItems: "center",
        opacity: 0,
        fontFamily: "Silika-Medium",
        color: "#010101"
    },
    "& .confidentialOverlay":{
        display: "flex",
        flexFlow: "column",
        position: "absolute",
        top: 0,
        left: 0,
        background: "#0d0c22",
        height: "100%",
        width: "100%",
        backdropFilter: 'blur(8px)',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        "& .notInterested":{
            display: "inline-flex",
            width: "100%",
            color: "#fff",
            justifyContent: "center",
            marginBottom: "12px",
            "& svg":{
                marginRight: "6px"
            }
        },
        "& .confidentialPara":{
            color: "#FFFFFF",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            margin: 0,
            maxWidth: "220px",
            fontSize: "14px",
            textAlign: "center",
            "@media (max-width: 768px)": {
            display: "none"
            },
        }
    },
    "& .project:hover .hoverOverlay":{
        opacity: 1,
        backgroundColor: "rgba(217, 217, 217, 0.36)",
        transition: "opacity 0.3s ease",
        filter:"grayscale(0.3)"
    },
    "& .project:hover .likeShareSaveButtonsDiv, .likeShareSaveButtonsDiv":{
        opacity: 1,
    }
}));

class FavouriteProjectsList extends React.Component<FavouritesProjectsProps>{
    constructor(props: FavouritesProjectsProps){
        super(props);
    }
    renderLikeSaveShare = (isConfidential:boolean, index: number, isFavorited: boolean | undefined, isLiked: boolean | undefined ,id: number,profileId:number, showDeleteIcon: boolean = true) => {
      return <>
          {!isConfidential && <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv">
          {isLiked ? (
                <FavoriteIcon className="actionButton" data-test-id="favoriteIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.props.likePost(event , "unlike" , id)}/>
              ) : (
                <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.props.likePost(event , "like" , id)}/>
              )}
            
            <img src={shareImage} className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery" onClick={(event: React.MouseEvent<HTMLImageElement>)=>this.props.openPopUp(event,profileId,id, "post")}/>
          {isFavorited ? (
              <BookmarkIcon className="actionButton" data-test-id="bookmarkIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.props.handleAddToFavorite(event,"unfavourite", id)}/>
            ) : (
              <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.props.handleAddToFavorite(event,"favourite", id)}/>
            )}
          </div>}
        </>
      }

    render(){
        const {favouritesProjects, windowWidth, onClickNavigateToPostDetails} = this.props;
        const isMobile = windowWidth && windowWidth <= 599;
        if(favouritesProjects.length > 0){
            return (<StyledProjectsContainer data-test-id="favouriteProject" >
                {favouritesProjects.map((favProject: FavouriteProject, index: number) => {
                    let isConfidential = favProject.confidential;
                    const isOtherUser = favProject.profileId?.toString() !== sessionStorage.getItem("profileId");
                    const profileUserId = sessionStorage.getItem("profileUserId") || "";
                    if((sessionStorage.getItem("profileId") === favProject.profileId.toString()) || favProject.collaborator_ids.includes(profileUserId)){
                        isConfidential = false;
                      }
                    return <div className="project" data-test-id="projectContainer" onClick={() => onClickNavigateToPostDetails(isConfidential, favProject.favouritableId,favProject.profileId)}>
                    <img src={favProject.image} alt={favProject.title} />
                    {isConfidential ? <span className="confidentialOverlay">
                        <span className="notInterested">
                            <NotInterestedIcon/> <span>Confidential</span>
                        </span>
                        <p className="confidentialPara">
                            You have to be a collaborator to view this content.
                        </p>
                    </span> :
                    <>
                        <div className={isMobile ? "": "hoverOverlay"}>
                            <Typography className="projectName">{favProject.title}</Typography>
                            <Typography className="projectOwner">{favProject.postCreator}</Typography>
                        </div>
                        {this.renderLikeSaveShare(isConfidential, index, favProject.is_favourite, favProject.is_like, favProject.favouritableId,favProject.profileId, !isOtherUser)}
                    </>
                    }
                </div>
                })}
            </StyledProjectsContainer>)
        }else{
            return <NoFavourites data-test-id="no-fav-projects" title="You don’t have favourites projects yet"/>
        }
    }
}

export default FavouriteProjectsList;