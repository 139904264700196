import React from "react";

// Customizable Area Start
import {
  Typography,
  Box
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle, StyledButton, StyledTitle, AccountStatement } from "../../email-account-login/src/LoginSignUp.web";
import "../../customisableuserprofiles/src/ProfileInfo.css"
import AccountLoginController, { Props } from "./AccountLoginController";





// Customizable Area End


const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class ForgotPwdVerification extends AccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End
  render() {
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        <div style={{overflow:'hidden'}}>
        <LoginSignUp navigation={this.props.navigation} id={""}>
          <Box width={{xs : "300px", sm : "327px",  md: "360px"}}>     
              <StyledTitle>
                Forgot Password
              </StyledTitle>
            <AccountStatement>
              <Typography className="blackMedium lineHeight24 marginBottom16">
                An email has been sent to <span style={{wordBreak: "break-all"}}>{sessionStorage.getItem('email')}</span> with instructions for resetting your password. Do not forget to check your spam folder.
              </Typography>
            </AccountStatement>
            <AccountStatement>
              <Typography className="lightGrayMedium resendVerificationLinkWarn">
                If you have not received it, click below
              </Typography>
            </AccountStatement>
              <StyledButton
                variant="outlined"
                type="submit"
                data-testid="colorBtn"
                className={this.state.timer>0?"colorButton":"sendBtn"}
                disabled={this.state.timer>0}
                onClick={()=>{
                  this.resendVerificationMail()
                }}
              >
                  <Typography className="textBtnCreate">Resend Verification Link</Typography>
              </StyledButton>
              <Typography style={{ ...webStyle.formTitleText, textAlign: "right" as "right",marginTop:8,fontSize:'17px' }}>
              {this.state.timer>=10?`00:${this.state.timer}`:`00:0${this.state.timer}`}
              </Typography>
          </Box>
        </LoginSignUp>
        </div>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}


