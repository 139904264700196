import React from "react";
import { Grid, styled, Theme, Typography, Box, Avatar, Button } from "@material-ui/core";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NoFavourites from "./NoFavourites";
import { FavouritePeople } from "../types";
import { returnTruthyString, isImageOrVideo } from "../../../../components/src/HelperUtils";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import {  restricted} from "../assets"
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { shareImage } from "../../../customisableuserprofiles/src/assets";

interface Props {
    favouritesPeople: FavouritePeople[],
    windowWidth: number,
    onNavigateToCreativeProfile: (profileId: string, postId ?: string) => void;
}

const StyledPeopleContainer = styled("div")(({ theme }: { theme: Theme }) => ({
    marginTop: "95px",
    [theme.breakpoints.down('sm')]: {
        marginTop: "24px",
        display: "flex",
        justifyContent: "center",
        flexFlow: "column",
        alignItems: "center"
    },
    "& .peopleListItem": {
        border: "1px solid #DFDFDF",
        padding: "6px",
        display: "flex",
        flexFlow: "column",
        alignItems: "center",
        marginBottom: "24px",
        [theme.breakpoints.down('sm')]: {
            width: "calc(100% - 40px)"
        }
    },
    "& .card": {
        border: "1px solid #DFDFDF",
        borderRadius: "4px",
        marginBottom: "30px",
        display: "inline-flex",
        width: "calc(100% - 40px)",
        padding: "24px 20px",
        minHeight: "180px",
        justifyContent: "space-between"
    },
    "& .leftContainer":{
        display: "inline-flex",
        width: "55%",
        cursor: "pointer",
    },
    "& .peopleImage": {
        display: "flex",
        alignItems: "center",
        marginRight: "50.5px",
        cursor: "pointer",
        "& .MuiAvatar-root": {
            height: "156px",
            width: "156px",
            borderRadius: "50%",
            "@media(max-width:1278px)": {
                height: "126px",
                width: "126px",
            },
            "@media(max-width:799px)": {
                height: "126px",
                width: "126px",
            },
            "@media(max-width:699px)": {
                height: "100px",
                width: "100px",
            }
        },
        "@media(max-width:780px)": {
            marginRight: "25px"
        }
    },
    "& .peopleAbout": {
        display: "flex",
        flexFlow: "column",
        overFlow: "hidden",
    },
    "& .name": {
        fontSize: "28px",
        fontWeight: 500,
        lineHeight: "39px",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
        marginRight: "43px",
        [theme.breakpoints.down('sm')]: {
            margin: "44px 0 0",
            fontSize: "20px",
            lineHeight: "24px"
        }
    },
    "& .btnGroup": {
        display: "flex",
        flexFlow: "column",
        margin: "10px auto 10px 0",
        [theme.breakpoints.down('xs')]: {
            flexFlow: "row",
            margin: "8px auto"
        },
        "& button": {
            height: "18px",
            width: "80px",
            fontSize: "12px",
            borderRadius: "2px",
            boxShadow: "none",
            "&:hover": {
                boxShadow: "none !important",
            }
        },
        "& .greenBtn": {
            height: "18px",
            background: "#25CF2C !important",
            color: "#0D0C22"
        },
        "& .blackBtn": {
            background: "#0D0C22 !important",
            color: "#fff",
        },
        "& button:nth-child(1)": {
            marginBottom: "4px",
            [theme.breakpoints.down('xs')]: {
                margin: "0 4px 0"
            }
        }
    },
    "& .profession": {
        fontSize: "14px",
        textAlign: "start",
        "@media (max-width: 600px)": {
            textAlign: "center"
        }
    },
    "& .earning": {
        margin: "10px auto 10px 0",
        fontSize: "14px",
        "@media (max-width: 600px)": {
            textAlign: "center",
            margin: "8px auto"
        }
    },
    "& .location": {
        display: "inline-flex",
        alignItems: "center",
        fontSize: "12px",
        "& svg": {
            height: "20px",
            width: "20px"
        },
        "& span": {
            marginLeft: "4px"
        }
    },
    "& .peopleProjects": {
        display: "inline-flex",
        alignItems: "center",
        justifyContent: "end",
        padding: "0px",
        width: "45%",
        "& .postGrid":{
            aspectRatio: "177/137",
            cursor: "pointer",
            position: "relative"
        },
          "& .collaborationGridItem:hover .collabImg":{
            opacity: 0.5
          },
        "& .collaborationGridItem:hover .collaborationGridItemInside": {
            filter: "grayscale(0.3)",
            backgroundColor: "rgba(217, 217, 217, 0.36)",
            transition: "opacity 0.3s ease",
        },
        "& .collaborationGridItem, .confCollaborationGridItem":{
            borderRadius: "4px",
            aspectRatio: "177/137",
            [theme.breakpoints.down("xs")]:{
              aspecRatio: "auto",
              height: "252px"
            }
          },
        "& .collaborationGridItem:hover .collabImgHover, .collaborationGridItem:hover .likeShareSaveButtonsDiv, .collaborationGridItem:hover .removeCollab, confCollaborationGridItem:hover .collabImgHover, .confCollaborationGridItem:hover .likeShareSaveButtonsDiv, .confCollaborationGridItem:hover .removeCollab":{
            opacity: 1
          },
        "& .collabImgHover":{
            position: "absolute",
            top:0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            opacity: 0,
            gap: "10px",
            fontFamily: "Silika-Medium",
            color: "#010101"
          },
        "& .imageWrapper": {
            position: "relative",
            display: "inline-block",
            height: "100%",
            width: "100%",
            overflow: "hidden",
          },
          "& .restrictedDivMain": {
            position: "absolute",
            top: 0,
            left: 0,
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            background: "rgba(0,0,0,0.7)",
            zIndex : 2,
          },
          "& .restrictedDiv": {
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          },
        "& .restrictedImg": {
            width: "24px",
            height: "24px",
        },
        "& .confidentialText": {
            fontFamily: "Silika-Medium",
            fontSize: "15px",
            color: "#FFFFFF",
            fontWeight: 500,
            marginLeft: "4px",
            "@media (max-width: 768px)": {
                display: "none"
            },
        },
        "& .confidentialPara": {
            color: "#FFFFFF",
            fontWeight: 500,
            fontFamily: "Silika-Light",
            margin: 0,
            maxWidth: "220px",
            fontSize: "11px",
            "@media (max-width: 768px)": {
                display: "none"
            },
        },
        "& .project:hover .likeShareSaveButtonsDiv, .likeShareSaveButtonsDiv":{
            opacity: 1,
        },
        "& .likeShareSaveButtonsDiv":{
            display: "inline-flex",
            position: "absolute",
            bottom: 0,
            left: 0,
            padding: "12px",
            opacity: 0,
            "& .actionButton":{
              height: "24px",
              width: "24px",
              cursor: "pointer",
              "&:first-child":{
                marginRight: "12px"
              }
            },
            "& .actionButtonImg":{
              height: "22px",
              width: "22px",
              marginRight: "12px",
              cursor: "pointer"
            }
          },
        "& .confidential_img": {
            filter: "blur(4px)"
        },
        "& img, video": {
            height: "100%",
            width: "100%",
            borderRadius: "4px",
            objectFit: "cover"
        }
    },
    "& .confidentialOverlay":{
        display: "flex",
        flexFlow: "column",
        background: "#0d0c22",
        height: "100%",
        width: "100%",
        backdropFilter: 'blur(8px)',
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "4px",
        [theme.breakpoints.down("xs")]:{
            fontSize: "12px"
        },
        "& .notInterested":{
            display: "inline-flex",
            width: "100%",
            color: "#fff",
            justifyContent: "center",
            alignItems: "center",
            "& *":{
                fontSize: "14px",
                [theme.breakpoints.down("xs")]:{
                    fontSize: "12px",
                }
            },
            "& svg":{
                marginRight: "6px"
            }
        },
    },
    "& .peopleCard": {
        borderRadius: "2px",
        display: "inline-flex",
        padding: "0px"
    },
    "& .people": {
        position: "relative",
        aspectRatio: "104/80"
    },
    "& .peopleImgCard": {
        width: "100%",
        height: "100%",
        boxShadow: "none",
        borderRadius: "1.14px"
    },
    "& .peopleBox": {
        position: 'absolute',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "64px",
        height: "64px",
    },
    "& .peopleAvatar": {
        width: '100%',
        height: '100%',
        border: `3px solid ${theme.palette.background.paper}`,
        cursor: "pointer"
    }
}));

class FavouritePeopleList extends React.Component<Props>{
    constructor(props: Props){
        super(props);
    }
    renderLikeSaveShare = (isConfidential:boolean, index: number, isFavorited: boolean | undefined, isLiked: boolean | undefined ,id: string ) => {
        return <>
          {!isConfidential && <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv">
          {isLiked ? (
                <FavoriteIcon className="actionButton" data-test-id="favoriteIcon" />
              ) : (
                <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon" />
              )}
            
            <img src={shareImage} className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery"/>
          {isFavorited ? (
              <BookmarkIcon className="actionButton" data-test-id="bookmarkIcon" />
            ) : (
              <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon" />
            )}
          </div>}
        </>
      }
    renderPostFile = (img_url : string, postId: string, isConfidential: boolean) => {
        const urlType = isImageOrVideo(img_url);
        if(isConfidential){
          return(
            <div data-test-id="mediaImage" className="imageWrapper">
                  <div className="restrictedDivMain">
                    <div className="restrictedDiv">
                      <img
                        className="restrictedImg"
                        data-test-id="restrictedImgDiv"
                        src={restricted}
                      />
                      <p className="confidentialText">Confidential</p>
                    </div>
                    <p className="confidentialPara">
                      You have to be a collaborator to view this
                      content.
                    </p>
              </div>
              {urlType === "image" ?
                <img height="100%" width="100%" data-test-id={`image-${postId}`} src={img_url} className="confidential_img" />
                :
                <video
                  src={img_url}
                  controls
                  className="confidential_img"
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover',
                  }}
                />
              }
              </div>
          )
        }
        if(urlType === "image"){
          return <img  data-test-id={`image-${postId}`} src={img_url} className={`image ${isConfidential ? "confidential_img" : "collabImg"}`} />
        }else{
          return <video
          src={img_url}
          controls
          className={`image ${isConfidential ? "confidential_img" : "collabImg"}`}
          style={{
            height: '100%',
            width: '100%',
            objectFit: 'cover',
          }}
        />
        }
      }
    renderPeopleForDesktop = () => {
        const {favouritesPeople} = this.props;
        console.log(favouritesPeople,"favouritesPeople")
        return (
            <StyledPeopleContainer data-test-id="favouritePeopleDesktop">
               {favouritesPeople.map((favouritePeople: FavouritePeople) =>  {
                const avatarName = returnTruthyString(favouritePeople.firstName[0]) + returnTruthyString(favouritePeople.lastName[0])
                return <Box className="card" data-test-id="favouritePeopleCard" >
                <div className="leftContainer" data-test-id="peopleAvatar" onClick={() => this.props.onNavigateToCreativeProfile(favouritePeople.favouritableId.toString())}>
                    <div className="peopleImage" >
                        <Avatar
                            src={favouritePeople.photo}
                        >{avatarName}</Avatar>
                    </div>
                    <div className="peopleAbout">
                    {this.renderAbout(favouritePeople)}
                    </div>
                </div>
                <Grid className="peopleProjects" container spacing={1}>
                {favouritePeople.galleryImages.slice(0,3).map((img: { project_title:string , is_like: boolean, is_favourite: boolean, image_url: string, postId: number, confidential: boolean,collaborators_profiles: {id: string}[]} ,index) => {
                            const urlType = isImageOrVideo(img.image_url);
                            let isConfidential = img.confidential;
                            const profileId = sessionStorage.getItem("profileId") || "";
                            if(profileId == favouritePeople.favouritableId.toString() || (img.collaborators_profiles && img.collaborators_profiles.map((c: any) => c?.id?.toString()).includes(profileId))){
                                isConfidential = false;
                            }
                            return <>
                            <Grid item md={4} sm={4}   style={{ position: 'relative', display: 'inline-block' }} className="collaborationGridItem" data-test-id="peoplePost" onClick={() => this.navigateToProfilePost(isConfidential, favouritePeople.favouritableId.toString(), img.postId.toString())}>
                                    {this.renderPostFile(img.image_url, img.postId.toString(), isConfidential)}
                                    {!isConfidential &&
                                        <div className="collabImgHover">
                                            {img.project_title || "Project Name"}
                                        </div>}
                                    {this.renderLikeSaveShare(isConfidential, index, img.is_favourite, img.is_like, img.postId.toString() )}
                            </Grid>
                            </>
                        })}
                </Grid>
            </Box>
               })}
            </StyledPeopleContainer>
        )
    }

    getHourlyRate = (s1: string | null, s2: string | null) => {
        if(s1 === null || s2 === null){
            return "";
        }
        return `£${s1}/hr - £${s2}/hr`;
    }

    renderAbout = (favouritePeople: FavouritePeople) => {
        return <><Typography className="name">{`${favouritePeople.firstName} ${favouritePeople.lastName[0]}`}</Typography>
        <div className="btnGroup">
            <Button variant="contained" className="greenBtn">Shortlist</Button>
            <Button variant="contained" className="blackBtn">Available</Button>
        </div>
        <Typography className="profession">{favouritePeople.headline}</Typography>
        <Typography className="earning">{this.getHourlyRate(favouritePeople.hourly_rate_from, favouritePeople.hourly_rate_to)}</Typography>
        <div className="location">
            <LocationOnIcon /> <span>{favouritePeople.county}, {favouritePeople.country}</span>
        </div>
        </>
    }

    navigateToProfilePost = (confidential: boolean, favouritableId: string, postId?: string) => {
        if(!confidential){
            this.props.onNavigateToCreativeProfile(favouritableId, postId);
        }
    }

    getIsConfidential = (confidential: boolean, profileId: string) => {
        return confidential && (sessionStorage.getItem("profileId") !== profileId)
    }

    renderConfidentialOverlay = (img_url: string, isConfidential: boolean) => {
        return isConfidential 
        ? <span className="confidentialOverlay">
                <span className="notInterested">
                    <NotInterestedIcon/> <span>Confidential</span>
                </span>
          </span>
        :<img src={img_url} alt="peopleImgCard" className="peopleImgCard" />
    }

    renderPeopleList = () => {
        const {favouritesPeople, windowWidth} = this.props;
        const isMobile = windowWidth <= 599;
        if(favouritesPeople.length > 0){
            if(isMobile){
                return <StyledPeopleContainer data-test-id="favouritePeopleMobile">
                    {favouritesPeople.map((favouritePeople: FavouritePeople) => {
                        const avatarName = returnTruthyString(favouritePeople.firstName[0]) + returnTruthyString(favouritePeople.lastName[0])
                        const isConfidential1 = this.getIsConfidential(favouritePeople.galleryImages?.[0]?.confidential, favouritePeople.favouritableId.toString())
                        const isConfidential2 = this.getIsConfidential(favouritePeople.galleryImages?.[1]?.confidential, favouritePeople.favouritableId.toString())
                        const isConfidential3 = this.getIsConfidential(favouritePeople.galleryImages?.[2]?.confidential, favouritePeople.favouritableId.toString())
                        return <div className="peopleListItem" data-test-id="favouritePeopleCard">
                        <Grid className="peopleCard" container spacing={1}>
                            <Grid className="people" data-test-id="peoplePost" item md={4} sm={4} xs={4} onClick={() => this.navigateToProfilePost(isConfidential1, favouritePeople.favouritableId.toString(), favouritePeople.galleryImages?.[0]?.postId?.toString()) }>
                                {this.renderConfidentialOverlay(favouritePeople.galleryImages?.[0]?.image_url, isConfidential1)}
                            </Grid>
                            <Grid className="people" item md={4} sm={4} xs={4}>
                                {isConfidential2 
                                ? <span className="confidentialOverlay">
                                        <span className="notInterested">
                                            <NotInterestedIcon/> <span>Confidential</span>
                                        </span>
                                  </span>
                                :<img src={favouritePeople.galleryImages?.[1]?.image_url} alt="peopleImgCard" className="peopleImgCard" data-test-id="peoplePost" onClick={() => this.navigateToProfilePost(isConfidential2, favouritePeople.favouritableId.toString(), favouritePeople.galleryImages?.[1]?.postId?.toString())} />}
                                <Box className="peopleBox" data-test-id="peopleAvatar" onClick={() => this.props.onNavigateToCreativeProfile(favouritePeople.favouritableId.toString())}>
                                    <Avatar
                                       src={favouritePeople.photo}
                                        className="peopleAvatar"
                                    >{avatarName}</Avatar>
                                </Box>
                            </Grid>
                            <Grid className="people" data-test-id="peoplePost" item md={4} sm={4} xs={4} onClick={() => this.navigateToProfilePost(isConfidential3, favouritePeople.favouritableId.toString(), favouritePeople.galleryImages?.[0]?.postId?.toString())}> 
                            {this.renderConfidentialOverlay(favouritePeople.galleryImages?.[2]?.image_url, isConfidential2)}
                            </Grid>
                        </Grid>
                        {this.renderAbout(favouritePeople)}
                    </div>
                    })}
             </StyledPeopleContainer>
            }else{
                return this.renderPeopleForDesktop();
            }
        }else{
            return <NoFavourites data-test-id="no-fav-people" title="You don’t have favourites people yet" />;
        }
    }

    render(){
        return this.renderPeopleList();
    }
}  

export default FavouritePeopleList;
