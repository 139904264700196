import { styled, Theme, Button, Dialog, DialogActions, DialogContent, DialogContentText, Typography } from '@material-ui/core'
import React from 'react'
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { S } from '../PostCreationWebController';
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonstyles: {
            border: "1px solid #010101",
            borderRadius: "2px",
            width: "50%",
            textTransform: "none",
            backgroundColor: "#fff",
            boxShadow: "inset 0 0 0 0 #000 !important",
            transition: "box-shadow 0.4s ease-in-out, background-color 0.4s ease-in-out",
            "&:hover": {
              boxShadow: "inset 400px 0 0 0 #000 !important",
              backgroundColor: "#000",
              "& .textBtnCreate": {
                color: "#fff !important",
                fontFamily: "silika-Medium",
                fontSize: "15px",
              },
            },
            "& .textBtnCreate": {
              boxShadow: "inset 0 0 0 0 #fff !important",
              transition: "color 0.3s ease-in-out",  
              fontFamily: "silika-Medium",
              fontSize: "15px",
              color: "#0D0D0D",
            },
          },
          
        textStyles: {
            fontFamily: "Silka-light",
            fontSize: "16px",
            fontWeight: 300,
            width: "60%",
            alignItems: "center"
        },
        containedButton: {
            border: "1px solid  #010101",
            borderRadius: "2px",
            backgroundColor: "#0D0C22 !important",
            color: "white",
            width: "50%",
            textTransform: "none",
            boxShadow:"inset 0 0 0 0 #fff !important",
            '&:disabled': {
                backgroundColor: '#b0b0b0',
                color: '#fff',
                border: "none"
            },
            "&:hover":{
                boxShadow:"inset 400px 0 0 0 #fff !important",
                "& .textBtnCreate":{
                  color: "#0D0D0D !important" , 
                }  
              },
              "& .textBtnCreate":{
                boxShadow:"inset 0 0 0 0 #fff !important", 
                transition: "color 0.3s ease-in-out",  
              },
        },
        headerContent: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            "& *":{
                fontFamily: "Silika-Medium"
            }
        },
        buttonAlignment: {
            justifyContent: "space-around"

        }
    })
);

const StyledDialogActions = styled(DialogActions)(({theme}: {theme: Theme}) => ({
    margin: "10px 20px",
    "& button":{
        fontFamily: "Silika-Medium",
        boxShadow: "none",
        height: "48px"
    }
}))

type Props = {
    state: S,
    handleDelete: () => void,
    deleteCollabApi: (account_id: number) => void
}


function DeleteCollab(props: Props) {
    const { state, handleDelete, deleteCollabApi } = props
    const classes = useStyles()
    return (
        <Dialog
            style={{ padding: "20px" }}
            open={state.deleteCollabPopUp}
            onClose={handleDelete}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent className={classes.headerContent}>
                <DialogContentText id="alert-dialog-description" className={classes.textStyles}>
                    Are you sure you want to delete this collaborator?
                </DialogContentText>
            </DialogContent>
            <StyledDialogActions data-test-id="deleteCollabActions">
                <Button variant="outlined" id="close-delete-collab-popup" className={classes.buttonstyles} onClick={() => handleDelete()}><Typography className='textBtnCreate'>Cancel</Typography></Button>
                <Button className={classes.containedButton} variant="contained" id="yes-delete-btn" onClick={()=>deleteCollabApi(state.deleteID)}>
                    <Typography className='textBtnCreate'>Yes, Remove</Typography>
                </Button>
            </StyledDialogActions>
        </Dialog>
    )
}

export default DeleteCollab
