import React from "react";

import {
  // Customizable Area Start
  Box,
  Divider,
  Grid,
  Typography,
  ButtonGroup,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  FormLabel,
  InputAdornment,
  Select,
  MenuItem,
  IconButton,
  Input,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CircularProgress,
  TextareaAutosize,
  FormHelperText,
  Container,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme } from "@material-ui/core/styles";
import { searchIcon, share, save, location, cancel, CopyImage, ChatIcon, BackBtn, BannerDefault, WebSunnyIcon } from "./assets";
import DatePicker from "react-datepicker";
import { Autocomplete, Stack } from "@mui/material";
import { SafeAreaView } from "react-native";
import { AvatarGroup } from "@material-ui/lab";
import moment from "moment";
import {
  CityInfo,
  CountryInfo,
  DefaultAdSearchCountry,
  DefaultAdSearchCounty,
  DefaultAdSearchTown,
  DefaultCountry,
  DefaultCounty,
  DefaultTown,
  Event,
  FavEvent,
  IAttendee,
  ICreateEvent,
  ICustomInputProps,
  TownInfo,
} from "./AllEventsController.web";
import { styled } from "@material-ui/core/styles";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { addImage, calender, close } from "./assets";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { DesktopTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import CustomTextField from "../../../components/src/CustomTextField";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ICountry } from "country-state-city";
import utcTime from 'dayjs/plugin/utc';
import TurnedInIcon from '@mui/icons-material/TurnedIn';
import ClearIcon from '@material-ui/icons/Clear';
import { DialogActionWrapper, DialogContentTextWrapper, DialogContentTextWrapperChat, StyledBox } from "./EventView.web";
import HeaderNavbar from "../../../components/src/Navbar";
import {getStateArray} from "../../../components/src/GetStateArray.web";
import { getCityArray } from "../../../components/src/GetCityArray.web";
import { getSurname } from "../../../components/src/HelperUtils";
import { ShareLinkDialog, ShareMenuDialog } from "../../../components/src/ShareDialogs.web";

dayjs.extend(utcTime);
const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

export const ListProps = {
  style: {
    maxHeight: "150px",
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '12px' as string,
      border: '3px solid #ccc',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '8px',
    },
  } as React.CSSProperties,
}

export const DialogStyledInputWrapper=styled("div")({
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor:"#DFDFDF !important",
    fontFamily:"Silika-Light",
  },
  "& .MuiInputBase-input":{
    width:"100% !important",
    fontFamily:"Silika-Medium"
  }
});

export const DialogStyledInputWebSiteWrapper=styled("div")({
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor:"#DFDFDF !important",
  },
  "& .MuiInputBase-input":{
    fontFamily:"Silika-Medium",
    "@media (max-width:550px)": {
    width:"70% !important"
    }
  }
});

export const StyledDropDown = styled("div")({
  "& .MuiSelect-select":{
   "&:focus":{
    backgroundColor:"transparent"
   },
  },
  "& .MuiOutlinedInput-notchedOutline":{
    border: "1px solid #DFDFDF !important",
  },
  "& .MuiSelect-icon": {
    color: "black",
  },
  "& .MuiOutlinedInput-root": {
    fontFamily:"Silika-Medium",
    fontSize:"14px",
    border: "none",
    width:"95%",
    fontWeight:"bolder",
    "&.MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent",
      fontFamily:"Silka",
      border: "1px solid #DFDFDF",
      "&:focus-visible":{
       border:"none"
      }
    },
    "&:hover.MuiOutlinedInput-notchedOutline": {
      borderColor: "#DFDFDF !important",
      backgroundColor:"transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
        borderColor: "#DFDFDF !important",
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "14px !important",
      height: "48px !important",
    },
  },

  "& .MuiInputBase-formControl":{
    "&:focus-visible":{
      border:"none !important"
     }
  },

  "& .MuiSelect-nativeInput": {
    "& input": {
      padding: "0!important",
    },
  },
"& .MuiInputBase-input":{
  width: "95% !important",
},
  "@media (max-width:550px)": {
    "& .MuiOutlinedInput-root": {
      width: "105% !important",
    },
  },
});

export const StyledDropDownPickZone = styled("div")({
  
  "& .MuiOutlinedInput-root": {
    width:"100%",
    "& .MuiOutlinedInput-input": {
      paddingLeft: "40px !important",
      height: "48px !important",
      fontFamily:"Silika-Light",
      "@media (max-width:514px)": {
        width:"90% !important",
      }
    },
  }, 
});

export const StyledButton = styled("div")({
  "& .Mui-disabled": {
    backgroundColor: "grey !important",
    transition: "background-position 0.5s ease-in-out, color 0.5s ease-in-out",
    
  },
"& .ButtonCreate": {
  boxShadow: "inset 0 0 0 0 #fff !important",
  color: "white",
  "&:hover": {
    boxShadow: "inset 400px 0 0 0 #fff !important",
    "& .textBtnCreated": {
      color: "#0D0D0D !important", 
    },
  },
  "& .textBtnCreated": {
    color: "#000", 
    boxShadow: "inset 0 0 0 0 #fff !important", 
    transition: "color 0.3s ease-in-out",  
  },
  "&:disabled": {
    border: "none", 
    "& .textBtnCreated": {
      color: "grey", 
    },
  },
  "&:not(:disabled)": {
    border: "1px solid black",
  }
}
});
 
export const ButtonUpdate = styled("button")({
  background: "linear-gradient(to right, #fff 50%, #010101 50%)",
  backgroundSize: "200% 100%",
  backgroundPosition: "right bottom",
  height: "48px",
  width: "104px",
  color: "white",
  textTransform: "capitalize",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "30px",
  cursor: "pointer",
  transition: "background-position 0.5s ease-in-out, color 0.5s ease-in-out",

  "&:hover": {
    backgroundPosition: "left bottom",
    color: "#000",
  },

  "& .textBtnCreate": {
    transition: "color 0.3s ease-in-out",
    fontFamily: "Silika-Medium",
  },
});

export const CancelButton = styled("button")({
  width: "220px",
  fontFamily: "Silika-Medium",
  textTransform: "capitalize",
  border: "1px solid #010101",
  fontSize: "16px",
  background: "white",
  color: "#010101",
  cursor: "pointer",
  boxShadow: "inset 0 0 0 0 #000",
  transition: "box-shadow 0.4s ease-in-out, color 0.3s ease-in-out",

  "&:hover": {
    boxShadow: "inset 400px 0 0 0 #010101",
    color: "#fff",
  },

  "& .textBtnCreate": {
    transition: "color 0.3s ease-in-out",
    color: "#010101",
  },

});

export const DeleteButton = styled("button")({
  background: "#010101",
  height: "48px",
  width: "220px",
  color: "white",
  textTransform: "capitalize",
  fontFamily: "Silika-Medium",
  fontSize: "16px",
  cursor: "pointer",
  boxShadow: "inset 0 0 0 0 #000",
  transition: "box-shadow 0.5s ease-in-out, color 0.3s ease-in-out", 
  "&:hover": {
    
    boxShadow: "inset 400px 0 0 0 #fff",
      color: "#000000"
  },

  "& .textBtnCreate": {
    transition: "color 0.3s ease-in-out",
    boxShadow: "inset 0 0 0 0 #fff",
    fontFamily: "Silika-Medium",
  },
});


export const StyledWrapper = styled("div")({
  "& .react-datepicker": {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.189)",
    border: "none !important",
    width: "360px",
    fontFamily: "Silka",
    display: "flex",
    justifyContent: "center",
  },
  "& .react-datepicker__day--selected": {
    backgroundColor: "#DFDFDF",
    borderRadius: "24px",
    border: "1px solid var(--Grey, #9B9B9D)",
    color: "#010101",
  },
  "& .react-datepicker__header": {
    borderBottom: "none !important",
    backgroundColor: "white !important",
    marginTop: "20px",
    marginBottom: "20px",
  },
  "& .react-datepicker__current-month": {
    color: "black",
    weight: "700",
    fontFamily: "silka",
    marginBottom: "20px",
  },
  "& .react-datepicker__navigation": {
    top: "30",
    marginRight: "20px",
    marginLeft: "20px",
  },
  "& .react-datepicker__day--keyboard-selected": {
    backgroundColor: "#DFDFDF",
    borderRadius: "24px",
    border: "1px solid var(--Grey, #9B9B9D)",
    color: "#010101",
  },
  "& .react-datepicker__day": {
    height: "40px",
    width: "40px",
    fontSize: "14px",
    lineHeight: "3 !important",
  },
  "& .react-datepicker__day:hover": {
    backgroundColor: "#DFDFDF",
    borderRadius: "24px",
    border: "1px solid var(--Grey, #9B9B9D)",
    color: "#010101",
    fontSize: "14px",
    lineHeight: "3 !important",
  },
  "& .react-datepicker__day-name": {
    width: "40px",
    color: "gray !important",
    fontSize: "14px",
  },
  "& .react-datepicker__month": {
    margin: "0px !important",
  },
  "& .react-datepicker__navigation--previous": {
    backgroundImage: "url('')",
  },
  "& .react-datepicker__input-container": {
    width: "100% !important",
  },
  "& .MuiInputBase-input":{
    paddingLeft:"10px !important",
    width:"100% !important",
    "&::placeholder": {
      fontFamily: "Silka",
      weight: 400,
      fontSize: "14px",
      color:"#010101",
      opacity:"0.9"
    }
  },
  "& .MuiInput-underline":{
    "&::before":{
      border:"none"
    },
    "&::after":{
      border:"none"
    },
    "&:hover:not(.Mui-disabled)::before":{
      borderBottom:"none"
    }
  },
  "@media (max-width:550px)": {
    "& .react-datepicker": {
      width: "250px !important",
    },
    "& .react-datepicker__input-container": {
      width: "105% !important",
    },
    "& .react-datepicker__day": {
      height: "25px",
      width: "25px",
      fontSize: "9.5px",
      lineHeight: "3 !important",
    },
    "& .react-datepicker__day-name": {
      fontSize: "9.5px",
      height: "25px",
      width: "25px",
    },
    "& .react-datepicker__header":{
      width: "250px !important",
    }
  },
});

export const StyledTimeWrapper = styled("div")({
  width: "100% !important",
  "& .MuiOutlinedInput-root": {
    height: "48px !important",
    border:"1px solid #DFDFDF",
    width: "100%",
    '&:focus-visible': {
      borderColor: 'gray',  
      borderBottomColor: 'none',
      boxShadow: 'none',
    }
  },
  "& .MuiOutlinedInput-notchedOutline":{
    border:"none",
    "&::placeholder":{
      fontFamily:"Silika-Medium !important",
      fontSize:"14px",
      color:"#010101 !important",
      fontWeight:400
    },
    "&:focus-visible":{
      border:"none",
      borderBottomColor:"none"
     },
  },
  "& .MuiFormControl-root":{
     width:"100% !important"
  },
  "& .MuiInputAdornment-root": {
    position: "absolute !important" as "absolute",
    marginTop: "5px !important",
  },
  "& .MuiInputBase-input": {
    marginLeft: "50px !important",
    border: "none !important",
    width:"80% !important",
    fontFamily:"Silika-Medium !important",
    pointerEvents: "none !important" as "none",
    "&:focus-visible":{
      border:"none !important"
     },
     "&::placeholder":{
      fontFamily:"Silika-Light !important",
      fontSize:"14px",
      color:"#010101 !important",
      fontWeight:400
    },
  },
  "& .MuiInputLabel-root": {
    marginLeft: "50px !important",
    fontFamily:"Silika-Light",
    color:"#010101 !important",
    fontSize:"14px",
    zIndex:0,
    "&::placeholder":{
      fontFamily:"Silika-Medium !important",
      fontSize:"14px",
      color:"#010101 !important",
      fontWeight:400
    },
  },
  "& .MuiButtonBase-root":{
   color:"#010101",
  },
  "& .MuiInputLabel-outlined.MuiInputLabel-shrink": {
    transform: "translate(14px, -6px) scale(0) !important",
  },
  "& .MuiFormHelperText-root.Mui-error": {
    color: "rgb(244, 67, 54) !important",
  },
  "& .MuiFormHelperText-contained": {
    color: "rgb(244, 67, 54) !important",
  },
  "& .MuiFormLabel-root.Mui-error": {
    color: "rgb(244, 67, 54) !important",
  },
  "@media (max-width:550px)": {
    "& .MuiOutlinedInput-root": {
      width: "105% !important",
    },
  },
});

export const StyledWrapperAutocomplete = styled("div")({
  "& .MuiFormControl-root": {
    width: "100% !important",
  },
  "& .MuiInputBase-input":{
    fontFamily:"Silika-Medium",
    fontSize:"14px",
    height:"30px",
    "&::placeholder":{
      fontFamily:"Silka",
      fontSize:"14px"
    },
  },
  "& .MuiOutlinedInput-input":{
    width: "100% !important",
  },
  "& .MuiAutocomplete-paper":{
    maxHeight: "100px", 
    overflowY: 'auto',
  },
  "& .MuiOutlinedInput-root":{
    padding:"0px !important",
    paddingLeft:"9px !important"
  }
});

export const StyledWrapperAdSearchAutocomplete = styled("div")({
  "& .MuiFormControl-root": {
    width: "100% !important",
  },
  "& .MuiOutlinedInput-root":{
    height:"48px"
  },
  "& .MuiInputBase-input":{
    fontFamily:"Silika-Light",
    fontSize:"14px",
    fontWeight:"bolder !important",
    color: "#010101 !important",
    "&::placeholder":{
      fontFamily:"Silika-Light",
      fontSize:"14px",
      fontWeight:"bolder !important",
      color: "#010101 !important",
    },
  },
  "& .MuiOutlinedInput-input":{
    width: "100% !important",
  },
  "& .MuiAutocomplete-paper":{
    maxHeight: "100px", 
    overflowY: 'auto',
     fontFamily: "Silika-Light !important",
    fontSize: "14px",
    fontWeight: "bolder !important",
  },
  "& .MuiAutocomplete-option": {
    fontFamily: "Silika-Light !important",
    fontSize: "14px",
    fontWeight: "bolder !important",
    color: "#010101 !important",
  }
});

export const StyledWrapperTownAutocomplete = styled("div")({
  "& .MuiFormControl-root": {
    width: "100% !important",
  },
  "& .MuiOutlinedInput-root":{
    padding:"0px !important",
    paddingLeft:"9px !important"
  },
  "& .MuiAutocomplete-root":{
    marginTop:"8px !important",
  },
  "& .MuiInputBase-input":{
    width:"70vw !important",
    maxWidth:"100% !important",
    height:"30px",
    "@media (max-width:492px)": {
      width:"100% !important",
    }
  },
  "@media (max-width:592px)": {
    paddingRight:"10px !important"
   },
   "@media (max-width:584px)": {
    paddingRight:"0px !important"
   }
});

const CustomBackTop = styled('div')({
  "& .MuiButton-root": {
    width:"48px !important",
    height:"48px",
    minWidth:"48px !important"
  },
});

export const CustomMenuItem = styled(MenuItem)({
  fontFamily:"Silika-Medium !important",
  "&:hover": {
    backgroundColor: "black",
    color: "white",
  },
});

export const StyledAdSearchDate = styled('div')({
  "& .react-datepicker-wrapper": {
    width:"100% !important",
  },
  "& .react-datepicker__input-container": {
    width: "100% !important",
  },
  "& .MuiInputBase-input":{
    paddingLeft:"10px !important",
    width:"100% !important",
    "&::placeholder": {
      fontFamily: "Silka",
      weight: 400,
      fontSize: "14px",
      color:"#010101",
      opacity:"0.9"
    }
  },
  "& .MuiInput-underline":{
    "&::before":{
      border:"none"
    },
    "&::after":{
      border:"none"
    },
    "&:hover:not(.Mui-disabled)::before":{
      borderBottom:"none"
    }
  }
});

const StyledAdSearchDrop = styled('div')({
  "& .MuiOutlinedInput-root": {
    width: "95% !important",
    background: "none !important",
    fontFamily: "Silika-Light !important",
    fontWeight:"bolder !important",
    color: "#010101 !important",
    "@media (max-width:960px)": {
      width: "100% !important",
    },
  },
  "& .MuiInputBase-input": {
    color: "#010101 !important",
    width: "100% !important",
    weight:"bolder !important",
    "&::placeholder": {
      fontFamily: "Silika-Light !important",
      fontWeight: "bolder",
      fontSize: "14px",
      color:"#010101",
    }
  },
  "& .MuiFormControl-root": {
    width: "95% !important",
    fontFamily: "Silika-Light !important",
    fontWeight:"bolder !important",
    color: "#010101 !important",
    "@media (max-width:960px)": {
      width: "100% !important",
    },
  }
});

const StyledCustomInput= styled('div')({
  "& .MuiInputBase-input":{
    fontFamily: "Silika-Light",
    fontSize:"14px",
    fontWeight: "bolder",
    width:"85% !important",
    border: "transparent!important",
    "&::placeholder": {
      fontWeight: "bolder",
      color: "#010101 !important",
      fontFamily: "Silika-Light",
      fontSize:"14px",
    },
  }
})

export const CustomInput = React.forwardRef(
  ({ value, onClick, placeHolder,isEndIcon,isStartIcon,name,onBlur,onChange }:ICustomInputProps , reference: React.Ref<HTMLDivElement>) => (
    <StyledCustomInput className="custom-date-input" onClick={onClick} ref={reference} style={{ position: 'relative' }}>
      <Input
        type="text"
        value={value}
        style={{
          cursor: "pointer",
          border: "1px solid #DFDFDF",
          height: "48px",
          width: "100%",
          fontFamily: "Silika-Light",
          color: "#010101",
          borderRadius:"3px"
        }}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeHolder}
        startAdornment={isStartIcon ? (
          <img
            src={calender}
            style={{ marginRight: "10px", marginLeft: "10px" }}
            alt="Start Icon"
          />
        ) : null}
        endAdornment={isEndIcon ? (
         <KeyboardArrowDownIcon   style={{ position: 'absolute', top: '50%', right: '10px', transform: 'translateY(-50%)' }}/>
        ) : null}
      />
    </StyledCustomInput>
  )
);

const StyledAccordian = styled('div')({
  marginTop:"30px",
  "& .MuiAccordionSummary-root": {
    width:"158px",
  
  },
  "& .MuiTypography-body1":{
    fontFamily:"silka",
    fontWeight:"500",
    fontSize:"20px",
    color:"#000000"
  },
  "& .MuiIconButton-label":{
    fontSize:"20px",
    color:"#000000"
  },
  "& .MuiPaper-elevation1":{
    boxShadow:"none !important",
  },
  "& .MuiAccordionDetails-root":{
    padding:"0px"
  },
  "& .accordianBorderBox":{
    border:"none !important"
  }
});

const StyledInput = styled('div')({
  "& .MuiOutlinedInput-root": {
    height: "48px",
    borderColor: "transparent !important",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
      border: "1px solid #DFDFDF !important",
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    border: "1px solid #DFDFDF !important",
  }
 
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor: "none !important",
  },
  "& .MuiInputBase-input":{
    fontFamily: "Silika-Medium",
    fontSize:"16px",
    fontWeight: 500,
    width:"97% !important",
    border: "transparent!important",
    "&::placeholder": {
      fontWeight: 500,
      color: "#010101 !important",
      fontFamily: "Silika-Medium",
      fontSize:"16px",
      opacity:"0.9"
    },
    "@media (max-width:680px)": {
      width: "80% !important",
    },
   
  },
  "& .MuiFormControl-root":{
    border: "transparent !important",
  }
});

const IconContainer = styled("div")({
  position: "absolute",
  left: "10px",
  top: "50%",
  transform: "translateY(-50%)",
  pointerEvents: "none"
})

const AvatarGroupStyled = styled(AvatarGroup)({
 "& .MuiAvatar-root":{
  height:"28px",
  width:"28px",
  fontSize:"14px"
 }
})


export const commonValidationFields = {
  eventName: Yup.string()
    .required("Event Name is required")
    .max(100, "Event name should be maximum 100 characters"),
  inPerson: Yup.string().required("In Person is required"),
  startTime: Yup.string()
  .required("Start Time is required"),
  description: Yup.string().max(
    500,
    "Description should be maximum 500 characters"
  ),
  startDate: Yup.string().required("Start Date is required"),
  endDate: Yup.string().required("End Date is required"),
  endTime: Yup.string()
    .when(
      ["startDate", "endDate", "startTime"],
      (startDate: string, endDate: string, startTime: string) => {
        return Yup.string()
          .test({
            test: function (endTime) {
              if (startDate && endDate && startTime && startDate === endDate) {
                const startTimeAsDate = new Date(
                  `1970-01-01 ${dayjs(startTime).format("HH:mm:ss")}`
                );
                const endTimeAsDate = new Date(
                  `1970-01-01 ${dayjs(endTime).format("HH:mm:ss")}`
                );
                return endTimeAsDate > startTimeAsDate;
              }
              return true;
            },
            message: "End Time must be greater than Start Time",
          })
          .required("End Time is required");
      }
    )
    .required("End Time is required").typeError(" "),
 timeZone: Yup.string().required("timeZone is required"),
};

const getValidationSchema = (inPerson: string) => {
  const inPersonSpecificFields = {
    country: Yup.object().shape({
      name: Yup.string().required('Country is required'),
    }),
    address: Yup.string().required("Address is required"),
    postcode: Yup.string().required("Postcode is required"),
  };

  const onlineSpecificFields = {
    linkToMeet: Yup.string().required("Link to meet is required"),
  };

  const bothSpecificFields = {
    ...inPersonSpecificFields,
    ...onlineSpecificFields,
  };


  switch (inPerson) {
    case "in person":
      return Yup.object().shape({
        ...commonValidationFields,
        ...inPersonSpecificFields,
      });
    case "online":
      return Yup.object().shape({
        ...commonValidationFields,
        ...onlineSpecificFields,
      });
    case "both":
      return Yup.object().shape({
        ...commonValidationFields,
        ...bothSpecificFields,
      });
    default:
      return Yup.object().shape({
        ...commonValidationFields,
      });
  }
};

const StyledCreateEventDatePick = styled("div")({
  "& .react-datepicker-wrapper":{
    width: "100% !important",
  },
  "& .react-datepicker": {
    boxShadow: "0 0 20px 0 rgba(0, 0, 0, 0.189)",
    border: "none !important",
    fontFamily: "Silika-Medium",
    display: "flex",
    justifyContent: "center",
    "@media (max-width:1258px)": {
      "& .react-datepicker": {
        width: "90%",
      },
      "& .react-datepicker__input-container":{
        width: "96% !important",
      }
    },
  },
})

const StyledGlobWrapper = styled(Box)({
  margin:"0px 72px 0px 79px",
    "@media (max-width:1440px)": {
      width:"89.1% ",
    },
    "@media (max-width:960px)": {
      width:"89.1%",
       marginTop:"30px !important",
       marginLeft: "auto",
       marginRight: "auto",
    },
    "@media (max-width:560px)":{
     "& .loadMoreBtn":{
       width:"200px !important"
     }
    },
    "& .eventName":{
      fontFamily: "Silika-Medium",
      fontSize: "20px",
      fontWeight: 500,
    },
    "& .filterListSelect":{
      width:"100%"
    },
    "& .MuiPaper-elevation1":{
      boxShadow:"none !important",
      border:"0.5px solid #DFDFDF"
    },
    "& .mainTitle":{
      "@media (max-width:960px)": {
       marginBottom:"20px !important"
    },
    },
    "& .createBtnEvent":{
      boxShadow:"inset 0 0 0 0 #fff !important",
      border:"0.5px solid #0D0D0D",
      color: "white",
      "&:hover":{
        boxShadow:"inset 400px 0 0 0 #fff !important",
        "& .textBtnCreate":{
          color: "#0D0D0D !important" , 
        }  
      }
    },
    "& .textBtnCreate":{
      boxShadow:"inset 0 0 0 0 #fff !important", 
      transition: "color 0.3s ease-in-out",  
    }
});

const StyledAdvanceSearchWrapper = styled("div")({
  display:"flex",
  justifyContent:"space-between",
  width:"100%",
  flexWrap:"wrap",
  "& .datePickerDiv":{
    width:"32%",
    "@media (max-width:960px)": {
      marginTop:"10px",
    },
    "@media (max-width:533px)": {
      width:"100%",
    },
  },
  "& .addressDiv":{
    display:"flex",
    flexWrap:"wrap",
    justifyContent: "space-between",
    "@media (max-width:960px)": {
      marginTop:"10px",
      width:"100%",
    },
  }
})

const StyledChipBtn = styled(Button)({
  textTransform:"none",
  fontFamily:"Silika-Light",
  fontWeight:"bolder",
  fontSize:"14px",
  marginLeft:"0px !important",
  marginRight:"10px",
  marginTop:"11px",
  border:"0.5px solid lightGray",
  "@media (max-width:560px)": {
    fontSize:"10px",
  },
  "& .closeIconBreadCumb":{
    "@media (max-width:560px)": {
     height:'10px',
     width:"10px"
    },
  }
})

const StyledTabWrapper = styled(Box)({
  marginTop:"51px",
  display:"flex",
  flexDirection:"column",
  "& .tabTitle":{
    fontFamily:"Silika-Medium !important"
  },
  "& .tabBtn":{
    borderRadius:"0px"
  },
  "& .cardContainer":{
   display:"flex",
   columnGap:"2%",
    "@media (max-width:960px)": {
      marginBottom:"20px",
    },
    "@media (max-width:1440px)": {
      columnGap:"42px !important",
    },
    "@media (max-width:1435px)": {
      columnGap:"2% !important",
    },
  },
  "& .cardOfEvent":{
    width:"32%",
    marginBottom:"2.1%",
    "@media (max-width:960px)": {
      width:"49% !important",
      marginBottom:"20px !important",
    },
    "@media (max-width:600px)": {
      width:"100% !important",
      marginBottom:"20px !important",
    },
    "@media (max-width:1440px)": {
      width:"399px",
      marginBottom:"42px !important",
    },
    "@media (max-width:1435px)": {
      width:"32%",
      marginBottom:"20px !important",
    },
  },
})

export const StyledFormWrapper = styled(Form)({
  "& .formContainerEvent":{
    "@media (max-width:537px)": {
    padding:"8px 20px 8px 20px !important"
    },
    "@media (max-width:491px)": {
      padding:"8px 5px 8px 5px !important"
      }
  },
  "& .btnAction":{
    padding:"10px !important",
    "@media (max-width:532px)": {
    paddingRight:"20px !important"
    },
    "@media (max-width:487px)": {
      paddingRight:"5px !important"
    }
  },
  "& .dateTimeContainer":{
    width:"100%"
  },
  "& .dateTimeContainerInner":{
    "@media (max-width:532px)": {
      width:"100% !important",
      margin:"-16px 0px -16px 0px !important"
    },
  },
  "& .dateFormContainer":{
    "@media (max-width:532px)": {
     paddingLeft:"0px !important"
    }
  },
  "& .locationDropDown":{
    "@media (max-width:592px)": {
      paddingRight:"28px !important"
     },
     "@media (max-width:540px)": {
      paddingRight:"0px !important"
     }
  },
})

export const StyledDialogCreateWrapper = styled(Dialog)({
  "& .MuiDialogContent-root":{
    padding:"8px 7px !important"
  },
  "& .MuiDialogTitle-root":{
    padding:"0px 0px 0px 40px",
    height:"60px",
    alignItems:"center",
    display:"flex",
    "@media (max-width:532px)": {
    padding:"16px 20px !important",
    fontSize:"14px !important"
    },
    "@media (max-width:487px)": {
    padding:"5px 10px !important",
    fontSize:"10px !important"
    }
  },
 "& .MuiDialog-paperScrollPaper":{
   maxWidth:"1195px"
 },
 "& .MuiDialog-paperFullWidth":{
   width:"100% !important"
 },
 "& .MuiInputBase-Input":{
  height:"45px !important",
},
 "& .MuiDialog-paper":{
  "@media (max-width:492px)": {
     margin:"10px"
  },
  "@media (max-width:380px)": {
    margin:"5px"
 }
 }
})

const StyledShareChatDial =styled(Dialog)({
"& .headingText":{
  fontFamily:"Silika-Medium !important"
}
})

export const StyledDropDownInPerson = styled("div")({
  "& .MuiOutlinedInput-root": {
    width: "100% !important",
  },
  "@media (max-width:592px)": {
    paddingRight:"25px !important"
   },
   "@media (max-width:540px)": {
    paddingRight:"0px !important"
   }
})

export const PostCodeContainer = styled(Grid)({
  "@media (max-width:960px)": {
    paddingLeft:"38px !important"
  },
  "& .MuiInputBase-Input":{
    height:"45px !important",
  }
})

const StyleContainer = styled(Container)({
  padding:"0px !important",
"& .MuiContainer-root":{
  padding:"0px !important"
},
"@media(max-width:600px)": {
  padding:"0px !important"
 },
})

// Customizable Area End

import AllEventControllerWeb, {
  Props,
  configJSON,
} from "./AllEventsController.web";

export default class AllEventsWeb extends AllEventControllerWeb {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  getButtonClass() {
    return this.state.shareData?.length === 0 ? 'opacity-05' : '';
  }

  submitButton = (values:any,errors:any,touched:any) => {
    return (<StyledButton>
    <Button
      data-test-id="submitCreateBtn"
      variant="contained"
      style={webStyle.createBtnEvent}
      className="ButtonCreate"                            
      type="submit"
      disabled={
        values.timeZone==" "||
        Object.keys(errors).length > 0||
        Object.keys(touched).length == 0 ||     
        (getStateArray(values?.country?.isoCode).length > 0 && values?.county?.name==DefaultCounty.name) ||
        (getCityArray(values?.country?.isoCode,values?.county?.isoCode).length>0 && values?.town?.name==DefaultTown.name)||!this.state.validFile}
    >
       <Typography className="textBtnCreated" style={{color: "white"}}>Create</Typography>
    </Button>
  </StyledButton>)
  }

  getErrorBox = (fieldError?: string,touched?:boolean) => {
    if (!fieldError||!touched ) return null;
  
    return (
      <Box
        style={{
          color: "#f44336",
          fontSize: "12px",
          marginTop: "10px",
        }}
      >
        {fieldError}
      </Box>
    );
   };

   getErrorBoxValid = ()=> {
    return !this.state.validFile && (
      <Box
        style={{
          color: "#f44336",
          fontSize: "12px",
          marginTop: "10px",
        }}
      >
        No upload can be greater than 5MB
      </Box>
    )
   }
   
  getTimeZoneError = (errors: any, touched: any) => {
    if (errors.timeZone && touched.timeZone) {
      return (
        <Box
          style={{
            fontSize: "12px",
            color: "#f44336",
            marginTop: "10px",
          }}
        >
          TimeZone is required
        </Box>
      );
    }
    return null;
  }

  dialogCreateEvents =()=>{
    return(
      <StyledDialogCreateWrapper
      open={this.state.isOpenedCreateEventPopUp}
      onClose={this.hideCreateEventModal}
      maxWidth="lg"
      fullWidth
      aria-describedby="alert-dialog-slide-description"
    >
      <Grid style={webStyle.experienceInnerDiv1} className="headCreateEvent">
        <DialogTitle style={webStyle.dialougeHeading}>
          {"Create a new event"}
        </DialogTitle>
        <img
          onClick={this.hideCreateEventModal}
          data-test-id="closeIcon"
          src={close}
          style={{
            width: "14px",
            height: "14px",
            marginRight: "37px",
            cursor: "pointer",
          }}
          id="close-img"
        ></img>
      </Grid>
      <DialogContent>
        <Formik
          validateOnChange={true}
          validateOnBlur={true}
          initialValues={this.state.createEventInitialValues}
          validationSchema={(values:ICreateEvent) =>
            getValidationSchema(this.state.inPersonOnlineBoth)
          }
          onSubmit={(values, { resetForm }) => {
            const modifiedValues = {
              ...values,
              inPerson: this.state.inPersonOnlineBoth,
              bannerImage: this.state.uploadedFile,
              startDate: values.startDate,
              startTime: dayjs(values.startTime).format("HH:mm:ss"),
              endDate: values.endDate,
              endTime: dayjs(values.endTime).format("HH:mm:ss"),
            };
            if (this.state.validFile) {
                this.eventCreation(modifiedValues);
                resetForm();
            }
          }}
        >
          {({ handleSubmit, setFieldValue,setFieldTouched, values, errors, touched,handleBlur,handleChange,getFieldProps}) => {
            return (
              <StyledFormWrapper onSubmit={handleSubmit}>
                <Grid
                  container
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    margin: "auto",
                    width: "100%",
                  }}
                >
                  <Grid
                    item
                    xl={12}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      margin: "auto",
                      width: "100%",
                    }}
                  >
                    <Grid item style={webStyle.formContainer} className="formContainerEvent">
                      <FormControl fullWidth style={{ width: "100%" }}>
                        <FormLabel style={webStyle.formLableText}>
                          <Box style={{ display: "flex" }}>
                            Event name{" "}
                            <Box style={{ color: "red" }}>*</Box>
                          </Box>
                          <Box
                            style={{
                              alignContent: "flex-end",
                              fontSize: "12px",
                              fontFamily: "silika-Light",
                            }}
                          >
                            {values.eventName.length}/100
                          </Box>
                        </FormLabel>
                        <DialogStyledInputWrapper>
                        <TextField
                          {...getFieldProps("eventName")}
                          type="string"
                          id="profileTitel"
                          variant="outlined"
                          title="Event name"
                          fullWidth
                          inputProps={{
                            maxLength: 100
                          }}
                        />
                        </DialogStyledInputWrapper>
                      </FormControl>
                    </Grid>
                    <Grid item style={webStyle.formContainer} className="formContainerEvent">
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <FormLabel style={webStyle.formLableText}>
                          Banner image
                        </FormLabel>
                        <Grid
                          item
                          sm={12}
                          style={webStyle.imageGridStyle}
                        >
                          <Grid item style={webStyle.imageDisplay}>
                            {this.handleValidFile() && (
                              <Box style={webStyle.borderOfImage}>
                                <>
                                  <img
                                    width="108px"
                                    height="108px"
                                    src={this.state.uploadedFileUrl}
                                    style={{ padding: "13px" }}
                                  ></img>
                                  <CloseIcon
                                    data-test-id="removeFile"
                                    id="remove-image"
                                    width="12px"
                                    height="12px"
                                    style={webStyle.crossImage}
                                    onClick={this.handleRemoveFile}
                                  />
                                </>
                              </Box>
                            )}
                            {this.state.uploadedFileUrl == "" && (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="label"
                                style={webStyle.borderOfImage}
                              >
                                <input
                                  onChange={(event) =>
                                    this.handleImageChange(event)
                                  }
                                  type="file"
                                  data-test-id="uploadImageInput"
                                  hidden
                                  name="uploadMultipleImages"
                                  accept="video/*,image/*"
                                  className="filetype"
                                  id="group_image1"
                                />
                                <Avatar
                                  style={{
                                    borderRadius: 0,
                                    width: "90px",
                                    height: "90px",
                                  }}
                                  className="profile-avatar"
                                  src={addImage}
                                ></Avatar>
                              </IconButton>
                            )}
                          </Grid>
                        </Grid>
                        {this.getErrorBoxValid()}
                      </Grid>
                    </Grid>
                    <Grid item style={webStyle.formContainer} className="formContainerEvent">
                      <FormControl fullWidth>
                        <FormLabel style={webStyle.formLableText}>
                          Add a description
                          <Box
                            style={{
                              fontSize: "12px",
                              fontFamily: "silika-Light",
                            }}
                          >
                            {" "}
                            {values.description.length}/500
                          </Box>
                        </FormLabel>
                        <DialogStyledInputWrapper>
                        <Field
                          name="description"
                          component={CustomTextField}
                          placeHolder=""
                          type="string"
                          variant="outlined"
                          multiline
                          row={5}
                          value={values.description}
                          maxLength={500}
                        />
                        </DialogStyledInputWrapper>
                      </FormControl>
                    </Grid>
                    <Grid item style={webStyle.formContainer} className="formContainerEvent">
                      <FormControl fullWidth>
                        <FormLabel style={webStyle.formLableText}>
                          Event website
                        </FormLabel>
                        <DialogStyledInputWebSiteWrapper>
                        <TextField
                          fullWidth
                          onChange={(event) =>
                            setFieldValue("eventWeb", event.target.value)
                          }
                          type="textarea"
                          data-test-id="eventWeb"
                          variant="outlined"
                          name="eventWeb"
                          style={webStyle.input}
                          value={values.eventWeb}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <InsertLinkIcon />
                              </InputAdornment>
                            ),
                          }}
                        />
                        </DialogStyledInputWebSiteWrapper>
                      </FormControl>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ padding: "18px 37px 18px 40px"}}
                      className="formContainerEvent dateTimeContainer"
                      onClick={() => this.handleStatesCovs("filterss")} data-test-id="timeZoneC"
                    >
                      <FormLabel style={webStyle.formLableText}>
                        Date and time
                      </FormLabel>
                      <Grid container spacing={4} className="dateTimeContainerInner">
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="dateFormContainer">
                          <StyledWrapper>
                            <StyledCreateEventDatePick>
                            <DatePicker
                              {...getFieldProps("startDate")}
                              data-test-id="startDatePicker"
                              onChange={(value: Date) => {
                                setFieldValue(
                                  "startDate",
                                  dayjs(value).format("DD/MM/YYYY")
                                );
                                this.setStartDate(value);
                                setFieldValue(
                                  "endDate",
                                  ""
                                );
                                this.setEndDate(new Date());
                              }}       
                              dateFormat={"DD/MM/YYYY"}
                              value={values.startDate}
                              className="datePickDiv"
                              selected={this.state.startDate}
                              onBlur={handleBlur}
                              minDate={new Date()}
                              customInput={
                                <CustomInput
                                  placeHolder={"Start Date *"}
                                  isStartIcon
                                  onBlur={handleBlur}
                                  onChange={handleChange}
                                  name="startDate"
                                />
                              }
                            />
                            {this.getErrorBox(errors.startDate,touched.startDate)}
                          </StyledCreateEventDatePick>
                          </StyledWrapper>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}  className="dateFormContainer"  onClick={() => this.handleStatesCovs("filterss")} data-test-id="startTime">
                          <StyledTimeWrapper>
                            <LocalizationProvider
                              dateAdapter={AdapterDayjs}
                            >
                               <Field
                                 fullWidth
                                  onChange={(value:any) => {
                                    setFieldTouched("startTime", true);
                                    setFieldValue("startTime", value?value:"00:00");
                                  }}
                                  component={DesktopTimePicker}
                                  name="startTime"
                                  label="Start Time *"
                                  data-test-id="startTimePicker"
                                  ampm={false}
                                  openTo="hours"
                                  onBlur={() => {
                                    setFieldTouched("startTime", true);
                                  }}
                                  format="HH:mm"
                                />
                            </LocalizationProvider>
                             {this.getErrorBox(errors.startTime,touched.startTime)}
                          </StyledTimeWrapper>
                        </Grid>
                        <Grid item xl={4} lg={4} md={6} sm={6} xs={12}  className="dateFormContainer">
                        <FormControl fullWidth>
                        <StyledDropDown style={{position:"relative"}}>
                        <StyledDropDownPickZone>
                          <IconContainer>
                                <img src={WebSunnyIcon} alt="webSunnyIcon" />
                          </IconContainer>
                          <Field
                           fullWidth 
                           name="timeZone"
                           placeHolder="aj"
                           data-test-id="timeZone"
                            component={Select}
                            variant="outlined"
                            IconComponent={KeyboardArrowDownIcon}
                            type="button"
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: 270,
                                  overflowY: "auto",
                                },
                              },
                              anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                              },
                              transformOrigin: { 
                                horizontal: 'left',
                                vertical: 'top',
                              },
                              getContentAnchorEl: null
                            }}
                            value={values.timeZone}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                             setFieldTouched("timeZone",true);
                             setFieldValue("timeZone",event.target.value);
                            }}
                          >
                            <CustomMenuItem value={" "} disabled selected>
                              Pick Time Zone *
                            </CustomMenuItem>
                                  {
                                    this.state.GetTimeZoneData.length > 0 && this.state.GetTimeZoneData.map((time, index) => {
                                      return (
                                        <CustomMenuItem value={time}>
                                          {time}
                                        </CustomMenuItem>
                                      )
                                    })
                                  }
                          </Field>                
                          {this.getTimeZoneError(errors,values)}
                          </StyledDropDownPickZone>
                        </StyledDropDown>   
                      </FormControl>
                      </Grid>
                      </Grid>
                      <Grid item xs={12} style={{marginTop:"10px"}}>
                        <Grid container spacing={4} className="dateTimeContainerInner">
                          <Grid item xl={4} lg={4} md={6} sm={6}xs={12} className="dateFormContainer">
                            <StyledWrapper>
                            <StyledCreateEventDatePick>
                              <DatePicker
                                 {...getFieldProps("endDate")}
                                data-test-id="endDatePicker"
                                disabled={
                                  values.startDate == "" ? true : false
                                }
                                value={values.endDate}
                                selected={
                                  this.state.endDate
                                    ? this.state.endDate
                                    : this.state.startDate
                                }
                                minDate={this.state.startDate}
                                onChange={(value: Date) => {
                                  setFieldValue(
                                    "endDate",
                                    dayjs(value).format("DD/MM/YYYY")
                                  );
                                  this.setEndDate(value);
                                }}
                                dateFormat={"DD/MM/YYYY"}
                                onBlur={handleBlur}
                                customInput={
                                  <CustomInput
                                    placeHolder={"End Date *"}
                                    isStartIcon
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    name="endDate"
                                  />
                                }
                              />
                              {this.getErrorBox(errors.endDate ,touched.endDate)}
                             </StyledCreateEventDatePick>
                            </StyledWrapper>
                          </Grid>
                          <Grid item xl={4} lg={4} md={6} sm={6} xs={12} className="dateFormContainer">
                            <StyledTimeWrapper>
                              <LocalizationProvider
                                dateAdapter={AdapterDayjs}
                              >
                                <Field
                                 onChange={(value:any) => {
                                  setFieldTouched("endTime", true);
                                  setFieldValue("endTime", value?value:"00:00");
                                }}
                                  component={DesktopTimePicker}
                                  name="endTime"
                                  label="End Time *"
                                  ampm={false}
                                  openTo="hours"
                                  format="HH:mm"
                                  data-test-id="endTimePicker"
                                  showTimeSelect
                                  showTimeSelectOnly
                                />
                                {this.getErrorBox(errors.endTime ,touched.endTime)}
                              </LocalizationProvider>
                            </StyledTimeWrapper>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid item  xl={4} lg={4} md={6} sm={6} xs={12} style={webStyle.fomrContainerDrop} className="formContainerEvent">
                      <FormControl fullWidth>
                        <FormLabel style={webStyle.formLableText}>
                          <Box style={{ display: "flex" }}>
                            is in person or online?{" "}
                            <Box style={{ color: "red" }}>*</Box>
                          </Box>
                        </FormLabel>
                        <StyledDropDownInPerson>
                        <StyledDropDown>
                          <Field
                            fullWidth
                            data-test-id="inPerson"
                            name="inPerson"
                            component={Select}
                            variant="outlined"
                            IconComponent={KeyboardArrowDownIcon}
                            type="button"
                            MenuProps={{
                              anchorOrigin: {
                                horizontal: 'left',
                                vertical: 'bottom',                     
                              },
                              transformOrigin: {   
                                horizontal: 'left',
                                vertical: 'top',
                              },
                              getContentAnchorEl: null,
                            }}
                            value={this.state.inPersonOnlineBoth}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              this.handleInpersonOnline(
                                event.target.value
                              );
                              setFieldValue(
                                "inPerson",
                                event.target.value
                              );
                              setFieldValue("country", DefaultCountry);
                              setFieldValue("county", DefaultCounty);
                              setFieldValue("town", DefaultTown);
                              setFieldValue("address", "");
                              setFieldValue("linkToMeet", "");
                              setFieldValue("postcode", "");
                            }}
                          >
                            <CustomMenuItem value={"in person"}>
                              In person
                            </CustomMenuItem>
                            <CustomMenuItem value={"online"}>
                              Online
                            </CustomMenuItem>
                            <CustomMenuItem value={"both"}>
                              Both
                            </CustomMenuItem>
                          </Field> 
                        </StyledDropDown>
                        </StyledDropDownInPerson>
                      </FormControl>
                    </Grid>
                    {this.handleOnlineBoth() && (
                      <Grid item style={webStyle.formContainer} className="formContainerEvent">
                        <FormControl fullWidth>
                          <FormLabel style={webStyle.formLableText}>
                            <Box style={{ display: "flex" }}>
                              Link to the meeting{" "}
                              <Box style={{ color: "red" }}>*</Box>
                            </Box>
                          </FormLabel>
                          <DialogStyledInputWrapper>
                          <TextField
                          {...getFieldProps("linkToMeet")}
                            name="linkToMeet"
                            variant="outlined"
                            type="textarea"
                            value={values.linkToMeet}
                            style={webStyle.input}
                          />
                          </DialogStyledInputWrapper>
                        </FormControl>
                      </Grid>
                    )}
                    {this.handleInpersonBoth() && (
                      <>
                        <Grid container style={{ padding: "18px 37px 0px 0px"}} className="locationDropDown">
                          <Grid
                            item
                            xl={4} lg={4} md={6} sm={6} xs={12}
                           style={webStyle.fomrContainerDrop}
                           className="formContainerEvent"
                          >
                            <FormControl fullWidth>
                              <FormLabel style={webStyle.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  Country{" "}
                                  <Box style={{ color: "red" }}>*</Box>
                                </Box>
                              </FormLabel>
                              <StyledWrapperAutocomplete>
                                <Autocomplete
                                  disableClearable
                                  style={webStyle.autoComplateStyle}
                                  id="CountryData"
                                  value={values.country}
                                  ListboxProps={ListProps}
                                  data-testId="CountryData"
                                  options={this.getCountry()?.map(
                                    (options) => options.value
                                  )}
                                  getOptionLabel={(option: unknown) =>
                                    (option as CountryInfo).name
                                  }
                                  popupIcon={<KeyboardArrowDownIcon style={{color:"black"}} />}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                    />
                                  )}
                                  onChange={(_event, newValue) => {
                                    setFieldValue(
                                      "country",
                                      newValue === null ? "" : newValue
                                    );
                                    setFieldValue(
                                      "county",
                                      DefaultCounty
                                    );
                                    setFieldValue("town", DefaultTown);
                                  }}
                                />
                              </StyledWrapperAutocomplete>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={4} lg={4} md={6} sm={6} xs={12}
                            style={webStyle.fomrContainerDrop}
                            className="formContainerEvent"
                          >
                            <FormControl fullWidth>
                              <FormLabel style={this.getStateArray(
                                    values.country.isoCode
                                  )?.map((options) => options.value).length==0?webStyle.formLableTextDisabled:webStyle.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  Region{" "}
                                  {this.getStateArray(
                                    values.country.isoCode
                                  )?.map((options) => options.value).length==0?"":<Box style={{ color: "red" }}>*</Box>}
                                </Box>
                              </FormLabel>
                              <StyledWrapperAutocomplete>
                                <Autocomplete
                                  disableClearable
                                  ListboxProps={ListProps}
                                  placeholder=""
                                  style={webStyle.autoComplateStyle}
                                  value={values.county}
                                  disabled={this.getStateArray(
                                    values.country.isoCode
                                  )?.map((options) => options.value).length==0}
                                  id="combo-box-demo"
                                  data-testId="RegionData"
                                  options={this.getStateArray(
                                    values.country.isoCode
                                  )?.map((options) => options.value)}
                                  getOptionLabel={(option: unknown) =>
                                    (option as CountryInfo).name
                                  }
                                  popupIcon={<KeyboardArrowDownIcon style={{color:"black"}}/>}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                    />
                                  )}
                                  onChange={(_event, newValue) => {
                                    setFieldValue("county", newValue);
                                    setFieldValue("town", DefaultTown);
                                  }}
                                />
                              </StyledWrapperAutocomplete>
                            </FormControl>
                          </Grid>
                          <Grid
                            item
                            xl={4} lg={4} md={6} sm={6} xs={12}
                            style={webStyle.fomrContainerDropTown}
                            className="formContainerEvent"
                          >
                            <FormControl fullWidth>
                              <FormLabel style={this.getCityArray(
                                    values.country.isoCode,
                                    values.county.isoCode
                                  )?.map((options) => options.value).length==0?webStyle.formLableTextDisabled:webStyle.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  Town{" "}
                                  {this.getCityArray(
                                    values.country.isoCode,
                                    values.county.isoCode
                                  )?.map((options) => options.value).length==0? "": <Box style={{ color: "red" }}>*</Box>}
                                </Box>
                              </FormLabel>
                              <StyledWrapperTownAutocomplete>
                                <Autocomplete
                                  disableClearable
                                  style={webStyle.autoComplateStyle}
                                  ListboxProps={ListProps}
                                  data-testId="TownData"
                                  id="combo-box-demo"
                                  popupIcon={<KeyboardArrowDownIcon style={{color:"black"}}/>}
                                  getOptionLabel={(option: unknown) =>
                                    (option as TownInfo).name
                                  }
                                  value={values.town}
                                  disabled={this.getCityArray(
                                    values.country.isoCode,
                                    values.county.isoCode
                                  )?.map((options) => options.value).length==0}
                                  options={this.getCityArray(
                                    values.country.isoCode,
                                    values.county.isoCode
                                  )?.map((options) => options.value)}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                    />
                                  )}
                                  onChange={(_event, newValue) => {
                                    setFieldValue(
                                      "town",
                                      newValue === null ? "" : newValue
                                    );
                                  }}
                                />
                              </StyledWrapperTownAutocomplete>
                            </FormControl>
                          </Grid>
                        </Grid>
                        <Grid container>
                          <Grid
                            item
                            xl={8}
                            lg={8}
                            md={6}
                            sm={12}
                            style={webStyle.formContainer}
                            className="formContainerEvent"
                          >
                            <FormControl fullWidth>
                              <FormLabel style={webStyle.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  Address{" "}
                                  <Box style={{ color: "red" }}>*</Box>
                                </Box>
                              </FormLabel>
                              <TextField
                              {...getFieldProps("address")}
                                type="textarea"
                                name="address"
                                variant="outlined"
                                value={values.address}
                                style={webStyle.input}
                              />
                            </FormControl>
                          </Grid>
                          <PostCodeContainer
                            item
                            xl={4}
                            sm={12}
                            lg={4}
                            md={6}
                            style={webStyle.postcodeField}
                            className="formContainerEvent"
                          >
                            <FormControl fullWidth>
                              <FormLabel style={webStyle.formLableText}>
                                <Box style={{ display: "flex" }}>
                                  Post code
                                  <Box style={{ color: "red" }}>*</Box>
                                </Box>
                              </FormLabel>
                             
                              <TextField
                              {...getFieldProps("postcode")}
                                name="postcode"
                                type="textarea"
                                variant="outlined"
                                value={values.postcode}
                                style={webStyle.input}
                              />
                            </FormControl>
                          </PostCodeContainer>
                        </Grid>
                      </>
                    )}
                    <Grid style={webStyle.btnAction} className="btnAction">
                      <Grid
                        item
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          width: "98%",
                        }}
                      >
                       {this.submitButton(values,errors,touched)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </StyledFormWrapper>
            );
          }}
        </Formik>
      </DialogContent>
    </StyledDialogCreateWrapper>
    )
  }

  filterPart = () => {
   return(
    <Grid
    container
    style={{ flexBasis: "0px"}}
    onClick={() => this.handleStatesCovs("filterss")}
    data-test-id="filterPart"
  >
    <Grid container>
    <StyledAdvanceSearchWrapper>
     <Grid item xl={6} md={6} sm={12} style={{display:"flex",flexWrap:"wrap",justifyContent:"space-between"}}>
      <Grid className="datePickerDiv">
        <StyledWrapper>
         <StyledAdSearchDate>
          <DatePicker
            id="date-picker-one"
            dateFormat={"DD/MM/YYYY"}
            onChange={this.handleDateChange}
            value={this.state.fromDate?dayjs(this.state.fromDate).format("DD/MM/YYYY"):""}  
            customInput={
              <CustomInput
                placeHolder={"From date"}
                isEndIcon
              />
            }
          />
          </StyledAdSearchDate>
        </StyledWrapper>
      </Grid>
      <Grid item className="datePickerDiv">
        <StyledWrapper>
          <StyledAdSearchDate>
            <DatePicker
              disabled={this.state.fromDate==null}
              id="date-picker-two"
              dateFormat={"DD/MM/YYYY"}
              minDate={this.state.fromDate}
              value={this.state.toDate ? dayjs(this.state.toDate).format("DD/MM/YYYY") : ""}
              onChange={this.handleDateChangeTo}
              customInput={
                <CustomInput
                  placeHolder={"To date"}
                  isEndIcon
                />
              }
            />
          </StyledAdSearchDate>
        </StyledWrapper>
      </Grid>
      <Grid item className="datePickerDiv">
        <StyledDropDown>
          <StyledAdSearchDrop>
          <FormControl data-test-id="adSearchSelectForm">
            <Select
              id="adSearchSelect"
              value={this.state.location}
              onChange={this.handleSelect}
              IconComponent={KeyboardArrowDownIcon}
              variant="outlined"
              displayEmpty  
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null
              }}
              style={{fontFamily:"Silika-Light !important"}}
            >
              <CustomMenuItem style={{display:"none",fontFamily:"Silika-Light !important"}} value="" disabled>
                Location
              </CustomMenuItem>
              <CustomMenuItem value={"in person"}>
                In person
              </CustomMenuItem>
              <CustomMenuItem value={"online"}>
                Online
              </CustomMenuItem>
            </Select>
          </FormControl>
          </StyledAdSearchDrop>
        </StyledDropDown>
      </Grid>
    </Grid> 
    { this.state.location==="in person" &&
     <Grid item xl={6} md={6} sm={12} className="addressDiv"> 
      <Grid item  className="datePickerDiv">
        <FormControl data-test-id="adSearchCountryDrop" fullWidth>
         <StyledWrapperAdSearchAutocomplete>
            <Autocomplete
              disableClearable
              style={webStyle.autoAdSearchCompleteStyle}
              id="CountryDataAd"
              data-testId="CountryData"
              value={this.state.adSearchCountry}
              options={this.getCountry()?.map(
                (options:{value:ICountry}) => options.value
              )}
              getOptionLabel={(option: unknown) =>
                (option as CountryInfo).name
              }
              ListboxProps={ListProps}
              popupIcon={<KeyboardArrowDownIcon style={{color:"black"}} />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              onChange={(_event, newValue) => {
                 this.handleAdSearchCountry(
                   newValue === null ? DefaultAdSearchCountry : newValue as CountryInfo
                 );
              }}
            />
          </StyledWrapperAdSearchAutocomplete> 
        </FormControl>
      </Grid>
      <Grid item className="datePickerDiv">
        <FormControl  data-test-id="adSearchCountyDrop" fullWidth>
          <StyledWrapperAdSearchAutocomplete>
            <Autocomplete
              disableClearable
              fullWidth
              style={webStyle.autoAdSearchCompleteStyle}
              value={this.state.adSearchCounty}
              placeholder=""
              id="combo-box-demo"
              data-testId="RegionData"
              options={this.getStateArray(
                this.state.adSearchCountry.isoCode
              )?.map((options) => options.value)}
              getOptionLabel={(option: unknown) =>
                (option as CityInfo).name
              }
              ListboxProps={ListProps}
              popupIcon={<KeyboardArrowDownIcon style={{color:"black"}} />}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              onChange={(_event, newValue) => {
                this.handleAdSearchCounty(newValue === null ? DefaultAdSearchCounty : newValue as CityInfo)
              }}
            />
          </StyledWrapperAdSearchAutocomplete>
        </FormControl>
      </Grid>
      <Grid item className="datePickerDiv">
        <FormControl fullWidth data-test-id="adSearchTownDrop">
        <StyledWrapperAdSearchAutocomplete>
            <Autocomplete
              disableClearable
              style={webStyle.autoAdSearchCompleteStyle}
              id="combo-box-demo"
              data-testId="TownData"
              popupIcon={<KeyboardArrowDownIcon style={{color:"black"}} />}
              getOptionLabel={(option: unknown) =>
                (option as TownInfo).name
              }
              ListboxProps={ListProps}
              value={this.state.adSearchTown}
              options={this.getCityArray(
                this.state.adSearchCountry.isoCode,
                this.state.adSearchCounty.isoCode
              )?.map((options) => options.value)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              onChange={(_event, newValue) => {
               this.handleAdSearchTown(
                newValue === null ? DefaultAdSearchTown : newValue as TownInfo
                );
              }}
            />
           </StyledWrapperAdSearchAutocomplete>
        </FormControl>
      </Grid>
     
    </Grid> }
    </StyledAdvanceSearchWrapper> 
    </Grid>  
  </Grid>
   )
  }

  breadCrumbsStack = () =>{ 
     return (
<Box style={webStyle.breadCrumbsStack}>
          <Box className="filterListSelect">
            {this.state.fromDate && (
              <StyledChipBtn
                size="small"
                id="remove-fromDate"
                variant="outlined"
                endIcon={<img src={cancel} className="closeIconBreadCumb"></img>}
                onClick={() => {
                  this.removeBreadCrumbs("fromDate");
                }}
              >
                From {moment(this.state.fromDate).format("YYYY-MM-DD")}
              </StyledChipBtn>
            )}
            {this.state.toDate && (
              <StyledChipBtn
                style={webStyle.chipCards}
                id="remove-toDate"
                size="small"
                variant="outlined"
                endIcon={<img src={cancel} className="closeIconBreadCumb"></img>}
                onClick={() => {
                  this.removeBreadCrumbs("toDate");
                }}
              >
                To {moment(this.state.toDate).format("YYYY-MM-DD")}
              </StyledChipBtn>
            )}
            {this.state.location !== "" && (
              <StyledChipBtn
                id="location-id"
                style={webStyle.chipCards}
                size="small"
                variant="outlined"
                endIcon={<img src={cancel} className="closeIconBreadCumb"></img>}
                onClick={() => {
                  this.removeBreadCrumbs("location");
                }}
              >
                {this.state.location}
              </StyledChipBtn>
            )}
             {this.state.adSearchCountry.name !== "Country" && (
              <StyledChipBtn
                id="countryBread"
                style={webStyle.chipCards}
                size="small"
                variant="outlined"
                endIcon={<img src={cancel} className="closeIconBreadCumb"></img>}
                onClick={() => {
                  this.removeBreadCrumbs("country");
                }}
              >
                {this.state.adSearchCountry.name}
              </StyledChipBtn>
            )}
             {this.state.adSearchCounty.name !== "Region" && (
              <StyledChipBtn
                id="countyBread"
                style={webStyle.chipCards}
                size="small"
                variant="outlined"
                endIcon={<img src={cancel} className="closeIconBreadCumb"></img>}
                onClick={() => {
                  this.removeBreadCrumbs("county");
                }}
              >
                {this.state.adSearchCounty.name}
              </StyledChipBtn>
            )}
            {this.state.adSearchTown.name !== "Town" && (
              <StyledChipBtn
                id="townBread"
                style={webStyle.chipCards}
                size="small"
                variant="outlined"
                endIcon={<img src={cancel} className="closeIconBreadCumb"></img>}
                onClick={() => {
                  this.removeBreadCrumbs("town");
                }}
              >
                {this.state.adSearchTown.name}
              </StyledChipBtn>
            )}
          </Box>
          {this.state.location || this.state.toDate || this.state.fromDate ? (
            <Typography
              id="clear-all"
              style={webStyle.clearAllText}
              onClick={() => this.removeBreadCrumbs("all")}
            >
              Clear all filters
            </Typography>
          ) : (
            ""
          )}
        </Box>
     )
  }

  buttonGroupOfTab = () =>{
    return(
      <ButtonGroup style={webStyle.worlInfoDiv}>
      <Button
        data-test-id="InfoDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChanges(true);
        }}
        style={this.state.activeTab ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={this.state.activeTab ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
          {configJSON.AllTitle}
        </Typography>
      </Button>
      <Button
        data-test-id="workDiv"
        type="button"
        className="tabBtn"
        onClick={() => {
          this.handleTabChanges(false);
        }}
        style={!this.state.activeTab ? webStyle.infoDiv : webStyle.workDiv}
      >
        <Typography
          style={!this.state.activeTab ? webStyle.info : webStyle.info1}
          className="tabTitle"
        >
        {configJSON.MyEvent}
        </Typography>
      </Button>
    </ButtonGroup>
    )
  }

  loadMoreBtn =()=>{
   return(
    this.state.paginatedListEvent.length > 0 && <Grid container style={webStyle.loadingContainer}>
    <Grid container style={webStyle.loadingDiv}>
      <Grid item style={{justifyContent:"center",display:"flex"}}>
        {this.state.isRemainingEventLoading && 
        <Button style={webStyle.loadMoreBtn} onClick={this.handleLoadMore} className="loadMoreBtn">
        {this.state.isLoadingMore?<>Loading<CircularProgress style={webStyle.loadSpinner}/></>:"Load more"} </Button> }   
      </Grid>
      <CustomBackTop>
      <Button style={webStyle.backToTop} id="backToTop" onClick={this.handleScrollUp}><KeyboardArrowUpIcon/></Button>
      </CustomBackTop>
    </Grid>
  </Grid> 
   )
  }

  attendingEventList = () => {
    return (
      <Grid container className="cardContainer" item data-test-id="attendingEventList" onClick={() => this.handleStatesCovs("filterss")}>
        {this.state.listAllEvent.filter((event) => event.attributes.attendees.data.find((attendee) => attendee?.attributes?.email ===
          sessionStorage.getItem('accountEmail'))).map((attendingEvent: Event) => {
            return <Grid item className="cardOfEvent" key={attendingEvent.id}>
              <div style={{ position: 'relative', cursor: "pointer" }}>
                <Card>
                  <CardMedia
                    style={{ cursor: "pointer",aspectRatio:"16/11" }}
                    image={attendingEvent.attributes.banner_image ? attendingEvent.attributes.banner_image : BannerDefault}
                    data-test-id="attendEventCard"
                    onClick={() => { this.navigateToPage(attendingEvent.id) }}
                  />
                  <Chip
                    label="Attending"
                    style={webStyle.cardChips}
                  />
                  <CardContent style={{padding:"20px"}}>
                    <Typography gutterBottom variant="h5" component="div" style={webStyle.eventName}>
                      {attendingEvent.attributes.event_name}
                    </Typography>
                    <Typography variant="body2" style={webStyle.eventDate}>
                      {dayjs(attendingEvent.attributes.start_date).format('ddd, D MMM')}, {attendingEvent.attributes.start_time != null && <>{dayjs(attendingEvent.attributes.start_time).utc().format('h:mm a')}</>} ({attendingEvent.attributes.time_zone
                      })
                    </Typography>
                    <Box style={{ display: "flex", alignItems: "center", height: "30px", flexWrap: "wrap" }}>
                      {attendingEvent.attributes.is_in_person.toLowerCase() === "online"
                        &&
                        <Typography variant="body2" style={{ width: "100%" }}>Online</Typography>
                      }
                      {attendingEvent.attributes.country && attendingEvent.attributes.county &&
                        <Box style={{ marginTop: "10x", display: "flex", alignItems: "center" }}>
                          <img src={location}></img>
                          <Typography variant="body2">
                          {attendingEvent.attributes.county
                         ? `${attendingEvent.attributes.county}, ${attendingEvent.attributes.country}` 
                          : attendingEvent.attributes.country}
                          </Typography>
                        </Box>}
                    </Box>
                  </CardContent>
                  <CardActions style={{ justifyContent: 'space-between' ,paddingTop:"0px",paddingRight:"20px",paddingLeft:"20px",paddingBottom:"8px"}}>
                    <Box>
                      <img src={share} onClick={() => { this.openPopUp(attendingEvent.id) }}></img>
                      {(this.state.favEventList.find((list) => list.attributes.favouriteable.data.id == attendingEvent.id) ?
                        <TurnedInIcon /> :
                        <img src={save} style={{ paddingLeft: "10px" }} alt="Save" onClick={() => { this.handleAddFavouriteEvent(attendingEvent.id) }}></img>
                      )
                      }
                    </Box>
                    <Box sx={webStyle.avatarStyle}>
                      <AvatarGroupStyled max={4} style={{ height: "28px" }}>
                        {attendingEvent.attributes.attendees.data.map((data: IAttendee) => {
                          return <Avatar alt="Travis Howard" src={data.attributes.photo} style={webStyle.avatarGroup} key={data.attributes.email} />
                        })}
                      </AvatarGroupStyled>
                    </Box>
                  </CardActions>
                  <Typography style={webStyle.network}>{attendingEvent.attributes.attendees.data.length} of your network</Typography>
                </Card>
              </div>
            </Grid>
          })}
      </Grid>
    )
  }

  favEventList = () => {
    return (
      <Grid container className="cardContainer" data-test-id="favEventDiv" onClick={() => this.handleStatesCovs("filterss")}>
        {this.state.favEventList.length > 0 && this.state.favEventList.map((favEvent) =>
        (<>
          <Grid item className="cardOfEvent" data-test-id="cardsDiv" key={favEvent.id}>
            <div style={{ position: "relative", cursor: "pointer" }}>
              <Card key={favEvent.id}>
                <CardMedia
                  style={{ cursor: "pointer",aspectRatio:"16/11" }}
                  image={
                    favEvent.attributes.favouriteable.data.attributes
                      .banner_image
                      ? favEvent.attributes.favouriteable.data.attributes
                        .banner_image
                      : BannerDefault
                  }
                  data-test-id="favEventCard"
                  onClick={() => { this.navigateToPage(favEvent.attributes?.favouriteable?.data?.id) }}
                />
                <CardContent style={{padding:"20px"}}>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    style={webStyle.eventName}
                  >
                    {
                      favEvent.attributes.favouriteable.data.attributes
                        .event_name
                    }
                  </Typography>
                  <Typography variant="body2" style={webStyle.eventDate}>
                    {dayjs(favEvent.attributes.favouriteable.data.attributes.start_date).format(
                      "ddd, D MMM"
                    )}
                    ,{" "}
                    {favEvent.attributes.favouriteable.data.attributes.start_time != null && (
                      <>
                        {dayjs(favEvent.attributes.favouriteable.data.attributes.start_time).utc().format(
                          "h:mm a"
                        )}
                      </>
                    )}{" "}
                    ({favEvent.attributes.favouriteable.data.attributes.time_zone})
                  </Typography>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "40px",
                      flexWrap: "wrap",
                    }}
                  >
                    {favEvent.attributes.favouriteable.data.attributes.is_in_person.toLowerCase() ===
                      "online" && (
                        <Typography variant="body2" style={{ width: "100%" }}>
                          Online
                        </Typography>
                      )}
                    {favEvent.attributes.favouriteable.data.attributes.country &&
                      favEvent.attributes.favouriteable.data.attributes.county && (
                        <Box
                          style={{
                            marginTop: "10x",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <img src={location}></img>
                          <Typography variant="body2">
                          {favEvent.attributes.favouriteable.data.attributes.county!==" "
                         ? `${favEvent.attributes.favouriteable.data.attributes.county}, ${favEvent.attributes.favouriteable.data.attributes.country}` 
                          : favEvent.attributes.favouriteable.data.attributes.country}
                          </Typography>
                        </Box>
                      )}
                  </Box>
                </CardContent>
                <CardActions style={{ justifyContent: "space-between",paddingTop:"0px",paddingRight:"20px",paddingLeft:"20px",paddingBottom:"8px" }}>
                  <Box>
                    <img src={share} onClick={() => { this.openPopUp(favEvent.attributes.favouriteable.data.id) }}></img>
                    <TurnedInIcon onClick={() => { this.handleDeleteFavouriteApiCall(favEvent.id) }} />
                  </Box>
                  <Box sx={webStyle.avatarStyle}>
                    <AvatarGroupStyled max={4} style={{ height: "28px" }}>
                      {favEvent.attributes.favouriteable.data.attributes.attendees.data.map(
                        (data: IAttendee) => {
                          return (
                            <Avatar
                              key={data.attributes.email}
                              alt="Travis Howard"
                              src={data.attributes.photo}
                              style={webStyle.avatarGroup}
                            />
                          );
                        }
                      )}
                    </AvatarGroupStyled>
                  </Box>
                </CardActions>
                <Typography style={webStyle.network}>
                  {favEvent.attributes.favouriteable.data.attributes.attendees.data.length} of
                  your network
                </Typography>
              </Card>
            </div>
          </Grid>
        </>
        )
        )}
      </Grid>
    )
  }

  pastEventList = () => {
    return (
      <Grid container className="cardContainer" onClick={() => this.handleStatesCovs("filterss")} data-test-id="pastEventList">
        {this.state.listAllEvent.filter((event) => event.attributes.attendees.data.find((attendee) => attendee.attributes.email ===
          sessionStorage.getItem('accountEmail') && dayjs(event.attributes.end_date).isBefore(dayjs().startOf('day')))).map((pastEvent: Event) => {
            return <Grid item className="cardOfEvent" key={pastEvent.id} data-test-id="pastEventGrid">
              <div style={{ position: 'relative', cursor: "pointer" }}>
                <Card>
                  <CardMedia
                    style={{ cursor: "pointer" ,aspectRatio:"16/11"}}
                    image={pastEvent.attributes.banner_image ? pastEvent.attributes.banner_image : BannerDefault}
                    onClick={() => { this.navigateToPage(pastEvent.id) }}
                  />
                  <CardContent style={{padding:"20px"}}>
                    <Typography gutterBottom variant="h5" component="div" style={webStyle.eventName}>
                      {pastEvent.attributes.event_name}
                    </Typography>
                    <Typography variant="body2" style={webStyle.eventDate}>
                      {dayjs(pastEvent.attributes.start_date).format('ddd, D MMM')}, {pastEvent.attributes.start_time != null && <>{dayjs(pastEvent.attributes.start_time).utc().format('h:mm a')}</>} ({pastEvent.attributes.time_zone})
                    </Typography>
                    <Box style={{ display: "flex", alignItems: "center", height: "40px", flexWrap: "wrap" }}>
                      {pastEvent.attributes.is_in_person.toLowerCase() === "online"
                        &&
                        <Typography variant="body2" style={{ width: "100%" }}>Online</Typography>
                      }
                      {pastEvent.attributes.country && pastEvent.attributes.county &&
                        <Box style={{ marginTop: "10x", display: "flex", alignItems: "center" }}>
                          <img src={location}></img>
                          <Typography variant="body2">
                          {pastEvent.attributes.county!==" "
                         ? `${pastEvent.attributes.county}, ${pastEvent.attributes.country}` 
                          : pastEvent.attributes.country}
                          </Typography>
                        </Box>}
                    </Box>
                  </CardContent>
                  <CardActions style={{ justifyContent: 'space-between',paddingTop:"0px",paddingRight:"20px",paddingLeft:"20px",paddingBottom:"8px" }}>
                    <Box>
                      <img src={share} data-test-id="pastEventShareEventBtn" onClick={() => { this.openPopUp(pastEvent.id) }}></img>
                      {(this.state.favEventList.find((list) => list.attributes.favouriteable.data.id == pastEvent.id) ?
                        <TurnedInIcon /> :
                        <img src={save} alt="Save" onClick={() => { this.handleAddFavouriteEvent(pastEvent.id) }}></img>
                      )
                      }
                    </Box>
                    <Box sx={webStyle.avatarStyle}>
                      <AvatarGroupStyled max={4} style={{ height: "28px" }}>
                        {pastEvent.attributes.attendees.data.map((data: IAttendee) => {
                          return <Avatar alt="Travis Howard" key={data.attributes.email} src={data.attributes.photo} style={webStyle.avatarGroup} />
                        })}
                      </AvatarGroupStyled>
                    </Box>
                  </CardActions>
                  <Typography style={webStyle.network}>{pastEvent.attributes.attendees.data.length} of your network</Typography>
                </Card>
              </div>
            </Grid>
          })}
      </Grid>
    )
  }

  eventTitleWithCount = () => {
    return (
      <Typography onClick={() => this.handleStatesCovs("filterss")} data-test-id="filterss" style={webStyle.headerText} className="mainTitle">
        Events {Boolean(this.state.adSearchText || this.state.fromDate || this.state.toDate || this.state.location) && `(${this.state?.listAllEvent?.length})`}
      </Typography>
    )
  }

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <> 
      <HeaderNavbar navigation={this.props.navigation}/>
      <StyleContainer maxWidth="xl">
      <StyledGlobWrapper style={{ ...webStyle.safeAreaView}}>
         {this.eventTitleWithCount()}
        <StyledInput>
          <TextField
             id="adSearchText"
             type="text"
             variant="outlined"
             value={this.state.adSearchText}
             placeholder="Event name, Organizer."
             onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAdSearchText(event.currentTarget.value)}
             style={webStyle.input}
             InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={searchIcon} />
                </InputAdornment>
              ),
            }}
          />
        </StyledInput>
        <Typography style={webStyle.titleText} onClick={()=>this.handleStatesCovs("filter")} data-test-id="filter">Filters:</Typography>
         {this.filterPart()}
         {this.state.location || this.state.toDate || this.state.fromDate ? <Divider style={{marginTop:"51px"}} />:<></>}
         {this.breadCrumbsStack()}
        <StyledTabWrapper>
         {this.buttonGroupOfTab()}
        <Button
          data-test-id="createEventBtn"
          variant="contained"
          onClick={this.handleCreateEventPopUp}
          style={webStyle.createBtn}
          className="createBtnEvent"
        >
          <Typography style={webStyle.createBtnText} className="textBtnCreate">
          {configJSON.CreateAnEvent}
          </Typography>
        </Button>
        {this.state.activeTab && <Grid container  className="cardContainer" data-test-id="cardEventDiv" style={{margin:0}}>
        { this.state.paginatedListEvent.map((event: Event) => {
            return <Grid item data-test-id="cardEventList" className="cardOfEvent " key={event.id} style={{ padding: 0 }}>
              <Card style={{ padding: 0 }}>
              <div style={{ position: 'relative',cursor:"pointer"  }}>
                <CardMedia
                  style={{ aspectRatio:"16/11"}}
                  image={event.attributes.banner_image?event.attributes.banner_image:BannerDefault}
                  data-test-id="pastEventCard"
                  onClick={()=>{this.navigateToPage(event.id)}}
                />
                 {event.attributes.attendees.data.find((attendee) => attendee.attributes.email===
                   sessionStorage.getItem('accountEmail')) && 
                    <Chip
                     label="Attending"
                      style={webStyle.cardChips}
                 />
                 }
                {sessionStorage.getItem("accountEmail")===event.attributes.account.data.attributes.email &&
                <Chip
                  label="Owner"
                  style={webStyle.cardChips}
                />}         
                <CardContent style={{padding:"20px"}}>
                  <Typography gutterBottom variant="h5" component="div" style={webStyle.eventName}>
                    {event.attributes.event_name}
                  </Typography>
                  <Typography variant="body2" style={webStyle.eventDate}>
                   {dayjs(event.attributes.start_date).format('ddd, D MMM')}, {event.attributes.start_time!=null && <>{dayjs(event.attributes.start_time).utc().format('h:mm a')}</>} ({event.attributes.time_zone})
                  </Typography>
                  <Box style={{ display: "flex", alignItems: "center",height:"40px",flexWrap:"wrap"}}>
                  {event.attributes.is_in_person.toLowerCase()==="online"
                      &&
                      <Typography style={webStyle.eventLocation} variant="body2">Online</Typography>
                  }
                  {event.attributes.country && event.attributes.county &&
                  <Box style={{marginTop:"10x",display:"flex",alignItems:"center"}}>
                    <img src={location}></img>
                    <Typography variant="body2" style={webStyle.eventLocation}>
                      {console.log(event.attributes.county)}
                    {event.attributes.county!==" "
                         ? `${event.attributes.county}, ${event.attributes.country}` 
                          : event.attributes.country}
                    </Typography>
                  </Box>}
                  </Box>
                </CardContent>
                <CardActions style={{ justifyContent: 'space-between',paddingTop:"0px",paddingRight:"20px",paddingLeft:"20px",paddingBottom:"8px" }}>
                  <Box>
                    <img src={share}  onClick={()=>{this.openPopUp(event.id)}} data-test-id="shareEvent"></img>
                        {(this.state.favEventList.find((list) => list.attributes.favouriteable.data.id == event.id)?
                          <TurnedInIcon/>:
                          <img src={save} style={{paddingLeft:"10px"}} alt="Save" data-test-id="saveEvent" onClick={()=>{this.handleAddFavouriteEvent(event.id)}}></img>
                        )
                        }
                  </Box>
                  <Box sx={webStyle.avatarStyle}>
                    <AvatarGroupStyled style={{height:"27px"}} max={4}>
                     {event.attributes.attendees.data.map((data:IAttendee)=>{
                      return <Avatar alt="Travis Howard" src={data.attributes.photo} style={webStyle.avatarGroup} key={data.attributes.email} />
                      })}
                    </AvatarGroupStyled>      
                  </Box>
                </CardActions>
                <Typography style={webStyle.network}>{event.attributes.attendees.data.length} of your network</Typography>
                </div>
              </Card>
            </Grid>
          })}
          {this.loadMoreBtn()}
        </Grid>}
        {!this.state.activeTab && 
        <div>
          <StyledAccordian>
            <Divider/>
            <Accordion defaultExpanded className="accordianBorderBox">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography className="eventName" style={{width:"max-content"}} data-test-id="Attending" onClick={()=>this.handleDeleteFavouriteApiCall("Ab")}>Attending</Typography>
              </AccordionSummary>
              <AccordionDetails>
               {this.attendingEventList()}
              </AccordionDetails>
            </Accordion>
          </StyledAccordian>
          <StyledAccordian>
          <Divider/>
            <Accordion defaultExpanded className="accordianBorderBox">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon data-test-id="expandMoreFav"/>}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography className="eventName"  data-test-id="savedFavIcon" onClick={()=>this.handleDeleteFavouriteApiCall("Ab")}>Favourites</Typography>
              </AccordionSummary>
              <AccordionDetails>
               {this.favEventList()}
              </AccordionDetails>
            </Accordion>
          </StyledAccordian>
          <StyledAccordian>
          <Divider/>
            <Accordion defaultExpanded className="accordianBorderBox">
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography className="eventName">Past Events</Typography>
              </AccordionSummary>
              <AccordionDetails>
               {this.pastEventList()}
              </AccordionDetails>
            </Accordion>
          </StyledAccordian>
        </div>
        }
        {this.state.isOpenedCreateEventPopUp && (
         this.dialogCreateEvents()
        )}
        <ShareLinkDialog
            isOpen={this.state.openModel}
            onClickChatButton={this.handleChat}
            onClose={this.handleClose}
            isChatSend={this.state.msgSend}
            shareLink={`${this.state.linkShare}`}
          />
          <ShareMenuDialog
            isOpen={this.state.isSendLinkToChatModalOpen}
            onClose={this.handleCloseChatModels}
            onClickBackButton={this.handleNavigateBacks}
            profiles={this.state.getAllData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
              account_id:option?.attributes?.account_id,
              firstName: option?.attributes?.first_name,
              lastName: getSurname(option?.attributes?.last_name, option?.attributes?.account_id?.toString()),
              photo: option?.attributes?.photo,
            }))}
            shareData={this.state.shareData}
            chatMessage={this.state.sendChatMessage}
            onChangeChatMessage={this.handlesendChatMessages}
            onShareDataChange={this.handleshareDataChatChanges}
            onClickSendChat={this.handleSend}
          />
        </StyledTabWrapper>
      </StyledGlobWrapper>
      </StyleContainer>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  createEventBtn: {
    marginLeft: "20px",
    borderRadius: "2px",
    backgroundColor: "black",
    color: "white",
    marginTop: "20px",
    width: "15%",
    // float: "right",
    // textDecoration: "none", textTransform: "none"
  },
  clearAllText: {
    fontFamily: "Silika-Light",
    fontSize: "14px",
    fontWeight: 300,
    textDecoration: "underline",
    cursor: "pointer",
    textWrap: "nowrap" as "nowrap"
  },
  eventName: {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    fontWeight: 500,
    color:"#010101"
  },
  eventDate: {
    fontSize: "16px",
    fonWeight: 500,
    fontFamily: "Silika-Medium",
    color:"#010101"
  },
  eventLocation: {
    fontFamily: "Silika-Light",
    fontSize: "14px",
    fontWeight: "bolder" as "bolder",
    color:"#010101"
  },
  network: {
    fontFamily: "Silika-Light",
    fontSize: "12px",
    fontWeight: 300,
    justifyContent: "flex-end",
    display: "flex",
    paddingRight: "20px",
    paddingBottom:"20px",
    color:"#9B9B9D"
  },
  avatarStyle: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  avatarGroup: {
    width: 28,
    height: 28,
  },
  workDiv: {
    width: "50%",
    alignSelf: "center",
    border: "none",
    borderBottom: "2px solid #DFDFDF",
  },
  info: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    textTransform: "none" as "none",
  },
  info1: {
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    color: "#9B9B9D",
    textTransform: "none" as "none",
  },
  infoDiv: {
    width: "50%",
    alignItems: "center",
    border: "none",
    borderBottom: "2px solid #000",
  },
  worlInfoDiv: {
    // margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "67px"
  },
  breadCrumbsStack: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  searchTextFont: {
    fontFamily: "Silka",
    fontWeight: 500,
    color: "#010101",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: "#DFDFDF",
    marginLeft: 1,
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 22,
    borderRadius: "2px",
  },
  titleText: {
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 500,
    margin: "16px 0px",
    color: "#010101",
  },
  safeAreaView: {
    marginTop: "88px",
  },
  headerText: {
    fontFamily: "Silika-Medium",
    fontSize: "32px",
    fontWeight: 500,
    marginBottom: "50px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  createBtn: {
    background: "rgba(13, 12, 34, 1)",
    width: "162px",
    height: "47px",
    marginLeft: "auto",
    marginTop: "30px",
    marginBottom: "74px",
    padding: "16px",
    borderRadius:"2px",
    transition:"ease-in-out .3s"
  },
  createBtnText: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    letterSpacing: "-0.15000000596046448px",
    color: "rgba(255, 255, 255, 1)",
    textTransform: "none" as "none",
    fontSize: "16px",
  },
  dialogContainer: {
    top: "1px",
    borderRadius: "4px 0px 0px 0px",
    opacity: "0px",
    border: "1px solid #DFDFDF",
    margin: "auto",
  },
  dialogContent: {
    width: "100%",
    height: "100%",
  },
  headerDialog: {
    background: "#0D0C22",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    flexDirection: "row" as "row",
  },
  formContainer: {
    padding: "24px 37px 0px 40px",
    display: "flex",
    width: "100%",
  },
  fomrContainerDrop:{
    padding: "8px 12px 8px 40px",
    display: "flex",
    width: "100%",
    paddingRight:"0px"
  },
  fomrContainerDropTown:{
    padding: "8px 0px 8px 40px",
    display: "flex",
    width: "100%",
  },
  postcodeField:{
    padding: "24px 37px 0px 18px",
    display: "flex",
    width: "100%",
  },
  dateInputContainer: {
    display: "flex",
    width: "40%",
  },
  formLableText: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: "10px",
    color: "#010101",
    display: "flex",
    justifyContent: "space-between",
  },
  input: {
    width: "100%",
    heigth: "40px",
    alignItem: "center",
    border:"1px solid #DFDFDF",
  },
  calenderInputDiv: {
    display: "inline-flex",
    width: "100%",
    marginBottom: "2%",
  },
  calInDiv: {
    width: "70%",
    boxSizing: "content-box" as "content-box",
    display: "flex",
    justifyContent: "center",
  },
  calInput: {
    padding: "4px 8px 5px 8px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "2px",
  },
  datepicker: {
    height: "40px",
  },
  datepickercontainer: {
    position: "relative" as "relative",
    display: "inline-block",
  },
  sendBtn: {
    backgroundColor: "gray",
  },
  calendaricon: {
    position: "absolute",
    top: "50%",
    right: "10px",
    transform: "translateY(-50%)",
    cursor: "pointer",
  },
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silka",
    lineHeight: "10px",
    color: "white",
    alignItems: "center",
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "15px",
  },
  borderOfImage: {
    border: "1px dashed #9B9B9D",
    borderRadius: "4px",
    marginRight: "5px",
    position: "relative" as "relative",
    height:"132px",
    width:"132px"
  },
  crossImage: {
    position: "absolute" as "absolute",
    right: 1,
    cursor: "pointer",
    color: "black",
    height:"15px",
    width:"15px"
  },
  imageGridStyle: {
    display: "flex",
    width: "100%",
  },
  imageGridStyleText: {
    fontFamily: "Silka",
    fontSize: "16px",
    fontWeight: 500,
    color: "#010101",
  },
  imageDisplay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row" as "row",
  },
  createBtnEvent: {
    background: "#010101",
    height: "48px",
    width: "104px",
    color: "white",
    textTransform: "capitalize" as "capitalize",
    alignItem: "flex-end",
    marginTop:"30px",
    fontFamily:"Silika-Medium",
  },
  btnAction: {
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silka",
    lineHeight: "10px",
    color: "white",
    alignItems: "center",
    paddingRight: "35px",
  },
  dateInput: {
    height: "40px",
  },
  cardChips: {
    position: "absolute" as "absolute",
    top: 10,
    right: 10,
    backgroundColor: "#0D0C22",
    width: "103px",
    height: "32px",
    borderRadius: "100px",
    color: "#FFFFFF",
    fontFamily: "silika-Medium",
    fontSize: "14px",
    padding: "10px, 12px, 10px, 12px",
  },
  loadMoreBtn: {
    width: "360px",
    height: "48px",
    textTransform: "none" as "none",
    border: "1px solid black",
    padding: "16px",
    borderRadius: "2px",
    fontFamily:"silika-Medium"
  },
  autoComplateStyle: {
    marginTop: "8px",
    fontSize: "15px",
  },
  autoAdSearchCompleteStyle:{
    fontSize: "15px",
  },
  loadSpinner:{
    width:"20px",
    height:"20px",
    color:"black",
    marginLeft:"20px"
  },
  backToTop:{
    width:"48px",
    height:"48px",
    border:"1px solid #010101",
    borderRadius:"100px",
    position:"fixed" as "fixed",
    bottom: "50px",
    right: "5.3vw", 
    backgroundColor: "white",
  },
  loadingContainer:{
   alignItem:"center",
   height:"48px",
   marginBottom:"50px",
   display: "flex",
   justifyContent:"center"
  },
  loadingDiv:{
   height:"48px",
   alignContent: "center",
   display: "flex",
   justifyContent:"center",
  },
  chipCards:{
    marginLeft:"10px",
    textTransForm:"none !important"
  },
  modelHeadingstyle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  modelHeadingstyleChatModel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between"
  },
  modalStyle: {
    width: 450,
    height: 150,
    bgcolor: 'white',
    border: 'none',
    p: 4,
    zIndex: 10,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)"
  },
  headingText: {
    width: "100%",
    textAlign: "center",
    fontSize: '24px',
    fontWeight: 500,
    color: "#010101",
  },
  formLableTextDisabled:{
    fontFamily: "Silka",
    fontWeight: 500,
    fontSize: "14px",
    marginBottom: "10px",
    color: "gray",
    display: "flex",
    justifyContent: "space-between",
  },
  formLableTextCover:{
    fontSize: "14px",
    marginBottom: "10px",
    color: "gray",
    display: "flex",
    fontFamily: "Silka",
    fontWeight: 500,
    justifyContent: "space-between",
  }
};
// Customizable Area End
