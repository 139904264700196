import React from "react";

import {
    Grid,
    // Customizable Area Start
    Dialog,
    DialogContent,
    DialogTitle,
    TextField,
    Typography,
    Button,
    IconButton,
    DialogActions
    // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
import HeaderNavbar from "../../../components/src/Navbar";import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { closeImage } from "../src/assets";
import { ErrorMessage, Formik } from "formik";
import CommonDialog from "../../../components/src/CommonDialog.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#fff",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },

    },

});
// Customizable Area End

import SettingsProfileController, {
    Props,
    styles
} from "./SettingsProfileController";
import SettingHeading from "./components/settingIntro/SettingHeading";
import SettingDetails from "./components/settingDetails/SettingDetails"

export class SettingsProfile extends SettingsProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderSettingDetails = () => {
        return (
                <SettingDetails
                subscriptionPlans={this.state.subscriptionPlans ? this.state.subscriptionPlans : []}
                subscriptionFeatureCategories={this.state.subscriptionFeatureCategory ? this.state.subscriptionFeatureCategory : []}
                subscriptionFeatures={this.state.subscriptionFeatures ? this.state.subscriptionFeatures : []}
                onClickEditEmailPasswordShow={this.handleEditEmailPasswordShow}
                showEmailPassword={this.state.showEmailPassword}
                onForgotPasswordClick={this.handleForgotPasswordModal}
                isAccountDeleted={this.state.isAccountDeleted}
                onClickNavigateToLogin={this.handleNavigateToLogin}
                isConfirmDeleteModalOpen={this.state.isConfirmDeleteModalOpen}
                projectWorking={this.state.projectWorking}
                profile={this.state.profile}
                termsAndConditions={this.state.termsAndConditions} 
                onVisibilityChange={this.handleVisibilityChange}
                onUpdateProjectWorking={this.saveProjectWorking}
                onEditProjectWorking={this.handleEditProjectWorking}
                visibilitySetting={this.state.visibilitySetting} 
                isEditProjectWorking={this.state.isEditProjectWorking}
                handlePasswordCloseModal={this.handlePasswordCloseModal}
                handlePasswordShowModal={this.handlePasswordShowModal}
                notificationSettings={this.state.notificationSetting}
                data-test-id="settingDetails"
                onNotificationSettingChange={this.handleNotificationSettingChange}  
                openDialouge={this.openDialouge} 
                closeDialouge={this.closeDialouge} 
                closeCardSaved={this.closeCardSaved} 
                closeSubscription={this.closeSubscription} 
                onClickOpenSubscription={this.openSubscription} 
                setOpenEdit={this.setOpenEdit} 
                selectedSetting={this.state.selectedSetting}
                displaySubscription={this.state.displaySubscription}
                dialouge={this.state.dialouge}
                cardSaved={this.state.cardSaved}
                windowWidth={this.state.windowWidth}
                onBackButtonClick={this.backButtonHandler}
                onAgreeButtonClick={this.saveAgreeButtonHandler}
                isAgreeButtonEnabled={this.state.isAgreeButtonEnabled}
                onDeleteAccount={this.handleDeleteAccount}
                onClickDeleteAccount={this.handleDeleteAccountModal}
                isEmailUpdated={this.state.isEmailUpdated}
                isEditEmailModalOpen={this.state.isEditEmailModalOpen}
                onClickUpdateEmail={this.handleEditEmailModal}
                onUpdateEmail={this.handleEditEmail}
                onCloseDialog={this.handleCloseDialog}
                viewAllFeaturesOpen={this.state.viewAllFeaturesOpen}
                onClickFeaturesOpen={this.handleFeaturesOpen}
                onClickBackToTop={this.handleBackToTop}
                />
        )
    }

    renderWebContainer = () => {
        return (
            <StyledSettingProfileWeb data-test-id="settingProfileWeb">
            <div className="Heading">
                <SettingHeading windowWidth={this.state.windowWidth} data-test-id="settingHeading" selectedSetting={this.state.selectedSetting} onTabChange={this.setSettigValue} />
            </div>
            <div className="Details">
                {this.renderSettingDetails()}
            </div>
        </StyledSettingProfileWeb>
        )
    }

    renderMobileContainer = () => {
        const {selectedSetting} = this.state;
        return (<div>
            {selectedSetting === null && <SettingHeading windowWidth={this.state.windowWidth} data-test-id="settingHeading" selectedSetting={this.state.selectedSetting} onTabChange={this.setSettigValue} />}
            {this.renderSettingDetails()}
        </div>)
    }

    renderContainer = () => {
        const {windowWidth} = this.state;
        const isMobile = windowWidth <= 599;
        if(isMobile){
            return this.renderMobileContainer();
        }
        return this.renderWebContainer();
    }
    // Customizable Area End

    render() {
        const { classes } = this.props;
        return (
            // Customizable Area Start
            <ThemeProvider theme={theme}>
                <HeaderNavbar navigation={this.props.navigation} />
                <StyledDiv className={classes.leftPadding}></StyledDiv>
                {this.renderContainer()}
                <ModelStyle
                maxWidth="md"
                open={this.state.changePasssword}
                BackdropProps={{
                    className: "backdrop-class"
                }}
                aria-describedby="alert-dialog-slide-description"
                fullWidth={true}
                data-test-id="reset-password-dialog"
            >
                    <div style={webStyle.dialougeHeading}>
                        <Typography style={webStyle.title}>Reset Password</Typography>
                        <img data-test-id="handlePasswordCloseModal" onClick={this.handlePasswordCloseModal} style={webStyle.closeIconStyle} src={closeImage}></img>
                    </div>
                <DialogContent style={webStyle.positionStyle as React.CSSProperties}>
                <Formik
                data-test-id="formik"
                    initialValues={{
                        old_password: '',
                        new_password: '',
                        confirm_new_password: '',
                    }}
                    validationSchema={this.validationSchema}
                    validateOnChange={false}
                    onSubmit={(values) => {
                        this.handleResetPassword(values);
                    }}
                >
            
                    {({ handleSubmit, handleChange, handleBlur, values, touched, isValid }) => {
                        const isFormValid = touched && Object.keys(touched).length !== 0 && isValid;
                        const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
                            if(event.key === "Enter"){
                                handleSubmit();
                            }
                        }
                        return (
                            <form onSubmit={handleSubmit} data-test-id="reset-submit">
                                <Grid style={webStyle.paddingSpace}>
                                    <Grid item xs={12}>
                                        <Typography style={webStyle.typoStylePassword}>Enter your old password</Typography>
                                    </Grid>
                                    <Grid item xs={12} style={webStyle.gridStylee}>
                                        <Typography style={webStyle.fieldTitle}>Password</Typography>
                                        <div style={webStyle.passwordStyle}>
                                            <TextFieldCustomText
                                                fullWidth
                                                variant="outlined"
                                                type={this.state.showPassword ? "text" : "password"}
                                                placeholder="Password"
                                                name="old_password"
                                                autoComplete='new_password'
                                                onChange={handleChange}
                                                value={values.old_password}
                                                onBlur={handleBlur}
                                                data-test-id="old_password"
                                                onKeyDown={handleKeyDown}
                                            />
                                            <IconButton data-test-id="handleClickShowPassword" onClick={this.handleClickShowPassword} tabIndex="-1" style={webStyle.visiblePassStyle}>
                                                {this.state.showPassword ? (
                                                    <VisibilityIcon style={webStyle.passwordVisibilityOnIcon} />
                                                ) : (
                                                    <VisibilityOffIcon style={webStyle.passwordVisibilityOffIcon} />
                                                )}
                                            </IconButton>
                                        </div>
                                        <ErrorMessage name="old_password" component="div" className="errorStyle"/>
                                        <Typography data-test-id="forgot-password" component="button" className="endLine" onClick={()=> this.handleForgotPasswordModal(true)}>Forgot Password?</Typography>
                                    </Grid>

                                    <Grid item xs={12}>
                                        <Typography style={webStyle.typoStylePassword}>{`Enter a new password for ${this.state.profile?.attributes?.email}`}</Typography>
                                        <Typography className="txtGrey">The password should have at least one lowercase and one uppercase characters.</Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography style={webStyle.fieldTitle}>New Password</Typography>
                                        <div style={webStyle.passwordStyle}>
                                            <TextFieldCustomText
                                                fullWidth
                                                variant="outlined"
                                                type={this.state.showNewPassword ? "text" : "password"}
                                                placeholder="New Password"
                                                data-test-id="new_password"
                                                name="new_password"
                                                autoComplete='new_password'
                                                onChange={handleChange}
                                                value={values.new_password}
                                                onBlur={handleBlur}
                                                onKeyDown={handleKeyDown}
                                            />
                                            <IconButton data-test-id="handleClickShowNewPassword" onClick={this.handleClickShowNewPassword} tabIndex="-1" style={webStyle.visiblePassStyle}>
                                                {this.state.showNewPassword ? (
                                                    <VisibilityIcon style={webStyle.passwordVisibilityOnIcon} />
                                                ) : (
                                                    <VisibilityOffIcon style={webStyle.passwordVisibilityOffIcon} />
                                                )}
                                            </IconButton>
                                        </div>
                                        <ErrorMessage name="new_password" component="div" className="errorStyle"/>
                                    </Grid>
                                    <Grid item xs={12} className="gridTypo">
                                        <Typography style={webStyle.fieldTitle}>Confirm New Password</Typography>
                                        <div style={webStyle.passwordStyle}>
                                            <TextFieldCustomText
                                                fullWidth
                                                variant="outlined"
                                                type={this.state.showConfirmPassword ? "text" : "password"}
                                                placeholder="Confirm New Password"
                                                data-test-id="confirm_new_password"
                                                name="confirm_new_password"
                                                autoComplete='new_password'
                                                onChange={handleChange}
                                                value={values.confirm_new_password}
                                                onBlur={handleBlur}
                                                onKeyDown={handleKeyDown}
                                            />
                                            <IconButton data-test-id="handleClickShowNewConfirmPassword" onClick={this.handleClickShowNewConfirmPassword} tabIndex="-1" style={webStyle.visiblePassStyle}>
                                                {this.state.showConfirmPassword ? (
                                                    <VisibilityIcon style={webStyle.passwordVisibilityOnIcon} />
                                                ) : (
                                                    <VisibilityOffIcon style={webStyle.passwordVisibilityOffIcon} />
                                                )}
                                            </IconButton>
                                        </div>
                                        <ErrorMessage name="confirm_new_password" component="div" className="errorStyle"/>
                                    </Grid>

                                    <Grid item xs={12} className="endLineBtn">
                                        <Button onClick={this.handlePasswordCloseModal} className="btnCancel">
                                           <Typography className="textBtnCreate">Cancel</Typography> 
                                        </Button>
                                        <Button disabled={!isFormValid} type="submit" classes={{disabled: "btnDisabled"}} className={`btnReset`} data-test-id="resetBtn">
                                            <Typography className="textBtnCreate">Reset</Typography>
                                        </Button>
                                    </Grid>
                                </Grid>
                            </form>
                        )
                    }}
                </Formik>
                </DialogContent>
            </ModelStyle>
            <StyledDialog open={this.state.resetPasswordSucceed}>
                <StyledDialogTitle>Reset Password</StyledDialogTitle>
                <StyledDialogContent>
                <Typography>Your password has been successfully changed.</Typography>
                </StyledDialogContent>
                <StyledDialogActions>
                <StyledButton  onClick={this.handleCloseDialog} color="primary" variant="contained" className="okBtn">
                    <Typography className="">Okay</Typography>
                </StyledButton >
                </StyledDialogActions>
            </StyledDialog>
            <CommonDialog onClose={this.handleCloseDialog} hasCloseButton={true} isOkdisabled={this.state.verificationLinkDisabled} data-test-id="forgot-password-dialog" onHandleOk={() => this.handleForgotPasswordModal(true)} open={this.state.isSendLinkModalOpen} title="Forgot Password" description={<>An email has been sent to {this.state.profile?.attributes?.email} with instructions for resetting your password. Do not forget to check your spam folder. <br/><br/> If you have not received it, please click on Resend Link below.</>} okText="Resend Link"/>
            </ThemeProvider>
            // Customizable Area End
        );
    }
}
export default withStyles(styles)(SettingsProfile);

// Customizable Area Start

const StyledDiv = styled("span")({
    paddingTop: "4px !important",
    paddingLeft: "0px !important"
})
const webStyle = {
    mainWrapper: {
        display: "flex",
        fontFamily: "Roboto-Medium",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        background: "#fff",
    },
    passwordVisibilityOffIcon: {
        position: "absolute" as "absolute",
        right: "10px",
        top: "7px",
        width: "24px",
        height: "24px",
        flexShrink: 0,
        color: "#9B9B9D",
        cursor: "pointer",
      },
      passwordVisibilityOnIcon: {
        position: "absolute" as "absolute",
        right: "10px",
        top: "7px",
        width: "24px",
        height: "24px",
        flexShrink: 0,
        color: "#000",
        cursor: "pointer",
      },
    dialougeHeading: {
        display: "flex",
        justifyContent: "space-between",
        background: "#000",
        borderRadius: "4px, 4px, 0px, 0px",
        color: "white",
        padding: "20.5px 20px 20.5px 40px",
    },
    title:{
        fontFamily: "Silika-Medium",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "19px",
    },
    inputStyle: {
        borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    fieldTitle:{
        fontFamily: "Silika-Light",
        fontSize: "16px"
    },
    passwordStyle: {
        display: 'flex',
        alignItems: 'center'
    },
    visiblePassStyle:{
        marginLeft:-25,
        marginTop:-4
    },
    passwordTypo:{
        fontFamily:'Silika-light',
        fontSize:'16px'
    },
    closeIconStyle:{
        width: "20px", 
        paddingRight: "20px",
         cursor: "pointer" 
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        border: "none",
        backgroundColor: "rgb(98, 0, 238)",
    },
    positionStyle:{
        position:"relative",
        overflowX: 'hidden',
        "& h2":{
            fontFamily: "Silika-Medium !important"
        }
    },
    paddingSpace:{
        padding: "24px 40px"
    },
    typoStylePassword:{
        fontFamily:'Silika-medium',
        fontSize:'16px',
        fontWeight:600,
        marginTop:"15px"
    },
    gridStylee:{
        marginTop:"25px"
    }
};
const TextFieldCustomText = styled(TextField)({
    width: "100%",
    marginTop:'7px',
    "& .MuiInputBase-input": {
        width: "100% !important",
        fontSize:'14px',
        fontFamily: "Silika-Medium"

       
    },
    "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
            border:'1px solid gray',
            outline:'none',
        },
      },
    
  })

const ModelStyle = styled(Dialog)({
    "& .backdrop-class": {
        backgroundColor: "rgba(255, 255, 255, 0.5)"
    },
    "& .paddingSpace": {
        padding:"25px"
    },
    "& .gridTypo":{
        marginTop:'15px'
    },
    "& .MuiDialogContent-root": {
        padding:"0"
    },
    "& .endLine": {
        display:"grid",
        justifyContent:"end",
        fontFamily:'Silika-light',
        fontSize:'16px',
        marginTop:'15px',
        border: "none",
        background: "none",
        marinRight: "0px",
        marginLeft: "auto",
        textTransform: "none"
    },
    "& .endLineBtn": {
        display:"flex",
        justifyContent:"end",
        margin:"35px 0 0 0",
        gap:"20px"
    },
    "& .btnCancel": {
        backgroundColor:"#FFFFFF",
        color:"#010101",
        border:"1px solid #010101",
        borderRadius:"2px",
        padding:"16px",
        width:"104px",
        height:"48px",
        fontFamily:'Silika-medium',
        fontWeight:500,
        textTransform:'none',
        transition: "box-shadow 0.4s ease-in-out, color 0.3s ease-in-out",
        "&:hover": {
            boxShadow: "inset 400px 0 0 0 #000 !important", 
            color: "#fff",
            "& .textBtnCreate": {
            color: "#fff !important", 
            fontFamily: "silika-Medium",
            },
        },
        "& .textBtnCreate": {
            boxShadow: "inset 0 0 0 0 #000 !important",
            transition: "color 0.3s ease-in-out, box-shadow 0.4s ease-in-out",
            fontFamily: "silika-Medium",
        },
    },
    "& .btnReset": {
        backgroundColor:"#0D0C22",
        color:"#FFFFFF",
        border:"1px solid #0D0C22",
        borderRadius:"2px",
        padding:"16px",
        width:"104px",
        height:"48px",
        fontFamily:'Silika-medium',
        fontWeight:500,
        textTransform:'none',
        boxShadow:"inset 0 0 0 0 #fff !important",
        "&:hover":{
            boxShadow:"inset 400px 0 0 0 #fff !important",
            "& .textBtnCreate":{
              color: "#0D0D0D !important" , 
            }  
          },
          "& .textBtnCreate":{
            boxShadow:"inset 0 0 0 0 #fff !important", 
            transition: "color 0.3s ease-in-out",  
          },
    },
    "& .btnDisabled":{
        background: "#9B9B9D",
        border:"1px solid #9B9B9D",
    },
    "& .errorStyle":{
        color:'red',
        fontFamily: "Silika-Medium",
        fontSize: "14px"
    },
    "& .txtGrey": {
        color:"#9B9B9D",
        fontFamily: "Silika-light",
        margin:"10px 0"

    },
    "& .spaceBoth": {
        margin:"25px 0 0 0"

    },
    

    
})

const StyledDialog = styled(Dialog)(({ theme }) => ({
    '& *':{
      fontSize: "16px"
    },
    '& .MuiPaper-root': {
      backgroundColor: '#fff',
      borderRadius: 4,
      width: '600px',
      boxShadow: 'none',
      paddingBottom: "9.5px",
    }
  }));

const StyledDialogTitle = styled(DialogTitle)(({ theme }) => ({
    backgroundColor: '#0D0C22',
    color: '#fff',
    padding: '18px 37px 18px 40px',
    fontWeight: 'bold',
    "& *":{
      fontFamily: "Silika-Medium",
    }
  
}));
  
const StyledDialogContent = styled(DialogContent)(({ theme }) => ({
    padding: '48px 16px 24px 16px',
    "& p":{
      fontWeight: 300,
      fontFamily: "Silika-Light",
      textAlign: "center"
    }
}));
  
const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
    padding: '16px 40px 24px 40px',
    display: 'flex',
    justifyContent: "center",
    '& button':{
      fontFamily: "Silika-Medium",
      textTransform: "none",
      borderRadius: "0",
      boxShadow: "none",
      height: "48px"
    },
   "& .okBtn": {
  background: "#0D0C22", 
  border: "1px solid #0D0C22",
  textTransform: "none",
  borderRadius: "2px",
  color: "#fff", 
  boxShadow: "inset 0 0 0 0 #fff !important",
  transition: "background-color 0.3s ease-in-out, color 0.3s ease-in-out, box-shadow 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "#fff", 
    color: "#0D0D0D", 
    boxShadow: "inset 400px 0 0 0 #fff !important", 
    "& .textBtnCreate": {
      color: "#0D0D0D !important", 
    }
  },
  "& .textBtnCreate": {
    boxShadow: "inset 0 0 0 0 #fff !important",
    transition: "color 0.3s ease-in-out",
    color: "#fff", 
  },
}
}));

const StyledButton = styled(Button)(({ theme }) => ({
    minWidth: '100px',
}));

const StyledSettingProfileWeb = styled("div")({
    display: "inline-flex",
"& .Heading":{
    width: "24%"
},
"& .Details":{
    width: "68%",
    paddingTop: "34.5px",
    paddingLeft: "40px",
}
});
// Customizable Area End
