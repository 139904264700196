// Customizable Area Start
import React from "react";

import {
    Box,
    Button,
    Divider,
    Typography,
} from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LoginSignUp, { webStyle } from "../../email-account-login/src/LoginSignUp.web";
import AccountRegistrationController, { Props } from "./AccountRegistrationController";
const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class PaymentMethod extends AccountRegistrationController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        return (
            // Required for all blocks
            <ThemeProvider theme={theme}>
                <div style={{ overflow: 'hidden' }}>
                    <LoginSignUp navigation={this.props.navigation} id={""}>
                        <OuterStyle>
                            <div style={{ ...webStyle.formTitleContainer, justifyContent: 'left', marginBottom: '35px' }}>
                                <Typography style={{ ...webStyle.formTitleText, textAlign: "left" as "left", }}>
                                    Your membership Plan
                                </Typography>
                            </div>
                            <LineDivider />
                            <Box className="EnterpriseStyle">
                                <Box>
                                    <Typography className="textStyle">
                                        Enterprise Plan
                                    </Typography>
                                    <Typography className="pera">This plan includes 6 seats.</Typography>
                                    <Typography className="linkStyle">Plan Details</Typography>
                                </Box>
                                <Box>
                                    <Typography style={{ ...webStyle.formTitleText, marginTop: "0px" }}>£ 80</Typography>
                                </Box>
                            </Box>
                            <LineDivider />
                            <Box className="billingStyle">
                                <Box>
                                    <Typography>Monthly Billing schedule</Typography>
                                </Box>
                                <Box>
                                    <Typography><span>Next payment date</span> &nbsp;23th August, 2024</Typography>
                                </Box>
                            </Box>
                            <LineDivider />
                            <StyledNextBtn>
                                <div>
                                    <Button className="backBtn" data-test-id="paymentBackID" onClick={() => { this.paymentBack() }}>Back</Button>
                                    <Button className="nextBtn" data-test-id="paymentConfirmID" onClick={() => { this.paymentConfirm() }}>Confirm</Button>
                                </div>
                            </StyledNextBtn>
                        </OuterStyle>
                    </LoginSignUp>
                </div>
            </ThemeProvider>
        );
    }
}
const baseTypographyStyle = {
    fontFamily: "Silika-Light",
    lineHeight: "16.8px",
    letterSpacing: "0.02em",
};
const OuterStyle = styled("div")({
    marginTop: "108px",
    overflow: "hidden",
    width: "727px",
    [theme.breakpoints.down("sm")]: {
        width: "100%",
    },
    "& .lineStyle": {
        border: "1px solid #DFDFDF",
        height: "0px"
    },
    "& .EnterpriseStyle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "35px 0px",
    },
    "& .billingStyle": {
        display: "flex",
        justifyContent: "space-between",
        margin: "35px 0px",
        fontFamily: "Silika-Medium",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "16.8px",
        letterSpacing: " 0.02em",
        "& span": {
            color: "#9B9B9B",
            fontSize: "14px",
            fontWeight: 400,
            lineHeight: "16.8px",
            letterSpacing: " 0.02em",
        },
        "& p":{
            fontSize: "14px",
            fontFamily: "Silika-Medium",
            color:"#242121"
        }
    },
    "& .textStyle": {
        fontFamily: "Silika-Medium",
        fontSize: "24px",
        fontWeight: 500,
        lineHeight: "28.96px",
        letterSpacing: "-0.5616000294685364px",
    },
    "& .pera": {
        ...baseTypographyStyle,
        fontSize: "16px",
        fontWeight: 300,
        marginTop: "5px",
    },
    "& .linkStyle": {
        ...baseTypographyStyle,
        fontSize: "14px",
        fontWeight: 400,
        marginTop: "5px",
        textDecorationLine:"underline"
    }
})
const buttonBaseStyle = {
    width: "129px",
    height: "48px",
    padding: "23.04px",
    gap: "11.52px",
    borderRadius: "2.88px",
    fontFamily: "Silika-Medium",
    fontSize: "14px",
    fontWeight: 300,
    lineHeight: "18px",
    textTransform: "capitalize",
};
const StyledNextBtn = styled('div')({
    marginTop: "100px",
    display: "flex",
    justifyContent: "end",
    width: "100%",
    [theme.breakpoints.down("sm")]: {
        marginTop: "30px",
    },
    "& .backBtn": {
        ...buttonBaseStyle,
        background: "#FFFFFF",
        border: "1.44px solid #010101",
        marginRight: "20px",
    },
    "& .nextBtn": {
        ...buttonBaseStyle,
        background: "#0D0C22",
        color: "#FFFFFF",
    }
})
const LineDivider = styled(Divider)({
    border: "1px solid #DFDFDF",
    height: "0px",
});
// Customizable Area End