import React from "react";

// Customizable Area Start
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import LandingHeader from "../../../components/src/LandingHeader.web";
import { LandingFooter } from "../../../components/src/LandingFooter.web";
import { Box, Button, Container, Grid, InputAdornment, TextField, Typography } from "@material-ui/core";
import { isImageOrVideo ,getSurname } from "../../../components/src/HelperUtils";
import {restricted , shareImage , removeIcon} from "./assets"
import HeaderNavbar from "../../../components/src/Navbar";
import {CircularProgress} from '@material-ui/core';
import FavoriteIcon from '@mui/icons-material/Favorite';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { ShareLinkDialog, ShareMenuDialog } from "../../../components/src/ShareDialogs.web";

// Customizable Area End

import LandingPage6Controller, { Props } from "./LandingPage6Controller";
import SearchIcon from '@mui/icons-material/Search';
// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
  typography: {
    fontFamily: "Silka",
  },
});
const StyledContainer = styled(Box)({
  "& .parent": {
    disply: "flex",
    flexDirection: "column" as "column",
    alignItems: "flex-start",
    padding:"80px",
    [theme.breakpoints.down('sm')] :{
      width: "100%",
      padding: "24px"
    }
  },
  "& .headding": {
    marginBottom: 40,
    fontFamily: "silika-Medium",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    fontWeight: 500,
    fontSize: "32px",
    [theme.breakpoints.down('sm')] :{
      marginBottom : 24,
      fontSize: "20px",
    }
  },
  "& .galleryContainer": {
    display: "grid",
    gridTemplateColumns: "repeat(auto-fill, minmax(302px, 1fr))",
    gap: "24px",
    width: "100%",
    marginBottom : 24,
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    },
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: "repeat(4, minmax(0, 1fr))",
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: "repeat(3, minmax(0, 1fr))",
    },
    "@media (max-width: 599px) and (min-width: 400px)": {
      gridTemplateColumns: "repeat(2, minmax(0, 1fr))", 
    },
    "@media (max-width: 399px)": {
      gridTemplateColumns: "1fr", 
    },
  },
  "& .imageWrapper:hover":{
    filter:"grayscale(0.3)",
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .imageWrapper:hover .collabImgHover, .imageWrapper:hover .likeShareSaveButtonsDiv, .imageWrapper:hover, .collabImgHover, .likeShareSaveButtonsDiv":{
    opacity: 1
  },
  "& .imageWrapper": {
    width: "100%", 
    position: "relative", 
    borderRadius: "4px",
    overflow: "hidden",
    cursor : "pointer",
    "&:hover":{
      boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.06),0px 8px 12px rgba(0, 0, 0, 0.03), 0px 12px 16px rgba(0, 0, 0, 0.09)",
      transform: "scale(1.05)"
    },
  },
  "& .collaborationGridItem, .confCollaborationGridItem": {
    borderRadius: "4px",
    aspectRatio: "403/312",
    [theme.breakpoints.down("xs")]: {
      aspecRatio: "auto",
      height: "252px"
    }
  },
  "& .collaborationGridItem:hover .collaborationGridItemInside": {
    filter: "grayscale(0.3)",
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .collaborationGridItemInside": {
    width: "100%",
    height: "100%",
    position: "relative",
  },
  "& .collabImgHover":{
    position: "absolute",
    top:0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    gap: "10px",
    fontFamily: "Silika-Medium",
    color: "#010101"
  },
  "& .collaborationGridItem:hover .collabImgHover, .collaborationGridItem:hover .likeShareSaveButtonsDiv, .collaborationGridItem:hover .removeCollab, confCollaborationGridItem:hover .collabImgHover, .confCollaborationGridItem:hover .likeShareSaveButtonsDiv, .confCollaborationGridItem:hover .removeCollab": {
    opacity: 1
  },
  "& .collaborationGridItem:hover .collabImg": {
    opacity: 0.5
  },
  "& .likeShareSaveButtonsDiv": {
    display: "inline-flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "12px",
    opacity: 0,
    "& .actionButton": {
      height: "24px",
      width: "24px",
      cursor: "pointer",
      "&:first-child": {
        marginRight: "12px"
      }
    },
    "& .actionButtonImg": {
      height: "22px",
      width: "22px",
      marginRight: "12px",
      cursor: "pointer"
    }
  },
  "& .confCollaborationGridItem:hover .collaborationGridItemInside": {
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .image": {
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover", 
    borderRadius: "4px"
  },
  "& .footer": {
    marginTop : "40px"
  },
  "& .loadMoreBtn" : {
    width: "360px",
    height: "48px",
    textTransform: "none",
    border: "1px solid black",
    padding: "16px",
    borderRadius: "2px",
    fontFamily :"Silika-Medium",
    fontWeight : 500,
    fontSize:"16px",
    lineHeight:"19.2px",
    letterSpacing  :"-0.15px",
    "&:hover":{
      backgroundColor  :"black",
      color : "white"
    },
    "@media (max-width: 399px)": {
      width : "100%",
    },
    "& .loadingContainer":{
      height:"48px",
      width  :"100%",
      alignItem:"center",
      marginTop:40,
      border : "1px solid red"
     },
  },
  "& .restrictedDivMain": {
    position: "absolute",
    top: 0,
    left:0,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.9)",
    zIndex : 2
  },
  "& .restrictedDiv": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .restrictedImg": {
    width: "24px",
    height: "24px",
  },
  "& .confidentialPara": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    margin: 0,
    maxWidth: "220px",
    fontSize: "14px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidentialText": {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: 500,
    marginLeft: "4px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  }
});
const StyledInput = styled('div')({
  marginBottom:"90px",
  "& .MuiOutlinedInput-root": {
    height: "48px",
    borderColor: "transparent !important",
    "&.Mui-focused .MuiOutlinedInput-notchedOutline":{
      border: "1px solid #DFDFDF !important",
  },
  "&:hover .MuiOutlinedInput-notchedOutline":{
    border: "1px solid #DFDFDF !important",
  }
 
  },
  "& .MuiOutlinedInput-notchedOutline":{
    borderColor: "none !important",
  },
  "& .MuiInputBase-input":{
    fontFamily: "Silika-Medium",
    fontSize:"16px",
    fontWeight: 500,
    width:"97% !important",
    border: "transparent!important",
    "&::placeholder": {
      fontWeight: 500,
      color: "#010101 !important",
      fontFamily: "Silika-Medium",
      fontSize:"16px",
      opacity:"0.9"
    },
    "@media (max-width:680px)": {
      width: "80% !important",
    },
   
  },
  "& .MuiFormControl-root":{
    border: "transparent !important",
  }
});
// Customizable Area End

export default class LandingPage6Web extends LandingPage6Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  loadMoreBtn =()=>{
    return(
      !this.state.isLogedIn ? this.state.images.length > 0 && <Grid container className="loadingContainer">
     <Grid item style={webStyle.loadingDiv}>
       <Grid item  style={{justifyContent:"center",display:"none", width:"100%"}}>
         <Button className="loadMoreBtn"  onClick={this.handleLoadMore}>
         Load More </Button> 
       </Grid>
     </Grid>
   </Grid>: <></> 
    )
   }

   renderLoadingMore = () => {
    return this.state.isLoadingMore ? (
      <div style={{
        width: "100%",
        textAlign: "center",
        padding: "20px 0",
        gridColumn: "1 / -1"
      }}>
        <CircularProgress style={{ color: "#383838" }} />
      </div>
    ) : null;
  }
  renderPostFile = (img_url : string, postId: string, isConfidential: boolean) => {
    const urlType = isImageOrVideo(img_url);
    if(isConfidential){
      return(
        <div data-test-id="mediaImage" className="imageWrapper">
              <div className="restrictedDivMain">
                <div className="restrictedDiv">
                  <img
                    className="restrictedImg"
                    data-test-id="restrictedImgDiv"
                    src={restricted}
                  />
                  <p className="confidentialText">Confidential</p>
                </div>
                <p className="confidentialPara">
                  You have to be a collaborator to view this
                  content.
                </p>
          </div>
          {urlType === "image" ?
            <img height="100%" width="100%" data-test-id={`image-${postId}`} src={img_url} className="confidential_img" />
            :
            <video
              src={img_url}
              controls
              className="confidential_img"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          }
          </div>
      )
    }
    if(urlType === "image"){
      return <img height={300} width={400} data-test-id={`image-${postId}`} src={img_url} className="image collabImg" />
    }else{
      return <video
      src={img_url}
      controls
      className="image collabImg"
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      }}
    />
    }
  }
 
  renderLikeSaveShare = (isConfidential: boolean, index: number, is_favourite: boolean | undefined, is_like: boolean | undefined, id: number, profile_id: any, login:boolean , showDeleteIcon: boolean = true) => {
   return <>
      {!isConfidential &&
       login ?
       <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv">
      {is_like ? (
            <FavoriteIcon className="actionButton" data-test-id="favoriteIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.likePost(event , "unlike" , id)}/>
          ) : (
            <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.likePost(event ,"like" , id)}/>
          )}
        
        <img src={shareImage} className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery" onClick={(event: React.MouseEvent<HTMLImageElement>)=>{this.openPopUp(event,profile_id,id, "post")}}/>
      {is_favourite ? (
          <BookmarkIcon className="actionButton" data-test-id="bookmarkIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.handleAddToFavorite(event,"unfavourite", id)}/>
        ) : (
          <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.handleAddToFavorite(event,"favourite",id)}/>
        )}
      </div>
      : <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv">
      <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon"/>
      <img src={shareImage} className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery"/>
      <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon"/>
      </div>
      }
      {showDeleteIcon && (
            <img
              className="removeCollab"
              data-test-id="deleteIcon"
              onClick={(event: React.MouseEvent<HTMLImageElement>) => { 
                event.stopPropagation();
                this.setState({ deleteModal: true,postID:profile_id })
               }}
              height={22}
              width={22}
              src={removeIcon}
            />
        )}
    </>
  }
   renderMain = () => {
    const {searchMassege, images, searchImage} = this.state;
      const dataToRender_ = searchImage.length > 0 ? searchImage : images;
     if(this.state.searchMassege == ""){
       return  <div className="galleryContainer" data-test-id="galleryContainer">
      {
        (searchImage.length > 0 ? searchImage : images)?.map((item, index) => {
          let isConfidential = item.attributes.confidential;
          const isOtherUser = item.attributes?.profile_id?.toString() !== sessionStorage.getItem("profileId");
          const profileUserId_ = sessionStorage.getItem("profileUserId") || "";
          if(profileUserId_ == item.attributes.account_id.toString() || (item.attributes.collaborators && item.attributes.collaborators.map((c: any) => c.id).includes(Number(profileUserId_)))){
            isConfidential = false;
          }
          if (item.attributes.images.length > 0) {
            return (
              <>
                {
                  item.attributes.images.map((image, i) => 
                        image.is_visible && (
                        <>
                        <div key={i} data-test-id="mediaImage" onClick={() => this.handleImageNavigation(item.id, isConfidential, item.attributes?.profile_id?.toString())} className="collaborationGridItem">
                          <div className="collaborationGridItemInside">
                            {this.renderPostFile(image?.url, item.id, isConfidential)}
                            <div className="collabImgHover">
                              {item.attributes.project_title || "Project Name"}
                            </div>
                            {
                             this.renderLikeSaveShare(isConfidential, index, image.is_favourite, image.is_like, item.attributes.id , item.attributes.profile_id, this.state.isLogedIn, false )
                            }
                          </div>
                        </div>
                        {(dataToRender_.length > 0 && index === dataToRender_.length - 1) && this.renderLoadingMore()}
                        {(dataToRender_.length > 0 && index === dataToRender_.length - 1) && this.loadMoreBtn()}
                        </>
                        )
                    )
                }
              </>
            )
          }
        })
        }
    </div>
    }else{
      return <div style={{ textAlign: "center" }}>
      <span style={{color:"#000000",fontSize:"32px"}}>{searchMassege}</span><br/><br/>
      <span style={{fontSize:"16px",color:"#9B9B9D"}}>Unfortunately no results meet these <br /> search criteria</span>
    </div>
    }
   }
  // Customizable Area End
  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      // Required for all blocks
      <ThemeProvider theme={theme}>
        {localStorage.getItem("token")==null?<LandingHeader navigation={this.props.navigation} /> : <HeaderNavbar navigation={this.props.navigation}/>}
          <StyledContainer>
            <Container maxWidth="xl" className="parent">
             <Typography className="headding">Gallery {this.handleSearch()}</Typography>
             {this.state.isLogedIn ? <StyledInput data-test-id="searchbarDiv">
              <TextField
                id="adSearchText"
                type="text"
                variant="outlined"
                value={this.state.searchValue}
                placeholder="Owner name, Title , Description ."
                onChange={(event) => this.searchHandle(event.target.value)}
                style={webStyle.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon/>
                    </InputAdornment>
                  ),
                }}
              />
            </StyledInput>: <></>}
            <div>
              {this.renderMain()}
              <ShareLinkDialog
              isOpen={this.state.openModel}
              onClose={this.handleClose}
              onClickChatButton={this.handleChat}
              shareLink={this.state.shareLink}
              isChatSend={this.state.msgSend}
            />
               <ShareMenuDialog
              onClose={this.handleCloseChatModel}
              onChangeChatMessage={this.handlesendChatMessage}
              isOpen={this.state.isSendLinkToChatModalOpen}
              shareData={this.state.shareData}
              chatMessage={this.state.sendChatMessage}
              data-test-id="ShareMenuDialogID"
              onShareDataChange={this.handleshareDataChatChange}
              onClickBackButton={this.handleNavigateBack}
              onClickSendChat={this.handleSend}
              profiles={this.state.getAllData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
                account_id:option.attributes.account_id,
                firstName: option.attributes.first_name,
                lastName:  getSurname(option.attributes.last_name, option.attributes.account_id.toString()),
                photo: option.attributes.photo,
              }))}
            /> 
              </div>
            </Container>
          </StyledContainer>
          <LandingFooter  navigation={this.props.navigation} />
      </ThemeProvider>
      // Customizable Area End  
    );
  }
}
// Customizable Area Start
const webStyle={
   loadingDiv:{
    height:"48px",
    width : "100%",
    alignContent: "center",
    display: "flex",
    justifyContent:"space-between",
    marginLeft:"auto"
   },
  loadSpinner:{
    width:"20px",
    height:"20px",
    color:"black",
    marginLeft:"20px"
  },
  input: {
    width: "100%",
    heigth: "40px",
    alignItem: "center",
    border:"1px solid #DFDFDF",
    borderRadius:'2px',
  },
}
// Customizable Area End
