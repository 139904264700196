import React from "react";
// Customizable Area Start
import {
  Theme,
  Button,
  TextField,
  ButtonGroup,
  GridSpacing,
  Typography, Grid, Dialog, DialogActions, DialogContent, DialogTitle, DialogContentText, Box,Switch, Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { 
  createTheme, 
  styled, 
  ThemeProvider,
  withStyles 
  } from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import EditIcon from "@material-ui/icons/Edit";
import HeaderNavbar from "../../../components/src/Navbar";
import AddIcon from "@material-ui/icons/Add";
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import "./ProfileInfo.css";
import { 
  Formik, 
  Form, 
  ErrorMessage } from "formik";
import { 
  restricted,
  likeImage, dislikeImage,
  shareImage, 
  closeImage,
  instagram, linkedin, behance, viom, yt, tt,
  upArrow,downArrow,removeIcon} from "./assets"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { calendarIcon } from "../../email-account-registration/src/assets"
import { checkCondition, formatYearRange, concatArrayOfstring, returnTruthyArray, returnTruthyString, isImageOrVideo, getSurname } from "../../../components/src/HelperUtils";
import CustomModal from "../../../components/src/CustomModal.web";
import CommonDialog2 from "../../../components/src/CommonDialog2.web";
import { ShareLinkDialog, ShareMenuDialog } from "../../../components/src/ShareDialogs.web";
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import { ICompanyName, IInstitutionRecord } from "../../../components/src/interfaces.web"
import ChatBubbleOutlineIcon from '@material-ui/icons/ChatBubbleOutline';
import ShareIcon from '@material-ui/icons/Share';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Postdetails from "../../postcreation/src/ListPost.web";

const CustomDatePicker = styled(DatePicker)(({ theme }) => ({
    height: '40px',
    textAlign: 'right',
    fontSize:'15px',
    padding:'10px'
}));

const StyledUnavailabilitySection = styled("div")(() => ({
  display: "inline-flex",
  width: "100%",
  justifyContent: "space-between",
  position: "relative",
}))

const today = new Date();

const IOSSwitch = withStyles((theme) => ({
  root: {
    padding: 0,
    width: 45,
    height: 28,
  },
  switchBase: {
    padding: 1,
    top: 1,
    left: 1,
    '&$checked': {
      transform: 'translateX(17px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        border: 'none',
        backgroundColor: "#00FFA7 ",
      },
    },
    "& .MuiSwitch-colorSecondary-50.Mui-checked + .MuiSwitch-track-54": {
      backgroundColor: '#00FFA7 !important'
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #F1F5F9',
    },
    '& #swith': {
      marginTop: "1px",
    },
    "& .MuiSwitch-root-45": {
      marginRight: '16px !important'
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 29 / 2,
    border: `0px solid ${theme.palette.grey[400]}`,
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
    backgroundColor: '#E2E8F0',
  },
  checked: {},
  focusVisible: {},
}))(Switch);
// Customizable Area End

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  },
  typography: {
    button: {
      textTransform: 'none'
    }
  }
});

import ProfileInfoController, {
  Props,
} from "./ProfileInfoController";
import EditProfileModal from "./components/EditProfileModal";
import EditAbout from "./components/EditAbout";
import EditExperience from "./components/EditExperience";
import EditEducation from "./components/EditEducation"
import EditSocial from "./components/EditSocial"
import PostCreationWeb from "../../postcreation/src/PostCreationWeb";
import ClientProfile from "./ClientProfile.web";
import moment from "moment";
import { isNumber } from "lodash";
const socialMediaIcons : Record<string, string> = {
  "youtube": yt,
  "instagram": instagram,
  "behance": behance,
  "linkedin": linkedin,
  "vimeo": viom,
  "tikTok": tt
}
// Customizable Area End

export default class ProfileInfo extends ProfileInfoController {
  dive() {
    throw new Error("Method not implemented.");
  }
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  renderLikeSaveShare = (isConfidential:boolean, index: number, isFavorited: boolean, isLiked: boolean ,id: string, postID:number, showDeleteIcon: boolean = true) => {
    return <>
      {!isConfidential && <div className="likeShareSaveButtonsDiv" data-test-id="likeShareSaveButtonsDiv">
      {isLiked ? (
            <FavoriteIcon className="actionButton" data-test-id="favoriteIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.handleLikeUnlikeMedia(event , "unlike" , postID)} />
          ) : (
            <FavoriteBorderIcon className="actionButton" data-test-id="favoriteBorderIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.handleLikeUnlikeMedia(event, "like",postID)} />
          )}
        
        <img src={shareImage} className="actionButtonImg" height={20} width={20} data-test-id="openPopupGallery" onClick={(event: React.MouseEvent<HTMLImageElement>)=>{this.openPopUp(event,postID, "post")}}/>
      {isFavorited ? (
          <BookmarkIcon className="actionButton" data-test-id="bookmarkIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.handleAddToFavorite(event,"unfavourite", postID)} />
        ) : (
          <BookmarkBorderIcon className="actionButton" data-test-id="bookmarkBorderIcon" onClick={(event: React.MouseEvent<SVGSVGElement>) => this.handleAddToFavorite(event,"favourite", postID)} />
        )}
      </div>}
      {showDeleteIcon && (
            <img
              className="removeCollab"
              data-test-id="deleteIcon"
              onClick={(event: React.MouseEvent<HTMLImageElement>) => { 
                event.stopPropagation();
                this.setState({ deleteModal: true,postID:postID })
               }}
              height={22}
              width={22}
              src={removeIcon}
            />
        )}
    </>
  }
  renderButtonGroup(hideClass: string) {
    const fullScreenSelected = this.state.spacing === 1;
    const exitFullScreenSelected = this.state.spacing === 4;
    const urlParams = location.pathname.slice(location.pathname.lastIndexOf("/") , location.pathname.length)
    return (
      <div style={{ ...webStyle.activeTabWork, width: '100%' }}>
        <StyledAddPostButton
          data-test-id='addPostBtn'
          onClick={() => { this.handlePostCreation() }}
          variant="outlined"
          type="submit"
          className={hideClass}
        >
          <Typography className="textBtnCreate">Add Post</Typography>
        </StyledAddPostButton>
        {urlParams === "/ProfileInfo" && (
          <StyledButtonContainer>
            <Button data-test-id="decreaseSpacingBtn" className={`fullScreen ${fullScreenSelected ? "selected" : ""}`} onClick={this.decreaseSpacing}>
              <FullscreenIcon/>
            </Button>
            <Button data-test-id="increaseSpacingBtn" className={`exitFullScreen ${exitFullScreenSelected ? "selected" : ""}`} onClick={this.increaseSpacing}>
              <FullscreenExitIcon/>
            </Button>
          </StyledButtonContainer>
        )}
      </div>
    );
  }
  renderNoData() {
    return <div style={{ ...webStyle.noDataDiv, textAlign: 'center' }} >
      No Data Available
    </div>;
  }
  renderWorkDivIcon() {
    return this.state.myWorkDiv ? <ExpandLessIcon className="expandLess" /> : <ExpandMoreIcon className="expandMore"/>
  }
  renderCollaborateIcon() {
    return this.state.collaborateDiv ? <ExpandLessIcon className="expandLess" /> : <ExpandMoreIcon className="expandMore" />
  }
  renderCopyStatusFun() {
    return this.renderCopyStatus() && <Typography className="isNoErrorTextStyle">{this.renderCopyStatus()}</Typography>
  }
  getButtonClass() {
    return this.state.shareData?.length === 0 ? 'opacity-05' : '';
}

  renderProfileSection = (hideClass: string) => {
    const { attributes } = this.state.getProfile ?? {}
    const profileImage = returnTruthyString(attributes?.photo)
    const firstName = returnTruthyString(attributes?.first_name).trim()
    const lastName = returnTruthyString(attributes?.last_name).trim()
    const fullName = !!hideClass ? firstName + " " + lastName[0] :firstName + " " + lastName
    const avatarName = returnTruthyString(firstName[0]) + returnTruthyString(lastName[0])
    const userHeadline = returnTruthyString(attributes?.headline).trim()
    const seniorityLevel = returnTruthyString(attributes?.seniority_level).trim()
    const countyName = returnTruthyString(attributes?.county?.name).trim()
    const countryName = returnTruthyString(attributes?.country?.name).trim()
    const location = checkCondition(Boolean(countyName), countyName + ", " + countryName, countryName) as string
    const preferedWorkType = (returnTruthyArray(attributes?.work) as string[]).join("   |   ")
    const profession = (returnTruthyArray(attributes?.profession) as string[]).join("   |   ")
    const isLiked = this.state.allLikes?.some(
      (like: any) =>like.attributes.likeable_id === attributes?.id );


    const isSavedProfile = this.state.allSavedProfiles?.some(
      (like: any) =>like.attributes.favouriteable_id === attributes?.id)
    
    
    return (
      <>
        <ProfileWrapper  data-testid="ProfileDiv">
          <Box>
            <ProfileAvatar src={profileImage}>
              {avatarName}
            </ProfileAvatar>
          </Box>
          <ProfileDetailWrapper>
            <NameEditBox>
              <Typography style={webStyle.userName}>{fullName}</Typography>
              <EditIcon data-testid="user-profile-edit" id="profile-edit" onClick={this.handleEditDialoug} className={`editIcon ${hideClass}`} />
            </NameEditBox>
            <ProfileHeadline noWrap style={{ marginTop: "18px" }}>
              {userHeadline}
            </ProfileHeadline>
            <Box style={{ marginTop: "28px" }}>
              <CustomHRBox><CustomHR /></CustomHRBox>
              <LevelLocationBox>
                <LeftBox id="level-box">
                  <ProfileLabel>Level:</ProfileLabel>
                  <ProfileLabelValue style={{ marginLeft: "13px" }}>{seniorityLevel}</ProfileLabelValue>
                </LeftBox>
                <RightBox id="location-box">
                  <ProfileLabel>Location:</ProfileLabel>
                  <LocationOnIcon style={webStyle.locationIcon} />
                  <ProfileLabelValue>{location}</ProfileLabelValue>
                </RightBox>
              </LevelLocationBox>
              <CustomHRBox><CustomHR /></CustomHRBox>
              <LevelLocationBox>
                <LeftBox id="type-box">
                  <ProfileLabel>Type:</ProfileLabel>
                  <ProfileLabelValue style={{ marginLeft: "15px" }}>{preferedWorkType}</ProfileLabelValue>
                </LeftBox>
                <RightBox id="profession-box">
                  <ProfileLabel>Profession:</ProfileLabel>
                  <ProfileLabelValue style={{ marginLeft: "18px" }}>{profession}</ProfileLabelValue>
                </RightBox>
              </LevelLocationBox>
              <CustomHRBox><CustomHR /></CustomHRBox>
            </Box>
            {!!hideClass ? <div className="detailsRow">
            <img 
            src={isLiked ? dislikeImage : likeImage} 
            data-test-id="profileLikeIcon"
            onClick={() => {
              this.profileLike()
            }} className="actionIcons" />
            <div className="actionIcons">
                <ChatBubbleOutlineIcon style={{display:"none"}}/>
                <ShareIcon data-test-id="shareIcon" onClick={(event: React.MouseEvent<SVGSVGElement | HTMLImageElement>) => {
                  this.openPopUp(event, this.state.profileId, "profile")
                  }}/>
                {isSavedProfile ? <BookmarkIcon data-test-id="removeFavIcon" onClick={() =>{
                   this.handleAddFavouriteProfile(!isSavedProfile)
                  }} /> :<BookmarkBorderIcon data-test-id="addFavIcon" onClick={() => {
                    this.handleAddFavouriteProfile(true)
                  }} />}
            </div>
            </div>: <></>}
          </ProfileDetailWrapper>
          <EditProfileModal data-test-id="EditProfileModal" handleAutoComplateChange={this.handleAutoComplateChange} handleChangeData={this.handleChange} handleEditDialougClose={this.handleEditDialougClose} getCountry={this.getCountry} getCityArray={this.getCityArray} getStateArray={this.getStateArray} state={this.state} handleEditDialoug={this.handleEditDialoug} UpdateProfile={this.UpdateProfile} handleImageChange={this.handleImageChange} handleSaveModalOpen={this.handleClickOpenSaveModal} handleSaveModalClose={this.handleCloseSaveModal} handleDataSet={this.handleDataSet} modalCheck={this.handleEditDialougClose}/>
        </ProfileWrapper>
      </>
    )
  }

  renderAboutSection = (hideClass: string) => {
    const { attributes } = this.state.getProfile ?? {}
    const firstName = returnTruthyString(attributes?.first_name).trim()
    const lastName = returnTruthyString(attributes?.last_name).trim()
    const displayName = firstName || lastName
    const description = returnTruthyString(attributes?.description)
    const skillsArray = returnTruthyArray(attributes?.skill) as string[]
    return (
      <AboutWrapper data-test-id="aboutSection">
        <div style={webStyle.userDescInnerDiv}>
          <Typography style={webStyle.userDescName}>{`About ${displayName}`}</Typography>
          <EditIcon onClick={this.handleEditAbout} id="edit-about-profile" className={`editIcon ${hideClass}`} />
        </div>
        <DescriptionWrapper>
          <span style={webStyle.describation}>
            {description}
          </span>
        </DescriptionWrapper>
        <EditAbout state={this.state} handleEditAbout={this.handleEditAbout} editAboutMe={this.editAboutMe} />
        <HRwrapper> <Typography style={webStyle.borderDivSize} /></HRwrapper>
        <div style={webStyle.skillDivv}>
          <Typography style={webStyle.skills}>Skills</Typography>
          <div style={webStyle.skillButtonDiv}>
            {skillsArray.map((item, index) => {
              return <Button key={index} variant="outlined" style={webStyle.skillsButtons}>
                {item}
              </Button>
            })}

          </div>
        </div>
      </AboutWrapper>
    )
  }

  renderExperienceSection = (hideClass: string) => {

    const getCompanyName = (companyName: ICompanyName | string) => {
      if(typeof companyName === "string"){
        return companyName
      }else if(companyName?.data?.length > 0){
        return companyName?.data[0]?.attributes?.name;
      }else{
        return ""
      }
    }

    const getCompanyLogo = (companyName: ICompanyName | string) => {
      if(typeof companyName !== "string"  && companyName?.data?.length > 0){
        return companyName?.data[0]?.attributes?.logo;
      }else{
        return ""
      }
    }

    return (
      <>
      <SubSectionWrapper data-testid="ExperienceDiv">
        <SubSectionHeading>
          <SubSectionTitle>Experience</SubSectionTitle>
          <AddIcon data-test-id="add-experience-btn" id="add-experience" className={hideClass} style={webStyle.addIconStyle} onClick={this.handleExperiance} />
        </SubSectionHeading>
        <SubSectionList>
        {this.state.getExperience.map((expItem, index) => {
          const companyImage = returnTruthyString(getCompanyLogo(expItem.attributes.company_name))
          const companyName = returnTruthyString(getCompanyName(expItem.attributes.company_name))
          const professionData = (returnTruthyArray(expItem.attributes.profession) as string[]).join(" | ")
          const description = returnTruthyString(expItem.attributes.description)
          const skillsArray = returnTruthyArray(expItem?.attributes?.skills) as string[]
          const expYearFrom = returnTruthyString(expItem.attributes.experience_from)
          const expYearTo = returnTruthyString(expItem.attributes.experience_to)
          const yearrange = returnTruthyString(formatYearRange(expYearFrom, expYearTo));
          const months = returnTruthyString(this.calculateMonths(expYearFrom, expYearTo));
          const countryDate = concatArrayOfstring(yearrange, months);
          return (
              <SubWrapper>
                  <ExperienceAvatar src={companyImage}>
                    {returnTruthyString(companyName[0])}
                  </ExperienceAvatar>
                <div style={webStyle.mainDataContainer}>
                  <div className="expeNameDiv">
                    <Typography style={webStyle.tvr}>{companyName}</Typography>
                  </div>
                  <div>
                    <Typography style={webStyle.profileTitle}>
                      {professionData}
                    </Typography>

                    <div className="titleContainer">
                      <Typography className="countryDatetypo">{countryDate} </Typography>
                      <div className="location">
                      <LocationOnIcon className="locationContainer" />
                      <Typography className="countryDatetypo">{expItem?.attributes?.country}</Typography>
                      </div>
                    </div>
                    <div style={webStyle.detailDataContainer}>
                      <Typography className="summarisedData">
                        {description}
                      </Typography>
                      <div style={webStyle.skillButtonDiv}>
                        {skillsArray.map((item, index) => {
                          return <SkillLabel key={index} variant="outlined">{item}</SkillLabel>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <StyledEditIcon
                  onClick={() => this.handleEditingExp(expItem)}
                  id="edit-icon"
                  data-testid="edit-experience-btn"
                  className={hideClass}
                />
              </SubWrapper>
          )
        })}
        </SubSectionList>
      </SubSectionWrapper>
        <CustomModal
          data-testid="delete-exp-modal"
          open={this.state.openDeleteExpModal}
          handleCloseModal={this.closeDeleteModal}
          leftButtonTitle="Cancel"
          rightButtonTitle="Delete"
          description="Are you sure you want to delete this experience?"
          handleLeftBtn={this.closeDeleteModal}
          handleRightBtn={this.deleteExperience}
        />
      </>
    )
  }

  renderEducationSection = (hideClass: string) => {
    const getEducationName = (educationName: IInstitutionRecord | string): string => {
      if (typeof educationName === "string") {
          return educationName;
      } else if (educationName?.data?.length > 0) {
          return educationName.data[0].attributes.name;
      } else {
          return "";
      }
  };
  
    const getEducationLogo = (educationName: IInstitutionRecord | string) => {
      if(typeof educationName !== "string"  && educationName?.data?.length > 0){
        return educationName?.data[0]?.attributes?.logo;
      }else{
        return ""
      }
    }
    return (
      <>
      <SubSectionWrapper>
        <SubSectionHeading>
          <SubSectionTitle>Education</SubSectionTitle>
          <AddIcon style={webStyle.addIconStyle} onClick={this.handleEducation} id="add-education" className={hideClass} />
        </SubSectionHeading>
        <SubSectionList>
        {this.state.getEducation?.map((item, index) => {
          const instituteImage = returnTruthyString(getEducationLogo(item.attributes.institution))
          const instituteName = returnTruthyString(getEducationName(item.attributes.institution))
          const fieldOfStudy = returnTruthyString(item.attributes.field_of_study)
          const studyCountry = returnTruthyString(item.attributes.country)
          const studyDiscription = returnTruthyString(item.attributes.description)
          const skillsArray = returnTruthyArray(item.attributes.skills) as string[]
          const yearFrom = returnTruthyString(item.attributes.year_from)
          const yearTo = returnTruthyString(item.attributes.year_to)
          const yearrange = returnTruthyString(formatYearRange(yearFrom, yearTo));
          const months = this.calculateMonths(item?.attributes.year_from, item.attributes.year_to);
          const countryDate = concatArrayOfstring(yearrange, months);
          return (
              <SubWrapper id="education-main" key={index} data-testid="EducationDiv">
                  <ExperienceAvatar src={instituteImage}>
                    {returnTruthyString(instituteName[0])}
                  </ExperienceAvatar>
                <div style={webStyle.mainDataContainer}>
                  <div className="expeNameDiv">
                    <Typography style={webStyle.tvr}>
                      {instituteName}
                    </Typography>
                  </div>
                  <div>
                    <Typography style={webStyle.profileTitle}>
                      {fieldOfStudy}
                    </Typography>
                    <div className="titleContainer">
                      <Typography className="countryDatetypo">{countryDate}</Typography>
                      <div className="location">
                        <LocationOnIcon className="locationContainer" />
                        <Typography className="countryDatetypo">{studyCountry}</Typography>
                      </div>
                    </div>
                    <div style={webStyle.detailDataContainer}>
                      <Typography className="summarisedData">
                        {studyDiscription}
                      </Typography>
                      <div style={webStyle.skillButtonDiv} id="education-skill">
                        {skillsArray.map((item, index) => {
                          return <SkillLabel key={index} variant="outlined">{item}</SkillLabel>
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                  <StyledEditIcon
                    onClick={() => this.editEducationPopUp(item)}
                    data-testid="edit-education-btn"
                    id="edit-icon-id"
                    className={hideClass}
                  />
              </SubWrapper>
          )
        })}
        </SubSectionList>
      </SubSectionWrapper>

        <CustomModal
          data-testid="delete-edu-modal"
          open={this.state.openDeleteEduModal}
          handleCloseModal={this.closeDeleteModal}
          leftButtonTitle="Cancel"
          rightButtonTitle="Delete"
          description="Are you sure you want to delete this education?"
          handleLeftBtn={this.closeDeleteModal}
          handleRightBtn={this.deleteEducation}
        />
      </>
    )
  }

  checkMediaAvailable = (socialMedia: { [key: string]: string }) => {
    return Object.values(socialMedia).some(value => value !== "");
  }

  renderSocialMediaSection = (hideClass: string) => {
    const socialMedia = this.state.getProfile?.attributes?.media ?? {}
    const LinkArray = Object.entries(socialMedia as { [key: string]: string })
    return (
      <SubSectionWrapper className="socialMediaIcons" data-testid="SocialMediaDiv">
        <SubSectionHeading>
          <SubSectionTitle>Socials</SubSectionTitle>
         {!this.checkMediaAvailable(socialMedia) && <AddIcon id="add-social" className={hideClass} onClick={this.handleEditSocial} style={webStyle.addIconStyle} />}
        </SubSectionHeading>
        <SocialMediaContainer>
          <StyledSocialIcons>
            {LinkArray.map(([platform, url]: [string, string]) =>url && <img data-test-id="social-media-img" src={socialMediaIcons[platform]} alt="platform" onClick={() => this.handleNewLinkOpen(url)} />)}
          </StyledSocialIcons>
          {this.checkMediaAvailable(socialMedia) && <EditSocialBtn onClick={this.handleSocialOpenExp} className={hideClass} />}
        </SocialMediaContainer>
      </SubSectionWrapper>
    )
  }

  getTabStyles = (selectedTab: string) => {
    let buttonStyle = "nonSelectedDiv";
    if(this.state.activeTab === selectedTab){
      buttonStyle = "selectedDiv";
    }
    return {
      buttonStyle
    }
  }

  renderPostFile = (img_url : string, postId: string, isConfidential: boolean) => {
    const urlType = isImageOrVideo(img_url);
    if(isConfidential){
      return(
        <div data-test-id="mediaImage" className="imageWrapper">
              <div className="restrictedDivMain">
                <div className="restrictedDiv">
                  <img
                    className="restrictedImg"
                    data-test-id="restrictedImgDiv"
                    src={restricted}
                  />
                  <p className="confidentialText">Confidential</p>
                </div>
                <p className="confidentialPara">
                  You have to be a collaborator to view this
                  content.
                </p>
          </div>
          {urlType === "image" ?
            <img height="100%" width="100%" data-test-id={`image-${postId}`} src={img_url} className="confidential_img" />
            :
            <video
              src={img_url}
              controls
              className="confidential_img"
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          }
          </div>
      )
    }
    if(urlType === "image"){
      return <img height={300} width={400} data-test-id={`image-${postId}`} src={img_url} className={`image ${isConfidential ? "confidential_img" : "collabImg"}`} />
    }else{
      return <video
      src={img_url}
      controls
      className={`image ${isConfidential ? "confidential_img" : "collabImg"}`}
      style={{
        height: '100%',
        width: '100%',
        objectFit: 'cover',
      }}
    />
    }
  }

  renderGalleryDiv = (isOtherUser: boolean)=>{
    return(
      <>
      {this.sortGalleryData().map((data: any, index) =>
        {
          let isConfidential = data.confidential && (sessionStorage.getItem("profileId") !== this.state.profileId);
          return <Grid
          ref={this.postDetailsRef}
          item
          lg={4}
          sm={4}
          xs={12}
          className="collaborationGridItem"
          data-test-id={`grid-item-${index}`}
          style={{ position: 'relative', display: 'inline-block' }}
          key={index}
          onClick={()=>this.handleNavigateToPostDetailsPage(data.post_id, isConfidential)}
          draggable={!isOtherUser} 
          onDragStart={(e: React.DragEvent<HTMLDivElement>)=> this.onDragStart(e, index)}
          onDragOver={this.onDragOver}
          onDrop={(e: React.DragEvent<HTMLDivElement>)=> this.onDrop(e, index)}
        >
          <div className="collaborationGridItemInside">
          {this.renderPostFile(data.image_url, data.post_id , isConfidential)}
            <div className="collabImgHover">
              {!isConfidential ? data.project_title || "Project Name" : ""}
            </div>
            {this.renderLikeSaveShare(isConfidential, index, data.is_favourite, data.is_like, data.id, data.post_id, false)}
          </div>
        </Grid>
        }
      )}
      </>
    )
  }


  renderVisibilityOption = () => {
    const visibilityOption = this.state.getProfile?.attributes?.visibility_option;
    if(visibilityOption === "nobody"){
      return "Nobody can see this."
    }else if(visibilityOption === "everyone except my current employer"){
      return "Everyone can see this except your current employer."
    }else{
      return "Anyone can see this."
    }
  };

  renderAvailabilitySection = () => {
    const visibility = this.state.getProfile?.attributes?.visibility_option;
    return <SubSectionWrapper>
    <SubSectionHeading>
      <SubSectionTitle>Availability</SubSectionTitle>
      <AvailabilityChange style={{ textTransform: 'capitalize' }} >
          <span>
            <span style={{ textTransform: "uppercase" }}>
              {visibility?.charAt(0)}
            </span>
            <span style={{ textTransform: "lowercase" }}>
              {visibility?.slice(1)} can see this
            </span>
          </span>
        <span style={webStyle.changeHyperLink} onClick={this.navigateToAvailability} data-testid="change-link">
          Change
        </span>
      </AvailabilityChange>
    </SubSectionHeading>
    <AvailabilityWrapper data-test-id="availabilityWrapper">                 
      <AvailabilitySubWrapper>
        <AvailabilitySubText>
          <AvailabilityHeader>Open for opportunities.</AvailabilityHeader>
          <AvailabilitySubHeader>Are you interested in work either now or in the future?</AvailabilitySubHeader>
        </AvailabilitySubText>
        <Box>
          <IOSSwitch style={{ cursor: "pointer" }} onChange={this.handleAvalibility} checked={this.state.avalibility} data-testId="avalibility" />
        </Box>
      </AvailabilitySubWrapper>
      <AvailabilityChangeSM>
        Everyone can see this.
        <span onClick={this.navigateToAvailability} data-testid="change-link-sm">
          Change
        </span>
      </AvailabilityChangeSM>
      {this.state.avalibility && <>

        <div style={webStyle.unavaUpperLineDiv}>
          <Typography
            style={webStyle.unavaLine}
          />
        </div>
        <div>
          <StyledUnavailabilitySection>
            <Typography style={webStyle.unavailability}>
              Unavailable:
            </Typography>
            {!this.state.isEditUnavailabilityOpen && <StyledEditIcon
              onClick={this.handleUnavailabilityOption}
              id="edit-icon-unavailability"
              data-test-id="edit-unavailability-btn"
            />}
          </StyledUnavailabilitySection>
         
          {this.state.isEditUnavailabilityOpen 
          ? <>
          {this.state.selectedDates.length > 0 ? <div
            style={webStyle.unavaBorderDiv}
          >
            <Typography
              style={webStyle.unavaLine}
            />
          </div>: <></>}
          {this.state.selectedDates.map((dates, gridIndex: number) => {
            return <div style={webStyle.calenderInputDiv}>
              <div className="calInDiv">
                <Grid item xs={12} lg={6} md={6}>
                  <div className="date-picker-container">
                    <CustomDatePicker
                      dateFormat={'dd/MM/yyyy'}
                      id="date-select"
                      data-test-id='unavailablefrom'
                      placeholderText="Unavailable from"
                      className="datepicker"
                      selected={this.parseDate(dates.from)}
                      onChange={(selectedDate:Date) => {
                        this.handleDateChange(selectedDate, 'from', gridIndex)
                        this.handleDatePickerClose('from', gridIndex)
                      }}
                      onFocus={() => this.handleDatePickerOpen('from', gridIndex)}
                      minDate={today}
                      maxDate={this.parseDate(dates.to)}
                      onClickOutside={() => this.handleDatePickerClose('from', gridIndex)}
                      open={this.state.openDatePickers[gridIndex]?.from}
                      readOnly
                    />
                      <img
                      id="calender-img"
                      height={13}
                      width={13}
                      data-test-id='fromCalendar'
                      src={calendarIcon}
                      alt="Date Icon"
                      style={webStyle.calenderIcon as React.CSSProperties}
                      onClick={() => this.handleDatePickerOpen('from', gridIndex)}
                    />
                  </div>
                  <div className="date-error">{this.getErrors("from", gridIndex)}</div>
                </Grid>
                <Grid item xs={12} lg={6} md={6}>
                  <div className="date-picker-container">
                    <CustomDatePicker
                      dateFormat={'dd/MM/yyyy'}
                      id="date-select1"
                      data-test-id='unavailableto'
                      placeholderText="Unavailable to"
                      selected={this.parseDate(dates.to)}
                      onChange={(selectedDate:Date) => {
                        this.handleDateChange(selectedDate, 'to', gridIndex);
                        this.handleDatePickerClose('to', gridIndex);
                      }}
                      minDate={this.parseDate(dates.from) || null}
                      disabled={!dates.from}
                      onClickOutside={() => this.handleDatePickerClose('to', gridIndex)}
                      open={this.state.openDatePickers[gridIndex]?.to}
                      onFocus={() => this.handleDatePickerOpen('to', gridIndex)}
                      readOnly
                    />
                    <img
                      id="calender-img1"
                      height={13}
                      width={13}
                      data-test-id='toCalendar'
                      src={calendarIcon}
                      alt="Date Icon"
                      style={webStyle.calenderIcon as React.CSSProperties}
                      onClick={() => this.handleDatePickerOpen('to', gridIndex)}
                    />
                  </div>
                  <div className="date-error">{this.getErrors("to", gridIndex)}</div>
                </Grid>
              </div>
              <div
                style={webStyle.avalabiCloseIconDiv}
              >
                <CloseIcon
                  style={webStyle.availabilityCloseIcon}
                  data-test-id="handleDateInputRemove"
                  onClick={()=>this.handleDateInputRemove(gridIndex)}
                />
              </div>
            </div>
          })}
          <div style={webStyle.avalibiInputBoxDiv}>
            <Typography
              style={webStyle.availabilityInputBox}
            />
          </div>
          <div style={webStyle.availabilityAddIconMargin}>
            <AddIcon style={webStyle.availabilityAddIconSize} onClick={this.addNewDatePicker} id="add-date-picker"/>
          </div>
          </> 
          :<div className="unavailableDates">
            {this.state.selectedDates.map((dates) => <span>
            {`${moment(dates.from).format('Do MMMM YYYY')} to ${moment(dates.to).format('Do MMMM YYYY')}`}
            </span>)}
          </div>}
          <div>
            <Typography style={webStyle.availabilityInputBox} />
          </div>
        </div>
      </>}
    </AvailabilityWrapper>
    {this.state.isEditUnavailabilityOpen && this.state.avalibility && <div style={webStyle.saveButtonDiv}>
      <Button  style={{
        ...webStyle.buttonSave, 
        ...(this.handleDisableSaveButton() ? webStyle.buttonSaveDisabled : webStyle.buttonSaveEnabled)
        }}  
        className="blackBackgroundTransition"
          disabled={this.handleDisableSaveButton()}  
          onClick={this.updateAvalibility} id="save-avalibility" data-test-id="updateAvalibility">
         <Typography className="textBtnCreate">Save</Typography>
      </Button>
    </div>}
  </SubSectionWrapper>
  }

  renderCreativeprofile = () =>{
    const isOtherUser = this.state.profileId !== sessionStorage.getItem("profileId");
    const hideClass = isOtherUser ? "hideEdit": "";
    return(
    <StyledProfileInfo>
      {this.renderProfileSection(hideClass)}
      <ProfileBelowWrapper>
      <ButtonGroupWrapper>
        <Button id="info-btn" data-test-id="InfoDiv" onClick={() => {
          this.handleTabChange('info')
        }} className={this.getTabStyles("info").buttonStyle} >
          <Typography>Info</Typography>
        </Button>
        <Button data-test-id="workDiv" onClick={() => {
          this.handleTabChange('work')
        }} className={this.getTabStyles("work").buttonStyle}>
          <Typography>Work </Typography>
        </Button>
      </ButtonGroupWrapper>
      {this.state.activeTab === "info" ? <>
        {this.renderAboutSection(hideClass)}
        {isOtherUser? <></>: this.renderAvailabilitySection()}
        {this.renderExperienceSection(hideClass)}
        <EditExperience getCountry={this.getCountry} getCallApi={this.getCallApi} editSingleExperience={this.editSingleExperience} state={this.state} editExperienceUpdate={this.editExperienceUpdate} handleExperianceClose={this.handleExperianceClose}
        openDeleteExperience={this.openDeleteExperience} data-testid="edit-exp-component"
        />
        {this.renderEducationSection(hideClass)}
        <EditEducation handleEducationClose={this.handleEducationClose} getApiCalled={this.getApiCalled} editSingleEducation={this.editSingleEducation} addEducatioin={this.addEducatioin} state={this.state} 
          openDeleteEducation={this.openDeleteEducation}/>
        {this.renderSocialMediaSection(hideClass)}
        <EditSocial state={this.state} handleEditSocial={this.handleEditSocial} onValueSubmit={this.editSocial} />
      </> : <></>}
      {this.state.activeTab === 'work' && <div>
        {this.renderButtonGroup(hideClass)}
        {this.state.openPostCreation?(
        <PostCreationWeb onCreateNewPost={this.handleCreatePost} onClickClose={this.handlePostCreation}  navigation={this.props.navigation} isEdited={false} profileImage={this.state.getProfile.attributes.photo}/>
        ):<></>}
        <div style={{
          borderBottom: "1px solid #DFDFDF",
          width: "100%",
          marginTop: "61px",
        }}></div>
        <>
          <CollaborationHeader data-test-id="myWorkTab" onClick={() => { this.handleOpenWorkDiv() }} style={webStyle.myWorkBtn}>
            <Typography>My Work</Typography>
            {this.renderWorkDivIcon()}
          </CollaborationHeader>
            {this.state.myWorkDiv 
            && 
                <PostWrapper 
                data-test-id="myWorkTabWrapper"
                container
                spacing={this.state.spacing as GridSpacing}
                style={{
                  display: 'flex',
                  flexWrap: "wrap"
                }}
                >
                  {this.renderGalleryDiv(isOtherUser)}
                </PostWrapper>
              }
          <div style={{ ...webStyle.borderOr, marginTop: 30 }}></div>
        </>
        <>
          {this.state.collaboratedPosts.length !== 0 && (
            <>
              <CollaborationHeader data-test-id="collabDiv" onClick={() => { this.handleOpenCollaborate() }} style={webStyle.myWorkBtn}>
                <Typography>Collaborations</Typography>
                {this.renderCollaborateIcon()}
              </CollaborationHeader>
              {this.state.collaborateDiv && (
                <>
                  <PostWrapper data-test-id="collabDivWrapper" container 
                  spacing={this.state.spacing as GridSpacing}
                  style={{
                    display: 'flex',
                    flexWrap: "wrap"
                  }}>
                  {this.state.collaboratedPosts.map((data: any, index) =>{
                    let isConfidential = data.confidential && (sessionStorage.getItem("profileId") !== this.state.profileId);
                    return  (
                      <Grid
                        ref={this.postDetailsRef}
                        className={isConfidential ? "confCollaborationGridItem": "collaborationGridItem"}
                        data-test-id={`collab-grid-item-${index}`}
                        style={{ position: 'relative', display: 'inline-block' }}
                        key={index}
                        onClick={()=>this.handleNavigateToPostDetailsPage(data.post_id, isConfidential)}
                        item
                        lg={4}
                        sm={4}
                        xs={12}
                      >
                        <div className="collaborationGridItemInside">
                          {this.renderPostFile(data.image_url, data.post_id,isConfidential)}
                          {!isConfidential &&
                          <div className="collabImgHover">
                            {data.project_title || "Project Name"}
                          </div>}
                        {this.renderLikeSaveShare(isConfidential, index, data.is_favourite, data.is_like, data.id, data.post_id, !isOtherUser)}
                        </div>
                      </Grid>
                    )
                  })}
                  <CommonDialog2
                    description={<span>Are you sure you want to remove yourself as a collaborator from this project?</span>}
                    open={this.state.deleteModal}
                    cancelText="Cancel"
                    successText="Yes, remove"
                    onCancelClick={this.handleDeleteModal}
                    onClose={this.handleDeleteModal}
                    onSuccessClick={this.handleDeleteCollaboratedPosts}
                  />
                  </PostWrapper>
                </>
              )}
            </>
          )}
          <Dialog
            fullWidth
            maxWidth="md"
            open={this.state.editSocial}
            onClose={this.handleEditDialoug}
            aria-describedby="alert-dialog-slide-description"
          >
            <div style={webStyle.experienceInnerDiv1}>
              <DialogTitle style={webStyle.dialougeHeading}>{"Add Socials"}</DialogTitle>
              <img onClick={this.handleEditSocial} style={{ width: "20px", paddingRight: "20px", cursor: "pointer" }} src={closeImage}></img>
            </div>
            <DialogContent >
              <DialogContentText id="alert-dialog-slide-description">
                <form noValidate autoComplete="off">
                  <Formik
                    initialValues={{
                      linkedIn: "",
                      instagram: "",
                      behance: "",
                      vimeo: "",
                      youtube: "",
                      tikTok: ""
                    }}
                    onSubmit={(values) => {

                    }}
                    validationSchema={{}}
                  >
                    {({
                      handleChange,
                      values,
                      handleSubmit,
                      errors,
                      touched,
                      setFieldValue,
                      isValid,
                      dirty,
                    }) => {
                      return (
                        <Form >
                          <div style={webStyle.InputFormik} >
                            <div style={webStyle.InputFormik} >
                            <img src={linkedin} alt="linkedin" />
                              <div style={{ width: "100px" }}>

                                <Typography style={webStyle.socialText}>Linkedin</Typography>
                              </div>
                            </div>
                            <div>
                              <TextField
                                placeholder="Please add your Profile URL here"
                                inputProps={{
                                  style: webStyle.placehholderStyle,
                                }}
                                className="input-label"
                                style={webStyle.socialInput}
                                onChange={handleChange("AllocateCredit")}
                                data-test-id={"txtInputProductId"}
                                value={values.linkedIn}
                                name="linkedIn"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                helperText={<ErrorMessage name="linkedIn" />}

                                required
                              />
                            </div>
                          </div>
                          <div style={webStyle.InputFormik} >
                            <div style={webStyle.InputFormik} >
                            <img src={instagram} alt="instagram" />
                              <div style={{ width: "100px" }}>

                                <Typography style={webStyle.socialText}>Instagram</Typography>
                              </div>
                            </div>
                            <div>
                              <TextField
                                inputProps={{
                                  style: webStyle.placehholderStyle,
                                }}
                                placeholder="Please add your Profile URL here"

                                className="input-label"
                                style={webStyle.socialInput}
                                onChange={handleChange("AllocateCredit")}
                                data-test-id={"txtInputProductId"}
                                value={values.instagram}
                                name="instagram"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                helperText={<ErrorMessage name="instagram" />}

                                required
                              />
                            </div>
                          </div>
                          <div style={webStyle.InputFormik} >
                            <div style={webStyle.InputFormik} >
                            <img src={behance} alt="behance" />
                              <div style={{ width: "100px" }}>

                                <Typography style={webStyle.socialText}>Behance</Typography>
                              </div>
                            </div>
                            <div>
                              <TextField
                                placeholder="Please add your Profile URL here"
                                inputProps={{
                                  style: webStyle.placehholderStyle,
                                }}
                                className="input-label"
                                style={webStyle.socialInput}
                                onChange={handleChange("AllocateCredit")}
                                data-test-id={"txtInputProductId"}
                                value={values.behance}
                                name="behance"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                helperText={<ErrorMessage name="behance" />}
                                required
                              />
                            </div>
                          </div>
                          <div style={webStyle.InputFormik} >
                            <div style={webStyle.InputFormik} >
                            <img src={viom} alt="viom" />
                              <div style={{ width: "100px" }}>
                                <Typography style={webStyle.socialText}>Vimeo</Typography>
                              </div>
                            </div>
                            <div>
                              <TextField
                                className="input-label"
                                placeholder="Please add your Profile URL here"
                                inputProps={{
                                  style: webStyle.placehholderStyle,
                                }}
                                style={webStyle.socialInput}
                                onChange={handleChange("AllocateCredit")}
                                data-test-id={"txtInputProductId"}
                                value={values.vimeo}
                                name="vimeo"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                helperText={<ErrorMessage name="vimeo" />}
                                required
                              />
                            </div>
                          </div>
                          <div style={webStyle.InputFormik} >
                            <div style={webStyle.InputFormik} >
                            <img src={yt} alt="yt" />
                              <div style={{ width: "100px" }}>

                                <Typography style={webStyle.socialText}>Youtube</Typography>
                              </div>
                            </div>
                            <div>
                              <TextField
                                className="input-label"
                                placeholder="Please add your Profile URL here"
                                inputProps={{
                                  style: webStyle.placehholderStyle,
                                }}
                                style={webStyle.socialInput}
                                onChange={handleChange("AllocateCredit")}
                                data-test-id={"txtInputProductId"}
                                value={values.youtube}
                                name="youtube"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                helperText={<ErrorMessage name="youtube" />}

                                required
                              />
                            </div>
                          </div>
                          <div style={webStyle.InputFormik} >
                            <div style={webStyle.InputFormik} >
                            <img src={tt} alt="tt" />
                              <div style={{ width: "100px" }}>
                                <Typography style={webStyle.socialText}>Tiktok</Typography>
                              </div>
                            </div>
                            <div>
                              <TextField
                                placeholder="Please add your Profile URL here"
                                inputProps={{
                                  style: webStyle.placehholderStyle,
                                }}
                                className="input-label"
                                style={webStyle.socialInput}
                                onChange={handleChange("AllocateCredit")}
                                data-test-id={"txtInputProductId"}
                                value={values.tikTok}
                                name="tikTok"
                                fullWidth
                                variant="outlined"
                                margin="dense"
                                helperText={<ErrorMessage name="tikTok" />}
                                required
                              />
                            </div>
                          </div>
                          <DialogActions>
                            <Button variant="contained" style={webStyle.buttonFilled} onClick={this.handleEditSocial}>Save</Button>
                          </DialogActions>
                        </Form>
                      );
                    }}
                  </Formik>
                </form>
              </DialogContentText>
            </DialogContent>
          </Dialog>
        </>
      </div>
      }
      </ProfileBelowWrapper>
    </StyledProfileInfo>
    )
  }

  renderMain = () => {
    const company = this.state.getProfile?.attributes;
    const id = company?.role?.id || 2;
      if(id === 2){
        return this.renderCreativeprofile()
      }else{
        return <ClientProfile navigation={undefined} id={""} />
      }
  }
  
  render() {
    // Customizable Area Start
   
    return (
      <ThemeProvider theme={theme}>
        <HeaderNavbar navigation={this.props.navigation} data-test-id="headerNavbar"/>
           {this.renderMain()}
           {this.state.isPostDetailsOpen && <Postdetails 
            postId={this.state.selectedPostId} 
            navigation={this.props.navigation} 
            onClosePostDetails={this.handleClosePostDetails}
            isOpen={this.state.isPostDetailsOpen}
            />}
           <ShareLinkDialog
              isOpen={this.state.openModel}
              onClose={this.handleClose}
              onClickChatButton={this.handleChat}
              shareLink={this.state.shareLink}
              isChatSend={this.state.msgSend}
           />       
            <ShareMenuDialog
              onClose={this.handleCloseChatModel}
              onChangeChatMessage={this.handlesendChatMessage}
              isOpen={this.state.isSendLinkToChatModalOpen}
              shareData={this.state.shareData}
              chatMessage={this.state.sendChatMessage}
              data-test-id="ShareMenuDialogID"
              onShareDataChange={this.handleshareDataChatChange}
              onClickBackButton={this.handleNavigateBack}
              onClickSendChat={this.handleSend}
              profiles={this.state.getAllData?.map((option:{attributes:{account_id:number, first_name: string, last_name: string, photo: string}}) => ({
                account_id:option.attributes.account_id,
                firstName: option.attributes.first_name,
                lastName:  getSurname(option.attributes.last_name, option.attributes.account_id.toString()),
                photo: option.attributes.photo,
              }))}
            /> 
      </ThemeProvider>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}
// Customizable Area Start 
const StyledSocialIcons = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
  flexWrap: "wrap",
  "& img":{
    filter: "grayscale(100%)",
    cursor: "pointer"
  }
})
export const webStyle = {
  headingText:{width:"100%", textAlign:"center",
  fontSize: '24px',
  fontWeight: 500,color:"#010101"},
  modelHeadingstyle:{display:"flex",alignItems:"center",justifyContent:"center"},
  modelHeadingstyleChatModel:{display:"flex",alignItems:"center",justifyContent:"space-between"},
  modalStyle: {
    width: 450,
    height: 150,
    bgcolor: 'white',
    border: 'none',
    p: 4,
    zIndex: 10,
    boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.25)"
  },
  noDataDiv: {
    fontFamily: 'Silika-Medium',
    height: 200,
    fontSize: '16px',
    margin: 'auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  outerMostContainerSize: {
    height: "100%",
    width: "100%",
    marginTop: "60px",
  },
  profileinfoDiv: {
    height: "189px",
    maxWidth: "1093px",
    display: "flex",
    gap: "47px",
    margin: "auto",
  },
  myWorkBtn: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    cursor: 'pointer'
  },
  activeTabWork: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: "flex-end"
  },
  borderOr: {
    borderBottom: "1px solid #DFDFDF",
    width: "100%",
    marginTop: 90,
  },
  profileimgDiv: {
    maxWidth: "189px",
  },
  profileImg: {
    width: "200px",
    height: "200px",
    borderRadius: "100px",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
  },
  userNameDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  userName: {
    fontSize: "26px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "normal",
    fontStyle: "normal",
  },
  editIcon: {
    height: "24px",
    width: "24px",
    color: "#9B9B9D",
    cursor: "pointer"
  } as React.CSSProperties,
  describtion: {
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    marginTop: "18px",
    width: "80%",
    height: "100px",
  },
  borderDiv: {
    marginTop: "15px",
  },
  borderBottom: {
    borderBottom: "1px solid",
    color: "#DFDFDF",
    width: "100%",
  },
  location: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    color: "#9B9B9D",
    alignSelf: "center",
  },
  locationIcon: {
    height: "20px",
    width: "20px",
    flexShrink: 0,
    marginLeft: "7px",
    marginRight: "4px",
  },
  locationName: {
    fontSize: "12px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    lineHeight: "140.625%",
    fontStyle: "normal",
    alignSelf: "center",
    marginLeft: "13px",
  },
  info: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",


  },
  info1: {
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "40px",
    letterSpacing: "-0.15px",
    textAlign: "center" as "center",
    color: "#9B9B9D",
  },
  workDiv: {
    width: "50%",
    alignSelf: "center",
    border: 'none',
    borderBottom: "2px solid #DFDFDF",
  },
  infoDiv: {
    width: "50%",
    alignItems: "center",
    border: 'none',
    borderBottom: "2px solid #000",
  },
  userDescDiv: {
    marginTop: "80px",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
    padding: "45px 42.85px 39px 40.7px",
  } as React.CSSProperties,
  userDescInnerDiv: {
    display: "flex",
    justifyContent: "space-between",
    flexShrink: 1,
    width: "100%",
  },
  userDescName: {
    fontSize: "20px",
    fontWeight: 500,
    fontFamily: "Silika-Medium",
    lineHeight: "24.13px",
  } as React.CSSProperties,
  descDiv: {
    marginTop: "13px",
    marginRight: "23.57px",
  } as React.CSSProperties,
  describation: {
    fontSize: "16px",
    fontFamily: "Silika-ExtraLight",
    fontWeight: 200,
    lineHeight: "19.29px",
    whiteSpace: "pre-wrap",
    color: "#000000"
  } as React.CSSProperties,
  skillDivv: {
    display: "flex",
    flexDirection: "column" as "column",
    marginTop: "18px"
  } as React.CSSProperties,
  skills: {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
  },
  buttonDiv: {
    display: "flex",
    flexWrap: "wrap",
    boxSizing: "content-box",
    gap: "8px",
    marginTop: "19px",
  },
  skillButtonDiv: {
    display: "flex",
    flexWrap: "wrap" as "wrap",
    boxSizing: "content-box" as "content-box",
    gap: "8px",
    marginTop: "19px",

  },
  skillsButtons: {
    height: "33px",
    maxWidth: "auto",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14.48px",
    textAlign: "center" as "center",
    borderRadius: "2px",
    color: "#000",
    flexShrink: 0,
    whiteSpace: "nowrap" as "nowrap",
    textTransform: "none" as "none",
  },
  blenderSkillsButton: {
    height: "33px",
    width: "134px",
    fontFamily: "Silika-Medium",
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "14.48px",
    textAlign: "center" as "center",
    borderRadius: "2px",
    color: "#000",
    flexShrink: 0,
    whiteSpace: "nowrap" as "nowrap",
    textTransform: "none" as "none",
    marginLeft: "8px",
    marginRight: "8px",

  },
  availabilityDiv: {
    margin: "auto",
    marginTop: "50px",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
  },
  availabilityInnerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#0D0C22",
    padding: "21px 45px 21px 40px",
    borderRadius: "4px, 4px, 0px, 0px",
  } as React.CSSProperties,
  availability: {
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19.3px",
    color: "#FFF",
  },
  everyoneCanSee: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.48px",
    color: "#FFF",
    fontFamily: "Silika-Medium",
  } as React.CSSProperties,
  changeHyperLink: {
    color: "#FFF",
    fontWeight: 500,
    fontSize: "13px",
    marginLeft:'4px',
    textDecoration:"underline",
    cursor: "pointer"
  } as React.CSSProperties,
  userDescOppDiv: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "32.99px",
  },
  userDescOppName: {
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24.13px",
    letterSpacing: "-0.15px",
  },
  userDescSubName: {
    fontSize: "15px",
    lineHeight: "normal",
    letterSpacing: "0.7px",
    alignSelf: "center",
    marginLeft: "8px",
  },
  unavailability: {
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
  },
  buttonSave: {
    height: "48px",
    width: "104px",
  },
  buttonSaveEnabled : {
    color: "#FFF",
    background: "#0D0C22",
  },
  buttonSaveDisabled : {
    color: "#FFFFFF",
    background: "#DFDFDF"
  },
  saveButtonDiv: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: "32px",
    padding: "16px 40px 27px 0px",
    alignItems: "center",
    gap: "16px",
  },
  avalibiInputBoxDiv: {
    marginTop: "12px"
  },
  availabilityInputBox: {
    borderBottom: "1px solid #D9D9D9",
    width: "100%"
  },
  availabilityAddIconMargin: {
    marginTop: "12px",
    marginBottom: "12px"
  },
  availabilityAddIconSize: {
    height: "36px",
    width: "36px",
    cursor:'pointer'
  },
  avalabiCloseIconDiv: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    width: "30%",
  },
  availabilityCloseIcon: {
    height: "24px",
    width: "24px",
    flexShrink: 0,
    color: "#9B9B9D",
    cursor:'pointer'
  },
  borderDivMargin: {
    marginTop: "96px"
  } as React.CSSProperties,
  borderDivSize: {
    borderBottom: "1px solid #D9D9D9",
    width: "100%"
  },
  calenderInputDiv: {
    display: "inline-flex", width: "100%",
    marginBottom:"2%"
  },
  calInput: {
    padding: "4px 8px 5px 8px",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "2px",
  },
  unavaUpperLineDiv: {
    marginTop: "28px",
    marginBottom: "28px"
  },
  unavaLine: {
    borderBottom: "1px solid #D9D9D9",
    width: "100%",
  },
  userDisceDiv: {
    margin: "auto",
    padding: "20px"
  },
  oppDiv: {
    display: "flex"
  },
  experienceDiv: {
    marginTop: "80px",
    border: "1px solid #D9D9D9",
    borderRadius: "4px",
  } as React.CSSProperties,
  experienceInnerDiv: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    background: "#0D0C22",
    padding: "16px 44px 15px 38px",
    borderRadius: "4px, 4px, 0px, 0px",
  } as React.CSSProperties,
  expeTitle: {
    fontSize: "16px",
    fontWeight: 500,
    fontFamily: "Silika-Medium",
    lineHeight: "19.3px",
    color: "#FFF",
  },
  expDivPadding: {
    padding: "20px"
  },
  tvr: {
    color: "#000",
    fontFamily: "Silika-Medium",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "-0.15px",
  } as React.CSSProperties,
  expeEditIcon: {
    width: "24px",
    height: "24px",
    flexShrink: 0,
    color: "#9B9B9D",
    cursor:'pointer'
  },
  profileTitle: {
    color: "#000",
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
    marginTop: "12px",
  },
  addIconStyle: {
    color: "#FFF",
    height: "30px",
    width: "30px",
    cursor:'pointer'
  },
  educationMainContainerOuterDiv: {
    display: "flex",
    columnGap: "75px",
    margin: "20px 24px 0 17px",
  } as React.CSSProperties,
  imageTemplate: {
    height: "75px",
    width: "75px",
    fill: "#D9D9D9"
  },
  mainDataContainer: {
    width: "100%",
  },
  locationContainer: {
    marginLeft: "12px",
    marginRight: "5px"
  },
  detailDataContainer: {
    marginTop: "16px",
  } as React.CSSProperties,
  unavaBorderDiv: {
    marginTop: "20px",
    marginBottom: "20px",
  },
  shareButton: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "1%"
  },
  imgSize: {
    width: "23px",
    visibility: "hidden"
  } as React.CSSProperties,
  imgPaddingLeft:{
    paddingRight:"10px",
    paddingLeft:"10px",
    paddingTop:"3px",
    cursor:"pointer"
  },
  experienceInnerDiv1: {
    display: "flex",
    justifyContent: "space-between",
    maxWidth: "1195px",
    background: "#000",
    borderRadius: "4px, 4px, 0px, 0px",
    fontSize: "14px !important",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    lineHeight: "19.3px",
    color: "white",
  },
  InputFormik: {
    display: "flex",
    alignItems: "center"
  },
  dialougeHeading: {
    fontFamily: "Silika-Medium",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "19px",

  },
  buttonFilled: {
    borderRadius: "2px",
    backgroundColor: "black",
    color: "white",
    marginTop: "20px",
  },
  socialText: {
    fontFamily: "Silika-Medium",
    fontSize: "16px !important",
    fontWeight: 500,
    marginRight: "35px"
  },
  calenderIcon: {
    position: 'absolute',
    top: '50%',
    left: '10px',
    transform: 'translateY(-50%)',
    cursor: 'pointer'
  },
  socialInput: {
    border: "1px solid ##DFDFDF",
    borderRadius: "4px", marginTop: "1%", width: 700
  },
  placehholderStyle: {
    fontFamily: "Silika-Medium !important",
    fontSize: "16px",
    fontWeight: 500,
    color: "#9B9B9D"
  },
  inputChange: {

    display: "flex",
    color: "red",
    justifyContent: "start",
    alignItems: "center",
  }
};

const StyledProfileInfo = styled("div")(({theme}: {theme: Theme}) => ({
  [theme.breakpoints.up("lg")]:{
    width: "1380px",
    margin: "0 auto"
  },
  "@media (max-width:1499px)":{
    width: "calc(100vw - 160px)",
    margin: "0 auto"
  },
  "@media (max-width:990px)":{
    width: "auto",
    margin: "0 auto",
    "& .socialMediaIcons":{
      marginBottom: "60px"
    }
  },
  "& .socialMediaIcons":{
    marginBottom: "120px"
  }
}))

const StyledButtonContainer = styled("div")(({theme}: {theme: Theme}) => ({
    [theme.breakpoints.down("sm")]:{
      display: "none"
    },
    "& button":{
      backgroundColor: '#fff',
      color: "#0d0c22"
    },
    "& .fullScreen":{
      border: '1px solid #0d0c22',
      width: 48,
      height: 47,
      minWidth: 48,
      borderRadius: 0,
      marginRight: -1
    },
    "& .exitFullScreen":{
      border: '1px solid #0d0c22',
      width: 48,
      height: 47,
      minWidth: 48,
      borderRadius: 0
    },
    "& .selected":{
      backgroundColor: '#0d0c22',
      color: "#fff"
    }
}));

const StyledAddPostButton = styled(Button)(({theme}: {theme: Theme}) => ({
  width: "120px",
  height: "46px",
  borderRadius: "2px",
  backgroundColor: '#0d0c22 !important',
  fontFamily: "Silika-Medium",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  marginRight: 20,
  [theme.breakpoints.down("sm")]:{
    width: "100%",
    margin: 0
  },
  background:"#0D0C22",
  textTransform:"none",
  color:"white",
  boxShadow:"inset 0 0 0 0 #fff !important",
  border:"0.5px solid #0D0D0D",
  "&:hover":{
      boxShadow:"inset 400px 0 0 0 #fff !important",
      "& .textBtnCreate":{
        color: "#0D0D0D !important" , 
      }  
    },
    "& .textBtnCreate":{
      boxShadow:"inset 0 0 0 0 #fff !important", 
      transition: "color 0.3s ease-in-out",  
    },
}));

const CollaborationHeader = styled("div")(({theme}: {theme: Theme}) => ({
  "& .expandMore, .expandLess":{
    marginLeft: "12px"
  },
  "& p":{
    fontFamily: "Silika-Medium",
    color: "#000",
    fontSize: "20px",
    lineHeight: "24.13px",
    [theme.breakpoints.down("sm")]:{
      fontSize: "14px",
      lineHeight: "19px"
    }
  }
}));

const PostWrapper=  styled(Grid)({
  display: "flex",
  flexFlow: "row",
  flexWrap: "wrap",
  "& .collaborationGridItem, .confCollaborationGridItem":{
    borderRadius: "4px",
    aspectRatio: "403/312",
    [theme.breakpoints.down("xs")]:{
      aspecRatio: "auto",
      height: "252px"
    }
  },
  
  "& .collabImg":{
    width: "100%",
    height: "100%",
    objectFit:"cover",
    borderRadius: "4px"
  },
  "& .collaborationGridItemInside":{
    width: "100%",
    height: "100%",
    position: "relative",
  },
  "& .imageWrapper": {
    position: "relative",
    display: "inline-block",
    height: "100%",
    width: "100%",
    overflow: "hidden",
  },
  "& .restrictedDivMain": {
    position: "absolute",
    top: 0,
    left: 0,
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    background: "rgba(0,0,0,0.9)",
    zIndex : 2,
  },
  "& .restrictedDiv": {
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  "& .restrictedImg": {
    width: "24px",
    height: "24px",
  },
  "& .confidentialText": {
    fontFamily: "Silika-Medium",
    fontSize: "20px",
    color: "#FFFFFF",
    fontWeight: 500,
    marginLeft: "4px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidentialPara": {
    color: "#FFFFFF",
    fontWeight: 500,
    fontFamily: "Silika-Light",
    margin: 0,
    maxWidth: "220px",
    fontSize: "14px",
    "@media (max-width: 768px)": {
      display: "none"
    },
  },
  "& .confidential_img":{
    filter: "blur(4px)"
  },
  "& .confidentialOverlay":{
    color: "#FFFFFF",
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backdropFilter: 'blur(8px)',
    opacity: 1,
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& span":{
      color: "#FFFFFF",
      fontSize: "20px",
      fontFamily: "Silika-Medium",
      marginLeft: "4px"
    },
    "& svg":{
      height: "20px",
      width: "20px"
    }
  },
  "& .collaborationGridItem:hover .collaborationGridItemInside":{
    filter:"grayscale(0.3)",
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .confCollaborationGridItem:hover .collaborationGridItemInside":{
    backgroundColor: "rgba(217, 217, 217, 0.36)",
    transition: "opacity 0.3s ease",
  },
  "& .collaborationGridItem:hover .collabImgHover, .collaborationGridItem:hover .likeShareSaveButtonsDiv, .collaborationGridItem:hover .removeCollab, confCollaborationGridItem:hover .collabImgHover, .confCollaborationGridItem:hover .likeShareSaveButtonsDiv, .confCollaborationGridItem:hover .removeCollab":{
    opacity: 1
  },
  "& .collaborationGridItem:hover .collabImg":{
    opacity: 0.5
  },
  "& .collabImgHover":{
    position: "absolute",
    top:0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 0,
    gap: "10px",
    fontFamily: "Silika-Medium",
    color: "#010101"
  },
  "& .removeCollab":{
    position: "absolute",
    right: "12px",
    top:"12px",
    height: "24px",
    width: "24px",
    opacity: 0,
    cursor: "pointer"
  },
  "& .likeShareSaveButtonsDiv":{
    display: "inline-flex",
    position: "absolute",
    bottom: 0,
    left: 0,
    padding: "12px",
    opacity: 0,
    "& .actionButton":{
      height: "24px",
      width: "24px",
      cursor: "pointer",
      "&:first-child":{
        marginRight: "12px"
      }
    },
    "& .actionButtonImg":{
      height: "22px",
      width: "22px",
      marginRight: "12px",
      cursor: "pointer"
    }
  }
});
const ExperienceAvatar = styled(Avatar)({
  width: "75px",
  height: "75px",
  backgroundColor: "#D9D9D9",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  fontSize: "32px",
  "& .MuiAvatar-img": {
    objectFit: "scale-down",
  }
});

const SkillLabel = styled(Button)({
  height: "33px",
  maxWidth: "auto",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  fontSize: "12px",
  lineHeight: "14.48px",
  textAlign: "center" as "center",
  borderRadius: "2px",
  color: "#000",
  flexShrink: 0,
  whiteSpace: "nowrap" as "nowrap",
  textTransform: "none" as "none",
})

const ProfileAvatar = styled(Avatar)({
  width: "189px",
  height: "189px",
  backgroundColor: "#DFDFDF",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium !important",
  fontSize: "88px",
  "& .MuiAvatar-img": {
    objectFit: "cover",
  }
});

const ProfileWrapper = styled(Box)({
  padding: "0 100px",
  "& .editIcon":{
    color: "#9B9B9D",
    height: "24px",
    cursor: "pointer",
    width: "24px"
  },
  "& .hideEdit":{
    display: "none"
  },
  display: "flex",
  columnGap: "47px",
  rowGap: "24px",
  justifyContent: "center",
  marginTop: "136px",
  "@media (max-width:1200px)": {
    marginTop: "100px",
    padding: "0 120px",
  },
  "@media (max-width:991px)": {
    marginTop: "60px",
    padding: "0 60px",
    flexWrap: "wrap",

  },
  "@media (max-width:600px)": {
    marginTop: "24px",
    padding: "0 24px",
  }
})
const ProfileDetailWrapper = styled(Box)({
  width: "100%",
  "& .detailsRow":{
    width: "100%",
    display: "inline-flex",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]:{
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      "&:last-child":{
       flexFlow: "row"
      }
    }
},
"& img.actionIcons":{
  cursor: "pointer",
},
"& .actionIcons":{
    display: "inline-flex",
    paddingTop: "16px",
    "& svg":{
        marginRight: "17px",
        cursor: "pointer",
        "&:last-child":{
            marginRight: "0px"
        }
    }
},
})

const NameEditBox = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
})

const ProfileHeadline = styled(Typography)({
  fontSize: "14px",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  lineHeight: "20px",
  fontStyle: "normal",
  color: "#010101"
})
const CustomHR = styled(Typography)({
  borderBottom: "1px solid",
  color: "#DFDFDF",
})
const CustomHRBox = styled(Box)({
  "@media (max-width:991px)": {
    paddingRight: "0px",
  }
})

const LevelLocationBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  padding: "13px 22px",
  "@media (max-width:991px)": {
    padding: "13px 0px",
  }
})
const LeftBox = styled(Box)({
  display: "flex",
  alignItems:"center",
  width: "35%",
  "@media (max-width:991px)": {
    width: "50%",
  }
})
const RightBox = styled(Box)({
  display: "flex",
  alignItems:"center",
  width: "65%",
  "@media (max-width:991px)": {
    width: "50%",
  }
})

const ProfileLabel = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  lineHeight: "17px",
  fontStyle: "normal",
  color: "#9B9B9D",
})

const ProfileLabelValue = styled(Typography)({
  fontSize: "12px",
  fontFamily: "Silika-Medium",
  fontWeight: 500,
  lineHeight: "17px",
  fontStyle: "normal",
  color: "#010101",
  textTransform:"capitalize",
  whiteSpace: "break-spaces"
})

const ProfileBelowWrapper = styled(Box)({
  "& .hideEdit":{
    display: "none"
  },
  "& .editIcon":{
    height: "24px",
    width: "24px",
    color: "#9B9B9D",
    cursor: "pointer"
  },
  "@media (max-width:991px)": {
    padding: "60px",
  },
  "@media (max-width:767px)": {
    padding: "48px",
  },
  "@media (max-width:600px)": {
    padding: "24px",
  }
})
const ButtonGroupWrapper = styled(ButtonGroup)(({theme}: {theme: Theme}) => ({
  marginTop: "109px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  "& button": {
    padding: "16px",
    borderRadius: "0px"
  },
  "@media (max-width:991px)": {
    marginTop: "32px",
  },
  "@media (max-width:767px)": {
    marginTop: "0px",
  },
  "& .selectedDiv":{
    width: "50%",
    alignSelf: "center",
    border: 'none',
    borderBottom: "2px solid #0d0c22",
    "& *":{
      fontFamily: "Silika-Medium",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "40px",
      letterSpacing: "-0.15px",
      textAlign: "center" as "center",
      color: "#0d0c22",
    }
  },
  "& .nonSelectedDiv":{
    width: "50%",
    alignItems: "center",
    border: 'none',
    borderBottom: "2px solid #dfdfdf",
    "& *":{
      fontFamily: "Silika-Medium",
      fontWeight: 500,
      fontSize: "24px",
      lineHeight: "40px",
      letterSpacing: "-0.15px",
      textAlign: "center" as "center",
      color: "#9b9b9d"
    }
  },
  [theme.breakpoints.down("sm")]:{
    "& *":{
      fontSize: "16px !important",
      lineHeight: "19.3px !important"
    },
    "& button":{
      padding: "12px",
    }
  }
}))

const SubSectionWrapper = styled(Box)({
  marginTop: "80px",
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  "@media (max-width:991px)": {
    marginTop: "40px",
  },
  "@media (max-width:767px)": {
    marginTop: "30px",
  },
  "@media (max-width:600px)": {
    marginTop: "20px",
  },
  "& .blackBackgroundTransition": {
        backgroundColor: "black",
        color: "white",
        boxShadow: "inset 0 0 0 0 #fff !important",
        border: "0.5px solid #0D0D0D",
        fontFamily: "silika-Medium",
        textTransform:"none",
        "&:hover": {
            color:"black",
            boxShadow: "inset 400px 0 0 0 #fff !important",
            "& .textBtnCreate": {
                color: "#0D0D0D !important",
                fontFamily: "silika-Medium"
            }
        },
        "& .textBtnCreate": {
            boxShadow: "inset 0 0 0 0 #fff !important",
            transition: "color 0.3s ease-in-out",
            fontFamily: "silika-Medium"

        },
    },
})
const SubSectionHeading = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  background: "#0D0C22",
  padding: "0px 44px 0px 38px",
  borderTopLeftRadius: "4px",
  borderTopRightRadius: "4px",
  height: "61px",
  "@media (max-width:600px)": {
    padding: "0px 24px",
  }
})

const SubSectionTitle = styled(Typography)({
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Silika-Medium",
  lineHeight: "19.3px",
  color: "#FFF",
})

const SubSectionList = styled("div")(({theme}: {theme: Theme}) => ({
  padding: "40px 47px",
  "@media (max-width:600px)": {
    padding: "40px 24px",
  },
  [theme.breakpoints.down('sm')] :{

   }
}));

const StyledEditIcon = styled(EditIcon)(({theme}: {theme: Theme}) => ({
  width: "24px",
  height: "24px",
  flexShrink: 0,
  color: "#9B9B9D",
  cursor:'pointer',
  [theme.breakpoints.down('sm')] :{
    position: "absolute",
    right: "0"
  }
}))

const SubWrapper = styled(Box)(({theme}: {theme: Theme}) => ({
  display: "flex",
  columnGap: "75px",
  marginBottom: "80px",
  "&:last-child":{
    marginBottom: "0px"
  },
  [theme.breakpoints.down('sm')] :{
    flexWrap: "wrap",
    position: "relative",
    marginBottom: "36px",
  },
  "& .expeNameDiv":{
    display: "flex",
    justifyContent: "space-between",
    [theme.breakpoints.down('sm')] :{
      marginTop: "16px"
    }
  },
  "& .location":{
    display: "inline-flex",
    alignItems: "center",
    [theme.breakpoints.down('sm')] :{
      marginTop: "8px"
    }
  },
  "& .locationContainer":{
    marginLeft: "12px",
    marginRight: "5px",
    [theme.breakpoints.down('sm')] :{
      marginLeft: "0px"
    }
  },
  "& .summarisedData":{
    color: "#000",
    fontFamily: "Silika-ExtraLight",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 200,
    lineHeight: "normal",
    marginBottom: "28px",
    maxWidth: "651px",
    width: "100%",
    [theme.breakpoints.down('sm')] :{
      marginBottom: "16px",
      fontSize: "14px",
    }
  },
  "& .titleContainer":{
    color: "#9B9B9D",
    display: "flex",
    fontFamily: "Silka",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "19.364px",
    alignItems: "center",
    marginTop:"8px",
    [theme.breakpoints.down('sm')] :{
      flexFlow: "column",
      alignItems: "flex-start"
    }
  },
  "& .countryDatetypo":{
    fontFamily: "Silika-Light",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    letterSpacing: "-0.15px",
    whiteSpace: "break-spaces",
    [theme.breakpoints.down('sm')] :{
      fontSize: "14px",
    }
  }
}))

const AvailabilityWrapper = styled(Box)(({theme}: {theme:Theme}) => ({
  padding: "34px 44px 34px 38px",
  "& *":{
    fontFamily: "Silika-Medium"
  },
  "@media (max-width:600px)": {
    padding: "24px",
    "& .MuiGrid-item:last-child":{
      marginTop: "20px"
    }
  },
  "& .calInDiv": {
    width: "70%",
    boxSizing: "content-box" as "content-box",
    display:"flex",
    justifyContent: "center",
    [theme.breakpoints.down('sm')] :{
      flexFlow: "column"
    }
  },
  "& .unavailableDates":{
    display: "flex",
    flexFlow: "column",
    marginTop: "20px",
    marginBottom: "20px",
   "& span":{
    color: "#9B9B9B",
    marginBottom: "5px",
    "&:last-child":{
      margin: "0px"
    }
   }
  },
  "& .date-error":{
    color: "#DC2626"
  }
}))
const AvailabilitySubWrapper = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  flexDirection: "row",
  rowGap: "24px",
  "@media (max-width:991px)": {
    flexDirection: "column",
    alignItems: "start",
  }
})
const AvailabilityChange = styled(Typography)({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14.48px",
  color: "#FFF",
  fontFamily: "Silika-Medium",
  "@media (max-width:600px)": {
    display: "none"
  }
})
const AvailabilityChangeSM = styled(Typography)({
  marginTop: "32px",
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "14.48px",
  color: "#9B9B9D",
  fontFamily: "Silika-Medium",
  display: "none",
  "& span": {
    fontWeight: 500,
    fontSize: "13px",
    marginLeft: '4px',
    textDecoration: "underline",
    cursor: "pointer",
    color: "#9B9B9D",
  },
  "@media (max-width:600px)": {
    display: "block",
  }
})
const AvailabilitySubText = styled(Box)({
  display: "flex",
  columnGap: "16px",
  rowGap: "24px",
  alignItems: "center",
  flexDirection: "row",
  "@media (max-width:991px)": {
    flexDirection: "column",
    alignItems: "start",
  }
})
const AvailabilityHeader = styled(Typography)({
  fontSize: "20px",
  fontWeight: 500,
  lineHeight: "24.2px",
  color: "#000000",
  fontFamily: "Silika-Medium",
})
const AvailabilitySubHeader = styled(Typography)({
  fontFamily: "Silika-Light",
  fontSize: "16px",
  lineHeight: "19.3px",
  fontWeight: 300,
})


const EditSocialBtn = styled(EditIcon)({
  width: "24px",
  height: "24px",
  color: "#9B9B9D",
  cursor:'pointer',
})
const SocialMediaContainer = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  padding: '0 46px 0 41px',
  alignItems: "center",
  minHeight: "113px",
  "@media (max-width:600px)": {
    padding: '0 24px'
  },
  "@media (max-width:400px)": {
    padding: '24px',
  }
})
const AboutWrapper = styled(Box)({
  marginTop: "80px",
  border: "1px solid #D9D9D9",
  borderRadius: "4px",
  padding: "45px 42.85px 39px 40.7px",
  "@media (max-width:991px)": {
    marginTop: "64px",
  },
  "@media (max-width:767px)": {
    marginTop: "48px",
    padding: "24px",
  },
  "@media (max-width:600px)": {
    marginTop: "32px",
  }
})
const DescriptionWrapper = styled(Box)({
  marginTop: "13px",
  marginRight: "23.57px",
  "@media (max-width:600px)": {
    margin: "24px 0",
  }
})
const HRwrapper = styled(Box)({
  marginTop: "96px",
  "@media (max-width:600px)": {
    display: "none"
  }
})

// Customizable Area End
