import React, { useState, ChangeEvent } from 'react';
import {
  Box,
  Button,
  Divider,
  Paper,
  Table,
  styled,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Collapse,
} from "@material-ui/core";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
const imageCreative = require("./imageCreative.svg");

const CreateRolesBox = styled(Box)({
  margin: "70px",
  border: "1px solid #DFDFDF",

  "@media (max-width: 1000px)": {
    margin: "20px"
  },

  "@media (max-width: 480px)": {
    margin: "10px"
  },
  "& .lineStyle": {
    margin: "10px 0px",
  },
  "& .tableRowValueId": {
    padding: "12px",
    fontFamily: "silka",
    fontWeight: 300,
    fontSize: "14px",
    lineHeight: "19.69px",
    color: "black",
  },
  "& .tableRowValueName": {
    padding: "12px",
    fontFamily: "silka",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "19.36px",
    color: "black",
  },
  "& .tableRowStatus": {
    display: "block",
    textAlign: "center",
    width: "203px",
    height: "22px",
    fontFamily: "silka",
    fontWeight: 500,
    borderRadius: "2px",
    backgroundColor: "lightgrey",
    color: "black",
    padding: "2px 19.5px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  "& .tableRowPaidStatus": {
    display: "block",
    textAlign: "center",
    width: "203px",
    height: "22px",
    fontFamily: "silka",
    fontWeight: 500,
    borderRadius: "2px",
    backgroundColor: "#FEF3C7",
    color: "#d97706",
    padding: "2px 19.5px",
    fontSize: "12px",
    lineHeight: "18px",
  },
  "& .buttonStyle": {
    width: '175px',     
    height: '32px',    
    paddingRight: '12px',
    paddingLeft: '12px',  
    gap: '10px',  
    color: "white",
    border: "1px solid #010101",
    backgroundColor: "black"
  },
  "& .createHeadingBox": {
    backgroundColor: "#DFDFDF",
    height: "50px",
    padding: "0px 20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      height: "45px",
      padding: "0px 15px"
    },
    "@media (max-width: 768px)": {
      height: "40px",
      padding: "0px 10px"
    },
    "@media (max-width: 480px)": {
      height: "35px",
      padding: "0px 5px"
    }
  },
  "& .headingIconText": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  },
  "& .createViewTxt": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "18px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "16px"
    },
    "@media (max-width: 768px)": {
      fontSize: "15px"
    },
    "@media (max-width: 480px)": {
      fontSize: "14px"
    }
  },
  "& .roleAttachmentsTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    margin: "15px 0px"
  },
  "& .roleInvoiceTitle": {
    color: "#000000",
    fontWeight: 600,
    fontSize: "24px",
    fontFamily: "Silika-Medium",
    margin: "15px 0px"
  },
  "& .contentBox": {
    display: "flex",
    "@media (max-width: 900px)": {
      flexDirection: "row"
    },
    "@media (max-width: 768px)": {
      flexDirection: "column"
    },
    "@media (max-width: 480px)": {
    }
  },

  "& .mainInnerBox": {
    margin: "40px",
    display: "flex",
    flexDirection: "column",
    "@media (max-width: 900px)": {
      margin: "30px"
    },
    "@media (max-width: 768px)": {
      margin: "20px"
    },
    "@media (max-width: 480px)": {
      margin: "15px"
    }
  },
  "& .userImg": {
    height: "180px",
    width: "180px",
    "@media (max-width: 900px)": {
      height: "150px",
      width: "150px"
    },
    "@media (max-width: 768px)": {
      height: "120px",
      width: "120px",
      margin: "0 auto"
    },
    "@media (max-width: 480px)": {
      height: "100px",
      width: "100px",
      margin: "0 auto"
    }
  },
  "& .userInfoBox": {
    width: "100%",
    marginLeft: "20px",
    "@media (max-width: 900px)": {
      marginLeft: "15px"
    },
    "@media (max-width: 768px)": {
      marginLeft: "0",
      marginTop: "10px"
    }
  },
  "& .roleInfoBox": {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media (max-width: 900px)": {
      gap: "15px"
    },
    "@media (max-width: 768px)": {
      gap: "10px"
    }
  },
  "& .roleDetails": {
    display: "flex",
    gap: "30px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "5px"
    }
  },
  "& .actionBox": {
    display: "flex",
    gap: "20px",
    alignItems: "center",
    "@media (max-width: 900px)": {
      gap: "15px"
    },
    "@media (max-width: 480px)": {
      gap: "10px"
    }
  },
  "& .terminateBtn": {
    textTransform: "unset",
    color: "#FFFFFF",
    fontWeight: 500,
    fontSize: "15px",
    fontFamily: "Silika-Medium",
    width: "105px",
    backgroundColor: "#0D0C22",
    cursor: "pointer",
    borderRadius: "4px",
    padding: "10px 0px",
    "@media (max-width: 900px)": {
      width: "95px",
      fontSize: "14px"
    },
    "@media (max-width: 768px)": {
      width: "90px",
      fontSize: "13px"
    },
    "@media (max-width: 480px)": {
      width: "85px",
      fontSize: "12px",
      padding: "8px 0"
    }
  },
  "& .userName": {
    color: "#000000",
    fontWeight: 500,
    fontSize: "22px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "20px"
    },
    "@media (max-width: 768px)": {
      fontSize: "18px"
    },
    "@media (max-width: 480px)": {
      fontSize: "16px"
    }
  },
  "& .roleSubText": {
    color: "#9B9B9B",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Silika-Medium",
    "@media (max-width: 900px)": {
      fontSize: "13px"
    },
    "@media (max-width: 768px)": {
      fontSize: "12px"
    },
    "@media (max-width: 480px)": {
      fontSize: "11px"
    }
  },
  "& .infoSection": {
    margin: "20px 0px",
    "@media (max-width: 900px)": {
      margin: "15px 0"
    },
    "@media (max-width: 768px)": {
      margin: "10px 0",
      flexDirection: "row"
    },
    "& .infoRow": {
      borderTop: "1px solid #DFDFDF",
      padding: "10px 0px",
      display: "flex",
      gap: "50px",
      "@media (max-width: 900px)": {
        gap: "30px",
      },
      "@media (max-width: 768px)": {
        gap: "10px",
      }
    },
    "& .infoLabel": {
      color: "#9B9B9B",
      fontWeight: 500,
      fontSize: "12px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 900px)": {
        fontSize: "12px",
      },
      "@media (max-width: 768px)": {
        fontSize: "11px",
      },
      "@media (max-width: 480px)": {
        fontSize: "10px",
      }
    },
    "& .infoValue": {
      color: "#000000",
      fontWeight: 500,
      fontSize: "12px",
      fontFamily: "Silika-Medium",
      "@media (max-width: 900px)": {
        fontSize: "12px",
      },
      "@media (max-width: 768px)": {
        fontSize: "11px",
      },
      "@media (max-width: 480px)": {
        fontSize: "10px",
      }
    }
  },
  "& .fixedWidth": {
    width: "100%",
    maxWidth: "180px",
    "@media (max-width: 1024px)": {
      maxWidth: "160px"
    },
    "@media (max-width: 768px)": {
      maxWidth: "140px"
    },
    "@media (max-width: 480px)": {
      maxWidth: "120px"
    }
  },
  "& .borderBottom": {
    borderBottom: "1px solid #DFDFDF"
  },
  "& .infoInsideBox": { display: "flex", flexDirection: "row", gap: "10px" },
  "& .descriptionRole": {
    color: "#000000",
    fontSize: "14px",
    fontFamily: "Silika-light",
    "@media (max-width: 480px)": {
      fontSize: "12px"
    }
  }
});

const CreativeDashboardDetail = ({
  roleAttachmentFiles,
  roleInvoiceFiles: initialRoleInvoiceFiles,
  handleTerminateModal,
  handleRoleAttachmentFile,
  handleRoleInvoiceFile,
  editImage,
  editImageAlt,
  userImg,
  invoicesTitle,
  pdfType,
  imageType,
  navigateToDetailSection
}:any) => {

const rowOpen = [
  {
    id: "001",
    name: "Car circuits",
    status: "Invoiced awaiting payment",
    action: "Make Payment",
  },
  {
    id: "002",
    name: "Frontal Lights",
    status: "Submitted for Approval",
    action: "Approve/Reject",
  },
  {
    id: "003",
    name: "Car Dashboard",
    status: "Paid",
    action: "Confirm Payment",
  },
];

  const [roleInvoiceFiles, setRoleInvoiceFiles] = useState(initialRoleInvoiceFiles || []);
  const [roleAttachmentFilesState, setRoleAttachmentFilesState] = useState(roleAttachmentFiles || []);

  const handleRoleAttachmentChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    setRoleAttachmentFilesState((prevFiles: any) => [...prevFiles, ...filesWithTypes]);
  };

  const handleRoleInvoiceChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFiles = event.target.files ? Array.from(event.target.files) : [];

    const filesWithTypes = selectedFiles.map((file) => {
      const fileType = file.type ? file.type.split("/")[1].split("+")[0] : "unknown";
      return {
        name: file.name,
        file,
        type: fileType,
      };
    });

    setRoleInvoiceFiles((prevFiles: any) => [...prevFiles, ...filesWithTypes]);
  };

  const [openRows, setOpenRows] = useState<any>({});

  const handleClick = (id: string) => {
    setOpenRows((prev: any) => ({
      ...prev,
      [id]: !prev[id], 
    }));
  };
  const HeadingBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    gap: "10px",
    margin: "20px",
    "@media (min-width: 600px)": {
      margin: "30px"
    },
    "@media (min-width: 960px)": {
      margin: "50px"
    },
    "@media (min-width: 1280px)": {
      margin: "70px"
    },
  
    "& .hideText": {
      color: "#9B9B9D",
      fontWeight: 500,
      fontSize: "16px",
  
      "@media (min-width: 600px)": {
        fontSize: "18px"
      },
      "@media (min-width: 960px)": {
        fontSize: "22px"
      },
      "@media (min-width: 1280px)": {
        fontSize: "30px"
      }
    },
  
    "& .showText": {
      color: "#0D0C22",
      fontWeight: 500,
      fontSize: "16px",
      "@media (min-width: 600px)": {
        fontSize: "18px"
      },
      "@media (min-width: 960px)": {
        fontSize: "22px"
      },
      "@media (min-width: 1280px)": {
        fontSize: "30px"
      }
    },
  });

  const CreativeAttachmentContainer = styled(Box)({
    gap: "10px",
    display: "flex",
    overflow: "scroll",
    "& .roleAttachmentFileBox": {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "70px"
    },
    "& .roleAttachmentFilePreview": {
      height: "70px",
      borderRadius: "8px",
      width: "70px",
      border: "1px solid black",
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      marginLeft: "10px",
      backgroundColor: "#FFFFFF",
      cursor: "pointer"
    },
    "& .roleAttachmentFileName": {
      width: "70px",
    
      fontWeight: 400,
      color: "#000000",
      fontSize: "10px",
      fontFamily: "Silika-Medium"
    },
    "& .roleAttachmentUploadLabel": {
      justifyContent: "center",
      display: "flex",
      alignItems: "center",
      height: "70px",
      width: "70px",
      backgroundColor: "#FFFFFF",
      borderRadius: "5px",
      border: "1px dashed #9B9B9B",
      cursor: "pointer"
    },
    "@media (max-width: 768px)": {
      flexDirection: "column",
      gap: "15px",
      "& .roleAttachmentFileBox, & .roleAttachmentUploadLabel": {
        height: "70px",
        width: "70px"
      },
      "& .roleAttachmentFileName": {
        fontSize: "11px"
      }
    },
    "@media (max-width: 480px)": {
      flexDirection: "column",
      "& .roleAttachmentFileBox, & .roleAttachmentUploadLabel": {
        height: "60px",
        width: "60px"
      },
      "& .roleAttachmentFileName": {
        fontSize: "10px"
      }
    }
  });
  
  return (
    <>
      <HeadingBox>
        <Typography className="hideText" onClick={navigateToDetailSection}>
          Dashboard
        </Typography>
        <Typography className="hideText">{">"}</Typography>
        <Typography className="showText">0001 Truckers</Typography>
      </HeadingBox>
      <CreateRolesBox>
        <Box className="createHeadingBox">
          <Box className="headingIconText">
            <FolderOpenIcon />
            <Typography className="createViewTxt">
              0001 Truckers <Typography component="span" style={{ fontWeight: 300, fontFamily: "Silika-Light" }}> (Deliverable based)</Typography>
            </Typography>
          </Box>
        </Box>
        <Box className="mainInnerBox">
          <Box className="contentBox">
            <img src={imageCreative} alt="userImg2" className="userImg" />
            <Box className="userInfoBox">
              <Box className="roleInfoBox">
                <Box className="roleInfoText">
                  <Typography className="userName">
                    Automotive Designer
                  </Typography>
                  <Box className="roleDetails">
                    <Typography className="roleSubText">Supercars</Typography>
                    <Typography className="roleSubText">5 days ago</Typography>
                  </Box>
                </Box>
              </Box>

              <Box className="infoSection">
                <Box className="infoRow">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">Level:</Typography>
                    <Typography className="infoValue">Senior</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">Location:</Typography>
                    <Typography className="infoValue">Coventry, UK</Typography>
                  </Box>
                </Box>

                <Box className="infoRow">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">Type:</Typography>
                    <Typography className="infoValue">
                      Full Time | Remote
                    </Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">Professions:</Typography>
                    <Typography className="infoValue">
                      Interior Design | UI/UX Design | Visualization
                    </Typography>
                  </Box>
                </Box>

                <Box className="infoRow borderBottom">
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">Dates:</Typography>
                    <Typography className="infoValue">
                      13 Mar 24 - 25 Apr 24
                    </Typography>
                  </Box>
                  <Box className="fixedWidth infoInsideBox">
                    <Typography className="infoLabel">Rate:</Typography>
                    <Typography className="infoValue">£45.00/hr</Typography>
                  </Box>
                  <Box className="infoInsideBox">
                    <Typography className="infoLabel">Budget:</Typography>
                    <Typography className="infoValue">£2,000</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Typography className="descriptionRole">
            {" "}
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer id
            erat auctor, rutrum ex id, tincidunt tellus. Duis id nunc in neque
            maximus interdum. Nunc viverra vestibulum neque et mollis. Sed a
            odio condimentum, volutpat ex eu, volutpat tortor. Duis imperdiet
            urna eget lacus sollicitudin, non accumsan lorem posuere. In hac
            habitasse platea dictumst. Ut tempus et justo ac facilisis. Aliquam
            vitae faucibus nisi. Vivamus fermentum tortor in massa eleifend
            imperdiet. Vestibulum magna leo, hendrerit vitae odio eu, egestas
            tincidunt ligula. Integer sodales ac purus sit amet imperdiet..
          </Typography>

          <Box style={{ margin: "50px 0px", borderTop: "1px solid #DFDFDF" }}>
            <Typography className="roleAttachmentsTitle">
              Attachments
            </Typography>
            <CreativeAttachmentContainer className="roleAttachmentContainer">
              {roleAttachmentFilesState.map((file: { type: string; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | undefined) => (
                <Box className="roleAttachmentFileBox" key={index}>
                  <Box className="roleAttachmentFilePreview">
                    <img
                      src={file.type === "pdf" ? pdfType : imageType}
                      alt="file preview"
                    />
                  </Box>
                  <Typography className="roleAttachmentFileName">
                    {file.name}
                  </Typography>
                </Box>
              ))}

              <label className="roleAttachmentUploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={handleRoleAttachmentChange}
                  style={{ display: "none" }}
                />
                <AddIcon className="addIcon" />
              </label>
            </CreativeAttachmentContainer>
          </Box>

          <Box style={{ borderTop: "1px solid #DFDFDF" }}>
            <Typography className="roleAttachmentsTitle">Invoices</Typography>
            <CreativeAttachmentContainer className="roleAttachmentFileBox">
              {roleInvoiceFiles.map((file: { type: string; name: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined; }, index: React.Key | undefined) => (
                <Box className="roleAttachmentFileBox" key={index}>
                  <Box className="roleAttachmentFilePreview">
                    <img
                      src={file.type === "pdf" ? pdfType : imageType}
                      alt="file preview"
                    />
                  </Box>
                  <Typography className="roleAttachmentFileName">
                    {file.name}
                  </Typography>
                </Box>
              ))}

              <label className="roleAttachmentUploadLabel">
                <input
                  accept=".jpg,.jpeg,.pdf,.doc,.svg,.png"
                  type="file"
                  onChange={handleRoleInvoiceChange}
                  style={{ display: "none" }}
                />
                <AddIcon className="addIcon" />
              </label>
            </CreativeAttachmentContainer>
          </Box>
        </Box>
        <Box>
          <Divider className="lineStyle"></Divider>
          <TableContainer component={Paper} elevation={0}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                  >
                    Deliverable
                  </TableCell>

                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                    align="left"
                  >
                    Status
                  </TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      fontSize: "16px",
                      fontFamily: "silika-Medium",
                    }}
                    align="left"
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowOpen.map((row) => (
                  <>
                    <TableRow>
                      <TableCell align="left">
                        <span className="tableRowValueId">{row.id}</span>
                        <span className="tableRowValueName">{row.name}</span>
                      </TableCell>
                      <TableCell align="left">
                        <span
                          onClick={() => handleClick(row.id)}
                          className={
                            row.status !== "Paid"
                              ? "tableRowStatus"
                              : "tableRowPaidStatus"
                          }
                        >
                          {row.status}
                        </span>
                      </TableCell>
                      <TableCell align="left">
                        <Button className="buttonStyle">{row.action}</Button>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        colSpan={3}
                        style={{ paddingBottom: 0, paddingTop: 0, width: "100%" }}
                      >
                        <Collapse
                          in={openRows[row.id] ? true :false}
                          timeout="auto"
                          unmountOnExit
                        >
                          <Box
                            style={{
                              padding: "10px 20px",
                              backgroundColor: "#f9f9f9",
                            }}
                          >
                            <p>
                              Lorem ipsum dolor sit amet, consectetur adipiscing
                              elit. Integer id erat auctor, rutrum ex id,
                              tincidunt tellus. Duis id nunc in neque maximus
                              interdum. Nunc viverra vestibulum neque et mollis.
                              Sed a odio condimentum, volutpat ex eu, volutpat
                              tortor. Duis imperdiet urna eget lacus
                              sollicitudin, non accumsan lorem posuere. In hac
                              habitasse platea dictumst. Ut tempus et justo ac
                              facilisis. Aliquam vitae faucibus nisi. Vivamus
                              fermentum tortor in massa eleifend imperdiet.
                              Vestibulum magna leo, hendrerit vitae odio eu,
                              egestas tincidunt ligula. Integer sodales ac purus
                              sit amet imperdiet.
                            </p>
                          </Box>
                        </Collapse>
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </CreateRolesBox>
    </>
  );
};

export default CreativeDashboardDetail;
