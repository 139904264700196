export const rightLogo= require("../assets/rightIcon.svg");
export const orangeBanner=require("../assets/bannerOrange.jpeg")
export const groupPng = require("../assets/group_images.png");
export const profileList = require("../assets/profileList.png");
export const projectInfo = require("../assets/projectInfoDemo.png");
export const bentlyLogo= require("../assets/bently.png");
export const jaguarLogo= require("../assets/jaguar.png");
export const bmwLogo= require("../assets/bmw.png");
export const bugatiLogo= require("../assets/bugati.png");
export const astromartinLogo= require("../assets/astromartin.png");
export const amarenatiLogo= require("../assets/marenati.png");
export const ferariLogo= require("../assets/ferari.png");
export const mercedesLogo= require("../assets/mercedes.png");
export const teslaLogo= require("../assets/tesla.png");
export const tvrLogo= require("../assets/tvr.png");export const arrowRight = require("../assets/arrow.svg");
export const carInner = require("../assets/carInner.png");
export const joinCom = require("../assets/bannerDef_.png");
export const designResearch = require("../assets/designResearch.png");
export const exterior = require("../assets/exterior.png");
export const interior = require("../assets/interior.png");
export const CMFImg = require("../assets/CMF.png");
export const uiuxImg = require("../assets/uiux.png");
export const createModelImg = require("../assets/createModel.png");
export const CADResearchImg = require("../assets/CADResearch.png");
export const classSufferingImg = require("../assets/classSuffering.png");
export const productManagementImg = require("../assets/productManagement.png");
export const projectManagementImg = require("../assets/projectManagement.png");
export const studioEngineeringImg= require("../assets/studioEngineering2.png");
export const restricted = require("../assets/restricted.svg");
export const shareImage = require("../assets/share.png");
export const removeIcon =require("../assets/minus.png");
